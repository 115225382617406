import React, { useEffect, useMemo, useRef, useState } from "react";
import { SectionHeadingWithFiller } from "../HeadingWithFiller";
import {
  decodeName,
  getPath,
  getTranslations,
  isAndroid,
  isIOS,
} from "../../Common/utils";
import { CtaButton } from "../Buttons";
import { TableHeader } from "../TableHeader";
import { useDispatch, useSelector } from "react-redux";
import { NoDataFound } from "../NoDataFound";
import GroupBtns from "../../Components/League/GroupBtns";
import { league } from "../../redux/actions";
import CreateLeagueOverlay from "../../Components/League/Create/overlay";
import { useNavigate } from "react-router-dom";
import HeroBanner from "../../Components/League/HeroBanner";
import SectionTitle from "../../Components/League/SectionTitle";
import { ErrorMessage } from "../Error/message";
import Notes from "../Notes";
import { getRank } from "../../Common/utils/league";
import useTournamentScanario from "../../Common/hooks/useTournamentScanario";
import { currentFixture } from "../../Common/utils/fixture";
import {
  FANTASY_ANDROID,
  FANTASY_IOS,
  FANTASY_WEBSITE,
  WEB,
  WEBVIEW,
} from "../../Common/constants";
import useDataLayer from "../../Common/hooks/useDataLayer";
import useIntersectionObserver from "../../Common/hooks/useIntersectionObserver";

const LeaguesSummarySection = ({ leagueSummary }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const leagueSummaryRef = useRef(null);
  const userState = useSelector((state) => state?.user);
  const webview = userState?.webview;
  const pushToDataLayer = useDataLayer();
  const [selectedTeam, setSelectedTeam] = useState(1);
  const [activeTeam, setActiveTeam] = useState({
    0: true,
    1: false,
    2: false,
  });

  const translationState = useSelector((state) => state.translation);
  const fixtureState = useSelector((state) => state.fixture);
  const TeamCount = userState?.data?.TeamCount;
  const { isEos, lastGamedayRaceLocked } = useTournamentScanario();
  const disableCTAs = isEos || lastGamedayRaceLocked;
  const [isDataPushed, setIsDataPushed] = useState(false);
  const teamsLeagueSummary = useMemo(() => {
    const summaryobj = {};
    leagueSummary.forEach((currentTeamSummary) => {
      const teamNo = currentTeamSummary.teamNo;

      const currentTeamPrivateLeagues = currentTeamSummary?.private?.map(
        (item) => ({
          ...item,
          userRank: getRank(item?.rank),
          leaguename: decodeName(item?.leaguename),
        })
      );
      const currentTeamPublicLeagues = currentTeamSummary?.public?.map(
        (item) => ({
          ...item,
          userRank: getRank(item?.rank),
          leaguename: decodeName(item?.leaguename),
        })
      );

      summaryobj[teamNo] = {
        ...currentTeamSummary,
        private: currentTeamPrivateLeagues,
        public: currentTeamPublicLeagues,
      };
    });

    return summaryobj;
  }, [leagueSummary]);

  const currentTeamLeagueSummray = teamsLeagueSummary?.[selectedTeam];

  const joinLeagueOverlay = () => {
    dispatch(league.toggleJoinLeagueOverlay());
  };

  let btnProps = {
    joinLeagueOverlay: joinLeagueOverlay,
  };

  const userTeamCta = [
    { ctaLbl: getTranslations("leagues_summary_team_label", "Team") },
    { ctaLbl: getTranslations("leagues_summary_team_label", "Team") },
    { ctaLbl: getTranslations("leagues_summary_team_label", "Team") },
  ];

  const leaguesSummaryClickDataLayer = (value) => {
    const dataLayerObject = {
      actionType: `Team ${value + 1}`,
      clickText: `${getTranslations("leagues_summary_team_label", "Team")} ${
        value + 1
      }`,
      locationInPage: "League Summary",
      componentType: webview ? WEBVIEW : WEB,
      pageName: "Home",
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
    };
    pushToDataLayer("navigation_click", dataLayerObject);
  };
  const handleTeamClick = (value) => {
    setSelectedTeam(value + 1);
    leaguesSummaryClickDataLayer(value);
    if (value === 0) {
      setActiveTeam({
        0: true,
        1: false,
        2: false,
      });
    }
    if (value === 1) {
      setActiveTeam({
        0: false,
        1: true,
        2: false,
      });
    }
    if (value === 2) {
      setActiveTeam({
        0: false,
        1: false,
        2: true,
      });
    }
  };
  const viewResultsCTA = getTranslations("view_result_cta_text", "View Result");
  const tableConfig = [
    {
      head: getTranslations(
        "leagues_summary_table_league_name_heading",
        "League Name"
      ),
      cls: "name",
      key: "leaguename",
    },
    {
      head: getTranslations("leagues_summary_table_rank_heading", "Rank"),
      cls: "pts",
      key: "userRank",
    },
  ];

  const viewResultsClickDataLayer = ({ locationInPage, path }) => {
    const dataLayerObject = {
      actionType: "View Result",
      clickText: viewResultsCTA,
      path: `${path}`,
      locationInPage: `Leagues Summary | ${locationInPage}`,
      componentType: webview ? WEBVIEW : WEB,
      pageName: "Home",
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
    };
    pushToDataLayer("navigation_click", dataLayerObject);
  };

  const isLeagueSummaryIntersectingMap = useIntersectionObserver(
    leagueSummaryRef,
    {
      // threshold: isMobile() ? 0.5 : 1,
      threshold: 0.75,
    }
  );

  useEffect(() => {
    const dataLayerObject = {
      actionType: "view",
      locationInPage: "League Summary",
      pageName: "Home",
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: webview ? WEBVIEW : WEB,
    };

    if (leagueSummaryRef.current) {
      isLeagueSummaryIntersectingMap.forEach((value, key) => {
        if (value && !isDataPushed) {
          pushToDataLayer("widget_impressions", dataLayerObject);
          setIsDataPushed(true);
        }
      });
    }
  }, [isLeagueSummaryIntersectingMap]);

  return (
    <>
      <section className="si-leaguesSummary__wrap" ref={leagueSummaryRef}>
        <div className="si-main__container">
          <div className="si-leaguesSummary__grid">
            <SectionHeadingWithFiller
              // dark={true}
              heading={getTranslations(
                "leagues_summary_heading",
                "Leagues Summary"
              )}
              component={
                <>
                  <div className="si-leaguesSummary__ctas">
                    {userTeamCta.map((item, index) => (
                      <CtaButton
                        key={index}
                        classes={`si-btn si-btn__team si-btn__team--${
                          index + 1
                        } ${activeTeam?.[index] ? "si-active" : ""} `}
                        onClick={() => handleTeamClick(index)}
                      >
                        {`${item.ctaLbl} ${index + 1}`}
                      </CtaButton>
                    ))}
                  </div>
                </>
              }
            />
          </div>
        </div>
        <div className="si-main__container">
          <div className="si-leaguesSummary__container">
            {TeamCount < selectedTeam ? (
              <div className="si-leaguesSummary__container-item">
                <NoDataFound
                  noDataTitle={getTranslations(
                    "leagues_summary_create_team_title",
                    "Top Performing League"
                  )}
                  noDataCaption={getTranslations(
                    "leagues_summary_create_team_caption",
                    "Create a team and Join a league to view your league performance."
                  )}
                  noDataCta={getTranslations(
                    "my_teams_create_new_team_button",
                    "Create New Team"
                  )}
                  noDataConfig={{
                    variant: "secondary",
                    iconCls: "f1i-add",
                    ariaLabel: getTranslations(
                      "my_teams_create_new_team_button",
                      "Create New Team"
                    ),
                    onClick: () =>
                      navigate(getPath(translationState, "createTeam")),
                    btnState:
                      !currentFixture(fixtureState)?.IsActiveForNewUser ||
                      disableCTAs,
                  }}
                />
              </div>
            ) : (
              <>
                <div className="si-leaguesSummary__container-item">
                  {currentTeamLeagueSummray?.private?.length ? (
                    <>
                      <SectionTitle
                        pageTitle={getTranslations(
                          "leagues_summary_top_performing_private_league",
                          "Top Performing Private League"
                        )}
                      />
                      <div className="si-leaguesSummary__tbl">
                        <TableHeader
                          tableConfig={tableConfig}
                          tableData={currentTeamLeagueSummray?.private}
                        />
                      </div>
                      <div className="si-leaguesSummary__cta ">
                        <CtaButton
                          variant={"secondary"}
                          fullWidth={true}
                          icon="f1i-chevron-right"
                          onClick={() => {
                            const navigationPath = getPath(
                              translationState,
                              "leagues"
                            );
                            const search = "?type=private-classic";
                            navigate({
                              pathname: getPath(translationState, "leagues"),
                              search: "?type=private-classic",
                            });
                            viewResultsClickDataLayer({
                              locationInPage: "Top Private League",
                              path: `${navigationPath}${search}`,
                            });
                          }}
                        >
                          {viewResultsCTA}
                        </CtaButton>
                      </div>
                    </>
                  ) : (
                    <>
                      <HeroBanner
                        leagueHeroBanner={true}
                        leagueHeroBannerType="private"
                      />
                    </>
                  )}
                </div>
                <div className="si-leaguesSummary__container-item">
                  {currentTeamLeagueSummray?.public?.length ? (
                    <>
                      <SectionTitle
                        pageTitle={getTranslations(
                          "leagues_summary_top_performing_public_league",
                          "Top Performing Public League"
                        )}
                      />
                      <div className="si-leaguesSummary__tbl">
                        <TableHeader
                          tableConfig={tableConfig}
                          tableData={currentTeamLeagueSummray?.public}
                        />
                      </div>
                      <div className="si-leaguesSummary__cta">
                        <CtaButton
                          variant={"secondary"}
                          fullWidth={true}
                          icon="f1i-chevron-right"
                          onClick={() => {
                            const navigationPath = getPath(
                              translationState,
                              "leagues"
                            );
                            const search = "?type=public-classic";
                            navigate({
                              pathname: getPath(translationState, "leagues"),
                              search: "?type=public-classic",
                            });
                            viewResultsClickDataLayer({
                              locationInPage: "Top Public League",
                              path: `${navigationPath}${search}`,
                            });
                          }}
                        >
                          {viewResultsCTA}
                        </CtaButton>
                      </div>
                    </>
                  ) : (
                    <>
                      <HeroBanner
                        leagueHeroBanner={true}
                        leagueHeroBannerType="public"
                      />
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="si-main__container">
          <div className="si-leaguesSummary__notes">
            <Notes
              text={getTranslations(
                "leagues_summary_results_updated_after_next_session",
                "*League results will be updated after the next session has completed"
              )}
            />
          </div>
        </div>
      </section>
      <CreateLeagueOverlay />
    </>
  );
};

export { LeaguesSummarySection };
