import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MAX_LEAGUE_NAME_CHARACTERS } from "../../../Common/constants";
import { disableEmojiInput, getTranslations } from "../../../Common/utils";
import { league } from "../../../redux/actions";
import { AffordableToggleSwitch } from "../../AffordableToggleSwitch";
import { ErrorMessage } from "../../Error/message";
import { PvtPubCardChips } from "../../PvtPubCardChips";

const EditALeague = ({
  selectedLeagueType = null,
  handleSelect = () => {},
  leagueType,
  currentLeagueName,
  maxMembers,
  isUnlimitedMembers,
  noOfTeamsPerUser,
  submitEditLeagueData = () => {},
  isEditSubmit = false,
  canEditLeague = () => {},
}) => {
  const dispatch = useDispatch();
  const gameplayState = useSelector((state) => state.gameplay);
  const leagueState = useSelector((state) => state.league);
  //   const [leagueType, setLeagueType] = useState("");
  const [leagueName, setLeagueName] = useState(currentLeagueName);
  const [maxParticipants, setMaxParticipants] = useState(maxMembers);
  const [maxTeams, setMaxTeams] = useState(noOfTeamsPerUser);
  const [checked, setChecked] = useState(isUnlimitedMembers ? true : false);

  const { editLeagueFulfilled } = leagueState;

  const handleLeagueTypeChange = (e) => {
    // setLeagueType(e.target.value);
  };
  const handleLeagueNameChange = (e) => {
    let val = disableEmojiInput(e.target.value);
    setLeagueName(val);
  };
  const handleMaxParticipantsChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9]/g, " ");
    setMaxParticipants(Number(value));
  };

  const handleMaxTeamsChange = (e) => {
    let value = Number(e.target.value);
    dispatch(league.setMaxTeams(value));
    setMaxTeams(value);
  };

  const handleChecked = () => {
    setChecked(!checked);
  };

  const handlePasteMaxTeam = (event) => {
    event.preventDefault();
    const pastedText = (event.clipboardData || window.clipboardData).getData(
      "text"
    );
    const filteredText = pastedText.replace(/[^0-9]/g, "");
    setMaxParticipants(filteredText);
  };

  useEffect(() => {
    if (editLeagueFulfilled) {
      dispatch(league.resetEditLeague());
    }
  }, [editLeagueFulfilled, dispatch]);

  useEffect(() => {
    if (checked) {
      setMaxParticipants(99999999);
    } else if (leagueType === "h2h" || leagueType === "Private H-T-H") {
      setMaxParticipants(2);
    }
  }, [checked, leagueType]);

  useEffect(() => {
    canEditLeague({ leagueName, maxParticipants });
  }, [canEditLeague, leagueName, maxParticipants]);

  useEffect(() => {
    if (isEditSubmit) {
      submitEditLeagueData({
        leagueName,
        leagueType,
        maxParticipants,
      });
    }
  }, [
    isEditSubmit,
    leagueName,
    leagueType,
    maxParticipants,
    submitEditLeagueData,
  ]);

  // * Hande the condition of minimum 3 leagueName characters and max 50 characters
  useEffect(() => {
    if (leagueName.trim().length < 3 || leagueName.trim().length > 50) {
      // return confirm false
    } else {
      // return confirm true
    }
  }, [checked, leagueName, leagueType, maxParticipants]);

  return (
    <>
      <div className="si-league__overlay">
        <form className="" action="" method="" aria-label="">
          <div className="si-league__overlay-box">
            <h2 className="si-league__overlay-box-title">
              <span>
                {getTranslations(
                  "create_league_popup_choose_type_caption",
                  "Choose a type of league"
                )}
              </span>
            </h2>
            <div className="si-league__overlay-box-radio">
              <div className="si-radioBtn__wrap">
                {leagueType === "Classic Private" && (
                  <div
                    className={`si-radioBtn ${
                      leagueType === "private" ? "si-classic" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      id="createPrivateLeague"
                      name="leagueType"
                      value="private"
                      checked={true}
                      // onChange={(e) => handleLeagueTypeChange(e)}
                    />
                    <label htmlFor="createPrivateLeague">
                      <div className="si-league__radio-title">
                        <span>
                          {getTranslations(
                            "create_league_popup_private_league_text",
                            "Classic Private League"
                          )}
                        </span>
                      </div>
                      <div className="si-league__radio-caption">
                        <span>
                          {getTranslations(
                            "create_league_popup_private_league_caption",
                            "Can join only through invite"
                          )}
                        </span>
                      </div>
                      <PvtPubCardChips PvtPubCardChipsNameConfig={([0], [0])} />
                    </label>
                  </div>
                )}
                {leagueType === "Private H-T-H" && (
                  <div
                    className={`si-radioBtn ${
                      leagueType === "h2h" ? "si-h2h" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      id="createH2hLeague"
                      name="leagueType"
                      value="h2h"
                      checked={true}
                      // onChange={(e) => handleLeagueTypeChange(e)}
                    />
                    <label htmlFor="createH2hLeague">
                      <div className="si-league__radio-title">
                        <span>
                          {getTranslations(
                            "create_league_popup_h2h_league_text",
                            "Head 2 Head Private League"
                          )}
                        </span>
                      </div>
                      <div className="si-league__radio-caption">
                        <span>
                          {getTranslations(
                            "create_league_popup_h2h_league_caption",
                            "Compete against just one other player in this league"
                          )}
                        </span>
                      </div>
                      <PvtPubCardChips PvtPubCardChipsNameConfig={([1], [1])} />
                    </label>
                  </div>
                )}
                {leagueType === "Classic Public" && (
                  <div
                    className={`si-radioBtn ${
                      leagueType === "public" ? "si-public" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      id="createPublicLeague"
                      name="leagueType"
                      value="public"
                      checked={true}
                      // onChange={(e) => handleLeagueTypeChange(e)}
                    />
                    <label htmlFor="createPublicLeague">
                      <div className="si-league__radio-title">
                        <span>
                          {getTranslations(
                            "create_league_popup_public_league_text",
                            "Public League"
                          )}
                        </span>
                      </div>
                      <div className="si-league__radio-caption">
                        <span>
                          {getTranslations(
                            "create_league_popup_public_league_caption",
                            "Anyone can join -- no limits"
                          )}
                        </span>
                      </div>
                      <PvtPubCardChips PvtPubCardChipsNameConfig={([2], [2])} />
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="si-league__overlay-box">
            <div className="si-input ">
              <label className="si-lbl" htmlFor="createLeagueName">
                <span>
                  {getTranslations(
                    "create_league_popup_league_name_label",
                    "League Name"
                  )}
                </span>
                <span className="si-maxLimit">
                  {getTranslations(
                    "create_league_popup_league_name_validation",
                    "Max. {{MAX_CHARACTERS}} characters"
                  ).replace("{{MAX_CHARACTERS}}", MAX_LEAGUE_NAME_CHARACTERS)}
                </span>
              </label>
              <input
                type="text"
                id="createLeagueName"
                className=""
                placeholder={getTranslations(
                  "create_league_popup_league_name_placeholder",
                  "Enter your league name"
                )}
                value={leagueName}
                spellCheck="false"
                maxLength={MAX_LEAGUE_NAME_CHARACTERS}
                onChange={(e) => handleLeagueNameChange(e)}
              />
            </div>
          </div>
          <div className="si-league__overlay-box">
            <AffordableToggleSwitch
              toggleLabelText={getTranslations(
                "create_league_popup_unlimited_participants_toggle",
                "Unlimited no. of participants"
              )}
              handleChecked={handleChecked}
              checked={checked}
              leagueType={leagueType}
              disabled={
                isUnlimitedMembers ||
                leagueType === "h2h" ||
                leagueType === "Private H-T-H"
              }
            />
          </div>
          <div className="si-league__overlay-box si-league__overlay-box--grid">
            <div className="si-input ">
              <label className="si-lbl" htmlFor="maximumParticipants">
                <span>
                  {getTranslations(
                    "create_league_popup_maximum_participants_label",
                    "Maximum Participants"
                  )}
                </span>
              </label>
              <input
                type={checked ? "text" : "number"}
                id="maximumParticipants"
                className=""
                placeholder="10"
                disabled={
                  checked ||
                  leagueType === "h2h" ||
                  leagueType === "Private H-T-H"
                }
                value={checked ? "-" : maxParticipants}
                min={maxParticipants}
                max={99999999}
                spellCheck="false"
                onChange={(e) => handleMaxParticipantsChange(e)}
                onKeyDown={(event) => {
                  if (event.key === ".") event.preventDefault();
                }}
                onPaste={handlePasteMaxTeam}
              />
            </div>
            <div className="si-input ">
              <label className="si-lbl" htmlFor="numberTeamsAllow">
                <span>
                  {getTranslations(
                    "create_league_popup_maximum_teams_label_short",
                    "Teams per user"
                  )}
                </span>
              </label>
              <select
                id="numberTeamsAllow"
                name="numberTeamsAllow"
                aria-disabled="true"
                aria-expanded="true"
                aria-label="Select your country"
                disabled={true}
                value={maxTeams}
                onChange={(e) => handleMaxTeamsChange(e)}
              >
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
              </select>
            </div>
          </div>
          {!checked &&
          leagueType !== "h2h" &&
          leagueType !== "Private H-T-H" &&
          maxParticipants < 5 ? (
            <ErrorMessage
              text={getTranslations(
                "create_league_popup_max_participants_error",
                "Maximum teams can't be less than 5"
              )}
            />
          ) : (
            ""
          )}
          <ErrorMessage text="*Maximum Participants can be increased only by a count of 10 or more" />
        </form>
      </div>
    </>
  );
};

export { EditALeague };
