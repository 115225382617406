import { months } from ".";

export const currentFixture = (fixtureState) => {
  // return fixtureState?.list?.find((x) => x.MHIsCurrent == 1);

  const getFixture = (status) =>
    fixtureState?.list?.find((x) => getMatchStatus(x.MatchStatus) === status);
  if (getFixture("LIVE")) {
    return getFixture("LIVE");
  } else if (getFixture("PC IN PROGRESS")) {
    return getFixture("PC IN PROGRESS");
  } else if (getFixture("PROVISIONAL POINTS")) {
    return getFixture("PROVISIONAL POINTS");
  } else if (getFixture("CURRENT")) {
    return getFixture("CURRENT");
  } else if (getFixture("UPCOMING")) {
    return getFixture("UPCOMING");
  }
  // else if (getFixture("PC DONE")) {
  //   return getFixture("PC DONE");
  // }
};

export const getPreviousFixtures = (fixtureState) => {
  const getFixture = (status) =>
    fixtureState?.list?.filter((x) => getMatchStatus(x.MatchStatus) === status);
  return getFixture("PC DONE");
};

export const getUpcomingFixture = (fixtureState) => {
  const getFixture = (status) =>
    fixtureState?.list?.find((x) => getMatchStatus(x.MatchStatus) === status);

  if (getFixture("CURRENT")) {
    return getFixture("CURRENT");
  } else if (getFixture("UPCOMING")) {
    return getFixture("UPCOMING");
  }
};

export const getUpcomingThreeFixtures = (fixtureState, currentFixture) => {
  const getFixture = (status) =>
    fixtureState?.list?.filter((x) => getMatchStatus(x.MatchStatus) === status);

  // * Get upcoming fixtures and filtering out the current fixture
  const upcomingFixtures = getFixture("UPCOMING");
  const upcomingFixturesFiltered = upcomingFixtures?.filter(
    (item) => item?.MeetingNumber !== currentFixture?.MeetingNumber
  );

  /**
   * * Get Distinct upcoming fixtures from the filtered upcoming filters
   * * (the filtered fixtures contains all qualifying, race and sprint fixtures)
   * * Only one fixture of each will be present
   */
  const meetingNumbersSet = new Set();
  const upcomingFiltersDistinct = [];
  upcomingFixturesFiltered?.forEach((item) => {
    const { MeetingNumber = null } = item;

    if (MeetingNumber) {
      if (!meetingNumbersSet.has(MeetingNumber)) {
        upcomingFiltersDistinct.push(item);
        meetingNumbersSet.add(MeetingNumber);
      }
    }
  });

  // * the next three fixtures are pushed in the nextFixtures array
  const nextFixtures = [];
  for (
    let count = 0;
    count < 3 && count < upcomingFiltersDistinct?.length;
    count++
  ) {
    const nextFixture = upcomingFiltersDistinct?.[count];
    nextFixtures.push(nextFixture);
  }

  return nextFixtures;
};

export const getAllCompletedFixtures = (fixtureState) => {
  const getFixtures = (status) =>
    fixtureState?.list?.filter((x) => getMatchStatus(x.MatchStatus) === status);

  const completedFixtures = getFixtures("PC DONE");
  return completedFixtures;
};

export const getFixtureFromMatchDayId = (fixtureState, previousMatchdayId) => {
  const previousFixture = fixtureState?.list?.find(
    (item) => item?.GamedayId === previousMatchdayId
  );

  return previousFixture;
};

export const leaderBoardFixtures = (fixtureState) => {
  let fixtures = [];
  for (let index = 0; index < fixtureState?.list?.length; index++) {
    const element = fixtureState?.list[index];
    let fixtureIndex = fixtures?.findIndex(
      (x) => x.MeetingId === element.MeetingId
    );
    if (element.IsLeaderboardShow && fixtureIndex > -1) {
      fixtures[fixtureIndex] = element;
    } else if (element.IsLeaderboardShow) {
      fixtures.push(element);
    }
  }
  return (
    fixtures?.map((y) => ({
      id: y.GamedayId,
      value: y.MeetingName,
    })) || []
  );
};

export const opponentTeamFixtures = (fixtureState, opponentGameDays) => {
  return (
    opponentGameDays?.map((y) => ({
      id: Number(y),
      value: fixtureState.list.find((f) => f.GamedayId === Number(y))
        ?.MeetingName,
    })) || []
  );
};

export const getFixtureDateForLeagues = (meetingDates) => {
  if (!meetingDates) return "";

  const startDate = String(new Date(meetingDates[0])?.getDate()).padStart(
    2,
    "0"
  );
  const endDate = String(new Date(meetingDates[1])?.getDate()).padStart(2, "0");
  const startDateMonth = months[new Date(meetingDates[0])?.getMonth()]
    ?.split("")
    ?.slice(0, 3)
    ?.join("");
  const endDateMonth = months[new Date(meetingDates[1])?.getMonth()]
    ?.split("")
    ?.slice(0, 3)
    ?.join("");

  if (startDateMonth !== endDateMonth) {
    return `${startDate} ${startDateMonth} - ${endDate} ${endDateMonth}`;
  }

  return `${startDate} - ${endDate} ${endDateMonth}`;
};

export const getFixtureDates = (meetingDates) => {
  if (!meetingDates) return "";

  return `${new Date(meetingDates[0])
    .getDate()
    .toString()
    .padStart(2, "0")} - ${new Date(meetingDates[1])
    .getDate()
    .toString()
    .padStart(2, "0")}`;
};

export const getCompletedRaces = (fixtureState) => {
  return fixtureState?.list?.filter(
    (x) => x.SessionType === "Race" && x.GDStatus == 4
  );
};

export const getGDStatus = (status) => {
  switch (status) {
    case 0: // Upcoming
      return "UPCOMING";
    case 1: //current/ open
      return "CURRENT";
    case 2: // lock/live
      return "LIVE";
    case 3: // Subs / In between raceweek pc done
      return "SUBS";
    case 4: // View Points / all raceweek pc done
      return "COMPLETED";
    default:
      return "";
  }
};

export const getMatchStatus = (status) => {
  switch (status) {
    case 0: // upcoming
    case "0": // upcoming
      return "UPCOMING";
    case 1: // open /current
    case "1": // open /current
      return "CURRENT";
    case 2: // live
    case "2": // live
      return "LIVE";
    case 3: // pc in progress
    case "3": // pc in progress
      return "PC IN PROGRESS";
    case 4: // pc done
    case "4": // pc done
      return "PC DONE";
    case 5: // provisional points
    case "5": // provisional points
      return "PROVISIONAL POINTS";
    case 6: // provisional points
    case "6": // provisional points
      return "ABANDONED";

    default:
      break;
  }
};

export const checkIfRequiredFixtureIsActive = (fixture, meetingNumber) => {
  if (fixture?.MeetingNumber !== meetingNumber) {
    return false;
  }

  const fixtureMatchStatus = getMatchStatus(fixture?.MatchStatus);

  if (
    fixtureMatchStatus === "CURRENT" ||
    fixtureMatchStatus === "LIVE" ||
    fixtureMatchStatus === "PC IN PROGRESS"
  ) {
    return true;
  } else {
    return false;
  }
};

export const getFixtureCategory = (SessionType) => {
  switch (SessionType) {
    case "Qualifying":
      return "Q";
    case "Race":
      return "R";
    case "Sprint Qualifying":
      return "S";

    default:
      return "";
  }
};

export const getFixtureDay = (meetingDate) => {
  if (!meetingDate) return "";
  return new Date(meetingDate).getDate();
};

export const getFixtureMonth = (meetingDate) => {
  if (!meetingDate) return "";
  return months[new Date(meetingDate).getMonth()]
    .split("")
    .slice(0, 3)
    .join("");
};

export const getFixtureFromRaceWeek = (fixtureState, raceWeeks) => {
  const raceWeekSessions = fixtureState?.filter((x) =>
    raceWeeks?.includes(x.GamedayId)
  );
  let fixtures = [];
  for (let index = 0; index < raceWeekSessions?.length; index++) {
    const element = raceWeekSessions?.[index];
    let fixtureIndex = fixtures?.findIndex(
      (x) => x?.Gameday === element?.Gameday
    );

    if (fixtureIndex === -1) {
      fixtures?.push({
        Gameday: element?.Gameday,
        SessionStartDate: element?.SessionStartDate,
        SessionEndDate: element?.SessionEndDate,
        SessionStartDay: getFixtureDay(element?.SessionStartDate),
        SessionStartMonth: getFixtureMonth(element?.SessionStartDate),
        SessionEndDay: getFixtureDay(element?.SessionEndDate),
        SessionEndMonth: getFixtureMonth(element?.SessionEndDate),
        CountryName: element?.CountryName,
        CountryId: element?.CountryId,
        CircuitLocation: element?.CircuitLocation,
        MeetingNumber: element?.MeetingNumber,
      });
    } else {
      fixtures[fixtureIndex].SessionEndDate = element.SessionEndDate;
      fixtures[fixtureIndex].SessionEndDay = getFixtureDay(
        element?.SessionEndDate
      );
      fixtures[fixtureIndex].SessionEndMonth = getFixtureMonth(
        element?.SessionEndDate
      );
    }
  }
  return fixtures;
};

export const getMiniLeaguesLeaderBoardFilter = (fixtureState, raceWeeks) => {
  return leaderBoardFixtures(fixtureState)?.filter((fixture) =>
    raceWeeks?.includes(fixture.id)
  );
};

export const getMiniLeaguesOpponentTeamFixtures = (
  fixtureState,
  opponentGameDays,
  raceWeeks
) => {
  console.log(opponentTeamFixtures(fixtureState, opponentGameDays));
  return opponentTeamFixtures(fixtureState, opponentGameDays)?.filter(
    (fixture) => raceWeeks?.includes(fixture.id)
  );
};

export const sessionType = new Map();
sessionType.set("Qualifying", "live_qualifying");
sessionType.set("Race", "live_race");
sessionType.set("Sprint Qualifying", "live_sprint_qualifying");
