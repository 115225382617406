import React from "react";
import config from "../../Common/config";
import { IMAGE_VERSION } from "../../Common/constants";
import { BoosterChips } from "../BoosterChips";
import { splitName } from "../../Common/utils";
import StatisticsImage from "../Cloudinary/StatisticsImage";
import Media from "../media";

const PlayerMiniCard = ({
  teamID,
  imageSrc,
  imageName,
  PlayerMiniCardName,
  PlayerMiniCardTeam,
  booster = false,
  extraClass = "",
  isNameSplit = true,
  showImage = true,
  statisticsPlayer,
  notPlaying = false,
}) => {
  return (
    <>
      <div className={`si-miniCard__wrap ${extraClass}`}>
        {showImage && (
          <div
            className={`si-miniCard__lhs ${
              notPlaying ? "si-inActivePlayer" : ""
            }`}
          >
            <div className={`si-miniCard__thumb si-team__${teamID}`}>
              {statisticsPlayer ? (
                <StatisticsImage statisticsPlayer={statisticsPlayer} />
              ) : null}
            </div>
          </div>
        )}
        <div className="si-miniCard__rhs">
          <div className="si-miniCard__name">
            {isNameSplit ? (
              <>
                <span>{splitName(PlayerMiniCardName)?.firstName}</span>
                <span>
                  {splitName(PlayerMiniCardName)?.lastName?.toUpperCase()}
                </span>
              </>
            ) : (
              <span>{PlayerMiniCardName}</span>
            )}
          </div>
          {PlayerMiniCardTeam && (
            <div className="si-miniCard__team">
              <span>{PlayerMiniCardTeam}</span>
            </div>
          )}
        </div>
        {booster && (
          <div className="si-miniCard__icon">
            <BoosterChips {...booster} />
          </div>
        )}
        {notPlaying ? (
          <div className="si-miniCard__icon">
            {" "}
            <div className="si-player__status-icon">
              <i className="f1i-not-playing"></i>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export { PlayerMiniCard };
