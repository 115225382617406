import React from "react";
import Media from "../media";
import useCloudinaryData from "../../Common/hooks/useCloudinaryData";

const H2hNoData = ({ h2hIcon, h2hText }) => {
  const { cloudinaryBase, envVersion, currentSeasonYear } = useCloudinaryData();

  return (
    <>
      <div className="si-h2h__noData">
        <div className="si-h2h__noData-thumb">
          <Media
            imgSrc={`${cloudinaryBase}/${envVersion}/Fantasy/${currentSeasonYear}/images/leagues/h2h-nodata-thumb.png`}
            imgAlt={""}
            aria-label=""
          />
        </div>
        <div className="si-h2h__noData-box">
          <div className="si-h2h__noData-icon">
            <i className={h2hIcon}></i>
          </div>
          <div className="si-h2h__noData-text">{h2hText}</div>
        </div>
      </div>
    </>
  );
};

export { H2hNoData };
