import React from "react";
import config from "../../../Common/config";
import { IMAGE_VERSION } from "../../../Common/constants";
import { getTranslations } from "../../../Common/utils";
import GroupBtns from "../GroupBtns";
import { useSelector } from "react-redux";
import useCloudinaryData from "../../../Common/hooks/useCloudinaryData";
import Media from "../../../ReusableComponents/media";

const HeroBanner = ({
  leagueHeroBanner,
  leagueHeroBannerType = "private",
  showGroupButtons = true,
  customCls = "",
}) => {
  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;
  const { cloudinaryBase, currentSeasonYear, envVersion } = useCloudinaryData();
  return (
    <>
      <div className={`si-league__hero ${customCls}`}>
        {!leagueHeroBanner && (
          <div className="si-league__hero-thumb">
            <Media
              imgSrc={`${cloudinaryBase}/${envVersion}/Fantasy/${currentSeasonYear}/${webConfigData?.imagePaths?.leaguesAsset}leagues-hero-thumb.svg?v=${IMAGE_VERSION}`}
              imgAlt={getTranslations(
                "league_landing_private_league_banner_title",
                "Compete against your friends"
              )}
            />
          </div>
        )}
        <h2 className="si-league__hero-title">
          <span>
            {leagueHeroBanner
              ? getTranslations(
                  `leagues_summary_top_performing_${leagueHeroBannerType}_league`,
                  `Top Performing ${leagueHeroBannerType} League`
                )
              : getTranslations(
                  "league_landing_private_league_banner_title",
                  "Compete against your friends"
                )}
          </span>
        </h2>
        <div className="si-league__hero-caption">
          {leagueHeroBanner ? (
            <p>
              {getTranslations(
                "leagues_summary_join_or_create",
                "Join/Create a League to view Top performing leagues"
              )}
            </p>
          ) : (
            <>
              <p>
                {getTranslations(
                  "league_landing_private_league_banner_sub_title_1",
                  "You are not in any private league yet."
                )}
              </p>
              <p>
                {getTranslations(
                  "league_landing_private_league_banner_sub_title_2",
                  "Create your own, or join your friends if you have a league code."
                )}
              </p>
            </>
          )}
        </div>
        {showGroupButtons && <GroupBtns />}
      </div>
    </>
  );
};

export default HeroBanner;
