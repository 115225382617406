import {
  CLOUDINARY_IMAGE_BASE_URL,
  IMAGE_VERSION,
} from "../../../Common/constants";
import useCloudinaryData from "../../../Common/hooks/useCloudinaryData";
import { getBestPerformingDriver } from "../../../Common/utils/cloudinary";
import Media from "../../media";

const BestPerformingDriver = ({ player }) => {
  const { configData, currentSeasonYear } = useCloudinaryData();

  const playerImagePath = getBestPerformingDriver(
    player,
    currentSeasonYear,
    configData
  );

  return (
    <Media imgSrc={playerImagePath} imgAlt={player?.FUllName} />
    // <img
    //   src={playerImagePath}
    //   alt={player?.FUllName}
    //   onError={({ currentTarget }) => {
    //     currentTarget.onerror = null; // prevents looping
    //     currentTarget.src = `${CLOUDINARY_IMAGE_BASE_URL}common/fallback.svg?v=${IMAGE_VERSION}`;
    //   }}
    //   loading="lazy"
    // />
  );
};

export default BestPerformingDriver;
