import React from "react";
import { getTranslations, getYearForImagePath } from "../../Common/utils";
import config from "../../Common/config";
import { IMAGE_VERSION } from "../../Common/constants";
import { useSelector } from "react-redux";
import BestPerformingDriver from "../Cloudinary/BestPerformingDriver";
import BestPerformingConstructor from "../Cloudinary/BestPerformingConstructor";

const BestPerformanceCard = ({
  extraCls,
  teamID,
  playerId,
  playerType,
  cardTitle,
  userTeam,
  userRank,
  showTeamNo = true,
  currentSeasonYear = null,
}) => {
  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;
  const driverState = useSelector((state) => state.driver);

  const currentPlayer = driverState?.list?.find(
    (player) => player?.PlayerId === playerId
  );

  return (
    <>
      <div
        className={`si-bestPerf__card si-team__${teamID} ${
          extraCls ? extraCls : ""
        }`}
      >
        <div className="si-bestPerf__card-icon">
          <i className="f1i-trophy"></i>
        </div>
        <div className="si-bestPerf__card-thumb">
          {playerType === "Driver" && (
            <BestPerformingDriver player={currentPlayer} />
          )}
          {playerType === "Constructor" && (
            <BestPerformingConstructor player={currentPlayer} />
          )}
        </div>
        <div className="si-bestPerf__card-content">
          <h2 className="si-bestPerf__card-title">{cardTitle}</h2>
          <div className="si-bestPerf__card-userRank">
            {showTeamNo && <span>{userTeam}</span>}{" "}
            {getTranslations("total_points_lbl", "Total Points")}: {userRank}
          </div>
        </div>
      </div>
    </>
  );
};

export { BestPerformanceCard };
