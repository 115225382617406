import React, { useEffect, useRef } from "react";
import {
  camelCaseMiniLeagueDL,
  getPrize,
  getTranslations,
  getYearForImagePath,
  isAndroid,
  isIOS,
} from "../../Common/utils";
import config from "../../Common/config";
import {
  FANTASY_ANDROID,
  FANTASY_IOS,
  FANTASY_WEBSITE,
  IMAGE_VERSION,
  WEB,
  WEBVIEW,
} from "../../Common/constants";
import { useSelector } from "react-redux";
import useDataLayer from "../../Common/hooks/useDataLayer";
import useCloudinaryData from "../../Common/hooks/useCloudinaryData";

const PrizesBox = ({
  prizeType,
  prizeTitle,
  prizeCaption,
  prizePosition,
  prizeThumb,
  prizeMedal,
  miniLeague,
}) => {
  const { cloudinaryBase, envVersion, currentSeasonYear } = useCloudinaryData();
  const captionRef = useRef();
  const translationState = useSelector((state) => state.translation);
  const userState = useSelector((state) => state.user);
  const pushToDataLayer = useDataLayer();
  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;

  const language = translationState?.language;
  const webview = userState?.webview;

  useEffect(() => {
    const tAndCLink = captionRef?.current?.querySelector("a");

    const pushTAndCClickDataLayer = () => {
      const dataLayerObject = {
        pageName: "Prizes",
        actionType: "Terms & Conditions",
        locationInPage: `${camelCaseMiniLeagueDL(
          miniLeague?.name
        )} | ${getPrize(prizePosition)}`,
        path: tAndCLink?.getAttribute("href"),
        clickText: tAndCLink?.innerText,
        platform: isAndroid()
          ? FANTASY_ANDROID
          : isIOS()
          ? FANTASY_IOS
          : FANTASY_WEBSITE,
        componentType: webview ? WEBVIEW : WEB,
      };

      pushToDataLayer("linkClick", dataLayerObject);
    };

    tAndCLink?.addEventListener("click", pushTAndCClickDataLayer);

    return () => {
      tAndCLink?.removeEventListener("click", pushTAndCClickDataLayer);
    };
  }, []);

  return (
    <>
      <div
        className={`si-prize__box si-prize__box--mini si-prize__box--${prizeType}`}
      >
        <div className="si-prize__box-pos">
          <i className="f1i-leaf-left"></i>
          <span
            dangerouslySetInnerHTML={{ __html: getTranslations(prizePosition) }}
          ></span>
          <i className="f1i-leaf-right"></i>
        </div>
        <div className="si-prize__box-thumb">
          <img
            src={`${cloudinaryBase}/${envVersion}/Fantasy/${webConfigData?.imagePaths?.prizes?.replace(
              "{seasonYear}",
              currentSeasonYear
            )}${prizeThumb}`}
            alt={prizeTitle}
            loading="lazy"
            onError={(e) => {
              e.target.src = `${cloudinaryBase}/${envVersion}/Fantasy/${webConfigData?.imagePaths?.prizes?.replace(
                "{seasonYear}",
                currentSeasonYear
              )}0.jpg`;
            }}
          />
        </div>
        <div
          className="si-prize__box-title"
          dangerouslySetInnerHTML={{ __html: getTranslations(prizeTitle) }}
        ></div>
        <div className="si-prize__box-caption">
          <p
            ref={captionRef}
            dangerouslySetInnerHTML={{
              __html: getTranslations(prizeCaption)?.replace(
                "{{LANGUAGE}}",
                language
              ),
            }}
          ></p>
        </div>
      </div>
    </>
  );
};

export { PrizesBox };
