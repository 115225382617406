import { useMemo } from "react";
import useCloudinaryData from "../../../Common/hooks/useCloudinaryData";
import {
  getStatisticsConstructorImage,
  getStatisticsDriverImage,
} from "../../../Common/utils/cloudinary";
import {
  CLOUDINARY_IMAGE_BASE_URL,
  IMAGE_VERSION,
} from "../../../Common/constants";
import Media from "../../media";

const StatisticsImage = ({ statisticsPlayer }) => {
  const { configData, currentSeasonYear } = useCloudinaryData();

  const playerImage = useMemo(() => {
    if (statisticsPlayer?.PositionName === "DRIVER") {
      return getStatisticsDriverImage(
        statisticsPlayer,
        currentSeasonYear,
        configData
      );
    } else if (statisticsPlayer?.PositionName === "CONSTRUCTOR") {
      return getStatisticsConstructorImage(
        statisticsPlayer,
        currentSeasonYear,
        configData
      );
    } else {
      return null;
    }
  }, [configData, currentSeasonYear, statisticsPlayer]);

  return (
    <Media imgSrc={playerImage} imgAlt={statisticsPlayer?.FUllName} />
    // <img
    //   src={playerImage}
    //   alt={statisticsPlayer?.FUllName}
    //   onError={({ currentTarget }) => {
    //     currentTarget.onerror = null; // prevents looping
    //     currentTarget.src = `${CLOUDINARY_IMAGE_BASE_URL}common/fallback.svg?v=${IMAGE_VERSION}`;
    //   }}
    //   loading="lazy"
    // />
  );
};

export default StatisticsImage;
