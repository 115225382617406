export const APPLY_FINAL_FIX = "APPLY_FINAL_FIX";
export const APPLY_FINAL_FIX_PENDING = "APPLY_FINAL_FIX_PENDING";
export const APPLY_FINAL_FIX_REJECTED = "APPLY_FINAL_FIX_REJECTED";
export const APPLY_FINAL_FIX_FULFILLED = "APPLY_FINAL_FIX_FULFILLED";
export const RESET_FINAL_FIX = "RESET_FINAL_FIX";

export const APPLY_AUTO_PILOT = "APPLY_AUTO_PILOT";
export const APPLY_AUTO_PILOT_PENDING = "APPLY_AUTO_PILOT_PENDING";
export const APPLY_AUTO_PILOT_REJECTED = "APPLY_AUTO_PILOT_REJECTED";
export const APPLY_AUTO_PILOT_FULFILLED = "APPLY_AUTO_PILOT_FULFILLED";
export const RESET_AUTO_PILOT = "RESET_AUTO_PILOT";

export const APPLY_LIMITLESS = "APPLY_LIMITLESS";
export const APPLY_LIMITLESS_PENDING = "APPLY_LIMITLESS_PENDING";
export const APPLY_LIMITLESS_REJECTED = "APPLY_LIMITLESS_REJECTED";
export const APPLY_LIMITLESS_FULFILLED = "APPLY_LIMITLESS_FULFILLED";
export const RESET_LIMITLESS = "RESET_LIMITLESS";

export const APPLY_EXTRA_DRS = "APPLY_EXTRA_DRS";
export const APPLY_EXTRA_DRS_PENDING = "APPLY_EXTRA_DRS_PENDING";
export const APPLY_EXTRA_DRS_REJECTED = "APPLY_EXTRA_DRS_REJECTED";
export const APPLY_EXTRA_DRS_FULFILLED = "APPLY_EXTRA_DRS_FULFILLED";
export const RESET_EXTRA_DRS = "RESET_EXTRA_DRS";
export const RESET_EXTRA_TURBO_DRIVER = "RESET_EXTRA_TURBO_DRIVER";

export const APPLY_NON_NEGATIVE = "APPLY_NON_NEGATIVE";
export const APPLY_NON_NEGATIVE_PENDING = "APPLY_NON_NEGATIVE_PENDING";
export const APPLY_NON_NEGATIVE_REJECTED = "APPLY_NON_NEGATIVE_REJECTED";
export const APPLY_NON_NEGATIVE_FULFILLED = "APPLY_NON_NEGATIVE_FULFILLED";
export const RESET_NON_NEGATIVE = "RESET_NON_NEGATIVE";

export const CHANGE_EXTRA_DRS = "CHANGE_EXTRA_DRS";
export const CHANGE_EXTRA_DRS_PENDING = "CHANGE_EXTRA_DRS_PENDING";
export const CHANGE_EXTRA_DRS_REJECTED = "CHANGE_EXTRA_DRS_REJECTED";
export const CHANGE_EXTRA_DRS_FULFILLED = "CHANGE_EXTRA_DRS_FULFILLED";
export const RESET_CHANGE_EXTRA_DRS = "RESET_CHANGE_EXTRA_DRS";
