import React from "react";
import { SectionHeadingWithFiller } from "../HeadingWithFiller";
import { getPath, getTranslations } from "../../Common/utils";
import config from "../../Common/config";
import { IMAGE_VERSION } from "../../Common/constants";
import { CtaButton } from "../Buttons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useHowToPlayLearnMoreDL } from "./useHowToPlayLearnMoreDL";
import useCloudinaryData from "../../Common/hooks/useCloudinaryData";
import Media from "../media";

const HowToPlay = () => {
  const navigate = useNavigate();
  const { cloudinaryBase, currentSeasonYear, envVersion } = useCloudinaryData();
  const { handleLearnMoreDLClick } = useHowToPlayLearnMoreDL();
  const translationState = useSelector((state) => state.translation);
  const buttonText = getTranslations("how_to_play_learn_more", "Learn more");

  const handleLearnMoreClick = () => {
    const navigationPath = getPath(translationState, "howToPlay");
    const dataLayerActionType = "Learn More";

    handleLearnMoreDLClick({
      path: navigationPath,
      clickText: buttonText,
      actionType: dataLayerActionType,
      pageName: "Home",
    });
    navigate(navigationPath);
  };

  return (
    <>
      <section className="si-htp__wrap si-htp__wrap--section">
        <div className="si-main__container">
          <div className="si-htp__grid">
            <div className="si-htp__grid-lhs">
              <SectionHeadingWithFiller
                heading={getTranslations(
                  "landing_page_how_to_play_title_new",
                  "How to play",
                  "caps"
                )}
              />
              <div className="si-htp__caption">
                {getTranslations(
                  "landing_page_how_to_play_description_new",
                  "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa"
                )}
              </div>
              <div className="si-htp__cta">
                <CtaButton
                  variant={"secondary"}
                  icon="f1i-chevron-right"
                  ariaLabel={buttonText}
                  onClick={handleLearnMoreClick}
                >
                  {buttonText}
                </CtaButton>
              </div>
            </div>
            <div className="si-htp__grid-rhs">
              <div className="si-htp__grid-thumb">
                <Media
                  imgSrc={`${cloudinaryBase}/${envVersion}/Fantasy/${currentSeasonYear}/images/htp/htp-placeholder.svg?v=${IMAGE_VERSION}`}
                  imgAlt={""}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { HowToPlay };
