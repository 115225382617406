import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  copyToClipboard,
  decodeName,
  getTranslations,
} from "../../../Common/utils";
import { league } from "../../../redux/actions";
import { CtaButton } from "../../Buttons";
import { MiniLeagueCardInfo } from "../../MiniLeagueCardInfo";
import { PvtPubCardChips } from "../../PvtPubCardChips";

const Congratulations = (props) => {
  const {
    leagueName = "",
    leagueType = 0,
    leagueCode = "",
    leagueId,
    maxTeams,
  } = props;

  const dispatch = useDispatch();
  const leagueState = useSelector((state) => state.league);
  const translationState = useSelector((state) => state.translation);
  const { createPrivateLeagueSuccessData } = leagueState;

  const copyClick = () => {
    dispatch(league.toggleCopyPopUp());
    setTimeout(() => {
      dispatch(league.toggleCopyPopUp());
    }, 1000);
  };

  return (
    <>
      <div className="si-congratulations__header">
        {leagueType === 4 ? (
          <MiniLeagueCardInfo
            MiniLeagueCardInfoConfig={{
              showThumb: true,
              showLeagueCount: false,
              imageSrc: "mini-leagues/logo/",
              imageName: `${leagueId}.png`,
              leagueName: leagueName,
              showPvtPubCardChips: true,
              showMaxTeams: true,
              type: leagueType,
              maxTeams: maxTeams,
            }}
          />
        ) : (
          <>
            <h2 className="si-congratulations__title">
              <span>“{decodeName(leagueName)}”</span>
            </h2>
            <div className="si-congratulations__chip">
              <PvtPubCardChips
                PvtPubCardChipsNameConfig={([leagueType], [leagueType])}
              />
            </div>
          </>
        )}
      </div>

      <div className="si-congratulations__box">
        <h3 className="si-congratulations__box-title">
          <span>
            {getTranslations(
              "create_league_completed_share_link_title",
              "Invite your friends"
            )}{" "}
          </span>
        </h3>
        <div className="si-congratulations__box-caption">
          <span>
            {getTranslations(
              "create_league_completed_share_link_sub_title",
              "Invite friends to your league whenever you want by sharing this link"
            )}
          </span>
        </div>
        <div className="si-congratulations__input">
          <div className="si-congratulations__input-text">
            <span id="copyLeagueUrl">
              {`${window.location.origin}/${translationState?.language}/leagues/join/${leagueCode}`}
            </span>
          </div>
          <div className="si-congratulations__input-cta">
            <CtaButton
              onlyIcon={true}
              variant={"primaryDark"}
              btnType="submit"
              icon="f1i-pop-up"
              onClick={() => {
                copyToClipboard("copyLeagueUrl");
                copyClick();
              }}
              restProps={{
                ariaLabel: getTranslations(
                  "create_league_completed_share_aria_label",
                  "click here to copy the share link"
                ),
              }}
            />
          </div>
        </div>
      </div>
      <div className="si-congratulations__box si-congratulations__box--share">
        <h3 className="si-congratulations__box-title">
          <span>
            {getTranslations(
              "create_league_completed_share_league_code_title",
              "League code"
            )}
          </span>
        </h3>
        <div className="si-congratulations__box-caption">
          <span>
            {getTranslations(
              "create_league_completed_share_league_code_sub_title",
              "Your friends can also join by entering this code"
            )}
          </span>
        </div>
        <div className="si-congratulations__input">
          <div className="si-congratulations__input-text">
            <span id="copyLeagueCode">{leagueCode}</span>
          </div>
          <div className="si-congratulations__input-cta">
            <CtaButton
              variant={"primaryDark"}
              onlyIcon={true}
              btnType="submit"
              icon="f1i-pop-up"
              onClick={() => {
                copyToClipboard("copyLeagueCode");
                copyClick();
              }}
              restProps={{
                ariaLabel: getTranslations(
                  "create_league_completed_share_aria_label",
                  "click here to copy the share link"
                ),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { Congratulations };
