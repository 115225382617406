import React from "react";
import config from "../../Common/config";
import {
  IMAGE_VERSION,
  getLeagueBadge,
  leagueBadgeKeys,
} from "../../Common/constants";
import { getTranslations, isMobile } from "../../Common/utils";
import { CtaButton } from "../Buttons";
import { FixDates } from "../Fixtures/FixDates";
import { FixRound } from "../Fixtures/FixRound";
import { PvtPubCardChips } from "../PvtPubCardChips";
import { currentFixture, getMatchStatus } from "../../Common/utils/fixture";
import { MatchData } from "../MatchHeader";
import { useSelector } from "react-redux";
import { ProvisionalPtsTooltip } from "../ProvisionalPtsTooltip";
import useTournamentScanario from "../../Common/hooks/useTournamentScanario";
import Profanity from "../../Components/Profanity";
import MatchStatus from "../MatchStatus";
import { getRank } from "../../Common/utils/league";
import LeagueLogo from "../Cloudinary/LeagueLogo";
import StatisticsImage from "../Cloudinary/StatisticsImage";
import RaceweekFlag from "../Cloudinary/RaceweekFlag";

const PvtPubCardInfo = ({ PvtPubCardConfig }) => {
  const fixtureState = useSelector((state) => state.fixture);
  const {
    showPvtPubCardChips = false,
    showThumb = false,
    conditionClsName,
    teamID,
    leagueName,
    leagueCount,
    maxLeagueCount = null,
    imageSrc,
    imageName,
    leagueId,
    showLeagueCount = true,
    type = 0,
    showRound = false,
    roundNo = "",
    showDate = false,
    fixDates = "",
    fixRoundLbl = false,
    editName = false,
    adminIcon = false,
    errorIcon = false,
    showProvisionalPts = false,
    handleEditClick = () => {
      return null;
    },
    showMaxTeams = false,
    maxTeams,
    userJoinedTeams = 0,
    defaultImageName = null,
    showProfanityIcon,
    webPurifyStatus,
    showRHS = true,
    showBottomInfo = true,
    leagueBadgeId = null,
    leagueType = null,
    player,
    displayPlayerImage = false,
    isRaceWeekFlag = false,
    isSeasonData = false,
    countryName,
  } = PvtPubCardConfig;
  const { isEos, lastGamedayRaceLocked } = useTournamentScanario();
  const disableCTAs = isEos || lastGamedayRaceLocked;

  const isGlobal = () => {
    return (
      leagueType === "Global" ||
      leagueType === "Country" ||
      leagueType === "Team" ||
      leagueType === "Driver"
    );
  };

  return (
    <>
      <div className="si-league__card">
        <div className="si-league__card-lhs">
          {showThumb && (
            <div
              className={`si-league__card-thumb ${teamID? `si-team__${teamID}` : ""} ${conditionClsName ? conditionClsName : ""}`}
            >
              {isRaceWeekFlag ? (
                isSeasonData ? (
                  <LeagueLogo isGlobal={true} leagueType={"Global"} />
                ) : (
                  <RaceweekFlag countryName={countryName} />
                )
              ) : displayPlayerImage ? (
                <StatisticsImage statisticsPlayer={player} />
              ) : (
                <LeagueLogo
                  imageSrc={imageSrc}
                  imageName={imageName}
                  leagueId={leagueId}
                  isGlobal={isGlobal()}
                  leagueType={leagueType}
                />
              )}
            </div>
          )}
          {showRHS && (
            <div className="si-league__card-rhs">
              <h3
                className={
                  !editName
                    ? "si-league__card-title"
                    : "si-league__card-title si-editEnable"
                }
              >
                <span>{leagueName}</span>
                {errorIcon ? (
                  <div className="si-league__card-icon si-error">
                    <i className="f1i-error"></i>
                  </div>
                ) : adminIcon ? (
                  showProfanityIcon ? (
                    <Profanity
                      webPurifyStatus={webPurifyStatus}
                      type="league"
                    />
                  ) : (
                    <div className="si-league__card-icon">
                      <i className="f1i-admin"></i>
                    </div>
                  )
                ) : (
                  ""
                )}
                {editName ? (
                  <>
                    <CtaButton
                      onlyIcon={true}
                      variant={"primaryLight"}
                      icon="f1i-edit"
                      disabled={disableCTAs}
                      onClick={() => handleEditClick()}
                      restProps={{
                        ariaLabel: getTranslations(
                          "manage_team_edit_team_name_desc",
                          "Click to edit your league name" // TODO: Change this
                        ),
                      }}
                    />
                  </>
                ) : (
                  ""
                )}
              </h3>
              {!!leagueBadgeId && (
                <MatchStatus
                  {...getLeagueBadge(leagueBadgeKeys?.[leagueBadgeId])}
                />
              )}
            </div>
          )}
        </div>
        {showBottomInfo && (
          <div className="si-league__card-below">
            {(showPvtPubCardChips ||
              showLeagueCount ||
              showMaxTeams ||
              showRound ||
              showDate) && (
              <div
                className={`si-league__card-info ${
                  showProvisionalPts ? "si-league__card-info--reset" : ""
                }`}
              >
                <div className="si-league__card-info-lhs">
                  {showPvtPubCardChips && (
                    <PvtPubCardChips PvtPubCardChipsNameConfig={type} />
                  )}
                  {showMaxTeams && (
                    <div className="si-league__card-caption">
                      <i className="f1i-teams"></i>
                      <span>
                        {/* {getTranslations(
                          "league_landing_public_league_max_team_per_user",
                          "Max team per user"
                        )} */}
                        <em>
                          {maxTeams ? `${userJoinedTeams}/${maxTeams}` : "-"}
                        </em>
                      </span>
                    </div>
                  )}
                  {showLeagueCount && (
                    <>
                      {`|`}
                      <div className="si-league__card-caption">
                        <span>
                          {getTranslations(
                            "league_card_total_team",
                            "Total Team:"
                          )}
                          <em>
                            {leagueCount ? leagueCount : 0}
                            {maxLeagueCount ? `/${maxLeagueCount}` : ""}
                          </em>
                          {/* {leagueCount < 1
                          ? getTranslations(
                              "league_landing_public_league_table_member",
                              "team"
                            )
                          : getTranslations(
                              "league_landing_public_league_table_members",
                              "members"
                            )} */}
                        </span>
                      </div>
                    </>
                  )}
                </div>
                {showProvisionalPts &&
                  getMatchStatus(currentFixture(fixtureState)?.MatchStatus) ===
                    "PROVISIONAL POINTS" &&
                  (isMobile() ? (
                    <ProvisionalPtsTooltip isTooltipTrue={true} />
                  ) : (
                    <MatchData
                      showFixtureData={false}
                      MatchStatusWrap={true}
                      fixtureState={fixtureState}
                    />
                  ))}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export { PvtPubCardInfo };
