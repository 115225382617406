import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decodeName, getTranslations } from "../../../Common/utils";
import { useNavigate } from "react-router-dom";
import { getPath } from "../../../Common/utils";
import { league } from "../../../redux/actions";
import { CtaButton } from "../../Buttons";
import { NoDataFound } from "../../NoDataFound";
import useTournamentScanario from "../../../Common/hooks/useTournamentScanario";

const List = ({ leagueManageConfig }) => {
  const dispatch = useDispatch();
  const {
    leagueManageCTA = false,
    data,
    adminSocialId = "",
    selectMember,
    isAdminData,
    isAdmin,
    maxTeams = 0,
  } = leagueManageConfig;

  const [adminSocialID, setAdminSocialID] = useState("");
  const { isEos, lastGamedayRaceLocked } = useTournamentScanario();

  const disableCTAs = isEos || lastGamedayRaceLocked;

  useEffect(() => {
    if (adminSocialId.length > 0) {
      setAdminSocialID(adminSocialId);
    }
  }, [adminSocialId]);

  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);
  const leagueState = useSelector((state) => state.league);
  const translationState = useSelector((state) => state.translation);
  const gameplayState = useSelector((state) => state.gameplay);

  const { teams } = gameplayState;
  const { showRemoveMember, selectedLeague } = leagueState;
  const leagueCode = selectedLeague?.leagueCode;
  const SocialId = userState?.data?.SocialId;

  const toggleRemoveMember = (item) => {
    dispatch(league.toggleRemoveMember(item));
  };

  if (
    !isAdminData &&
    data?.length === 1 &&
    data?.[0]?.socialId === adminSocialID
  ) {
    return isAdmin ? (
      <NoDataFound
        noDataCaption={getTranslations(
          "manage_league_no_team_found",
          "No Team Found"
        )}
      />
    ) : null;
  }

  const displayAddTeam = () => {
    const currentUserJoinedLeague = data?.find(
      (item) => parseInt(item?.socialId) === parseInt(SocialId)
    );
    const userTeamNo = currentUserJoinedLeague?.userTeamNo;
    const userTeamsLeagueJoinedCount = userTeamNo?.length;
    if (userTeamsLeagueJoinedCount < maxTeams) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="si-league__manage-list si-league__manage-list--withName">
        <div className="si-league__card-header">
          {isAdminData ? (
            <h3 className="si-league__card-title">
              <span>
                {getTranslations("league_setting_manager", "Manager")}
              </span>
              <div className="si-league__card-icon">
                <i className="f1i-admin"></i>
              </div>
            </h3>
          ) : (
            <h3 className="si-league__card-title">
              <span>
                {getTranslations("league_setting_members", "Member(s)")}
              </span>
            </h3>
          )}
        </div>

        <ul>
          {data?.map((item, index) => {
            if (!isAdminData && item?.socialId === adminSocialID) {
              return null;
            }

            return (
              <li key={index}>
                <div className="si-league__manage-box">
                  <div className="si-league__manage-user">
                    <span>{item?.userName ? item?.userName : null}</span>
                    {leagueManageCTA &&
                    item?.socialId !== adminSocialId &&
                    !isAdminData ? (
                      <div className="si-league__manage-rhs">
                        <CtaButton
                          size={"md"}
                          variant={"link"}
                          disabled={disableCTAs}
                          onClick={() => toggleRemoveMember(item)}
                        >
                          Remove
                        </CtaButton>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="si-league__manage-grid">
                    <div className="si-league__manage-lhs">
                      <div className="si-league__manage-team si-league__manage-team--withName">
                        {item?.userTeamNo?.map((x, index) => (
                          <div key={index} className="si-myTeamHeader__serial">
                            <span
                              className={`si-myTeamHeader__serial-number si-team${
                                index + 1
                              }`}
                            >
                              T{x}
                            </span>
                            <span className="si-myTeamHeader__serial-lbl">
                              {decodeName(item?.teamName?.[index])}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                    {parseInt(item?.socialId) === SocialId &&
                      displayAddTeam() && (
                        <CtaButton
                          variant={"ghost"}
                          icon="f1i-add"
                          disabled={disableCTAs}
                          onClick={() => {
                            navigate(
                              getPath(translationState, "selectedJoinLeague", {
                                code: leagueCode,
                              })
                            );
                          }}
                        >
                          Add Team
                        </CtaButton>
                      )}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default List;
