import React from "react";
import { useSelector } from "react-redux";
import { getTranslations } from "../../Common/utils";
import { AddPlayer } from "../Formations/AddPlayer";
import { RemovePlayer } from "../Formations/RemovePlayer";

const Formations = ({
  selectedPlayers,
  playerCardConfig,
  turboDriver,
  extraTurboDriver = null,
  hideCloseButton = false,
  showReplacedPlayer = false,
  newLinePlayers = null,
  showPoints = false,
  isLateOnBoard = false,
  replacedPlayer = null,
  isFinalFix = false,
  team = {},
  currentSeasonYear = null,
  closeButtonVariant,
  showButtonText,
}) => {
  const players = selectedPlayers?.map((x, index) => ({
    ...x,
    formationIndex: index,
  }));

  const firstRow = players?.slice(0, 2);
  const secondRow = players?.slice(2, 5);
  const thirdRow = players?.slice(-2);

  const getPlayerType = (player, type = 1) => {
    return player?.Skill || type;
  };

  if (hideCloseButton) {
    playerCardConfig = {
      ...playerCardConfig,
      ctaBtn: false,
      notPlayingOnlyIcon: true,
    };
  }

  const gameplayState = useSelector((state) => state.gameplay);

  return (
    <>
      <section className="si-formation__wrap">
        <div className="si-formation__container">
          <div className="si-formation__drivers">
            <div className="si-formation__row">
              <ul>
                {firstRow?.map((row, index) => (
                  <li key={index}>
                    {row?.PlayerId ? (
                      <div className="si-player__card si-remove">
                        <RemovePlayer
                          player={row}
                          playerCardConfig={{
                            ...playerCardConfig,
                            isPlayerPlaying: row?.IsActive === "1",
                            notPlayingOnlyIcon: true,
                            playerImageType: "pitch-view",
                            addingExtraClass: `${
                              row?.isSwappedInPlayer || row?.isSwappedOutPlayer
                                ? "si-replaced"
                                : ""
                            } ${row?.isSwappedInPlayer ? "isswappedin" : ""}`,
                          }}
                          isTurboDriver={
                            turboDriver?.PlayerId === row?.PlayerId
                          }
                          isExtraTurboDriver={
                            extraTurboDriver?.PlayerId === row?.PlayerId
                          }
                          showPoints={showPoints}
                          isLateOnBoard={isLateOnBoard}
                          isFinalFix={isFinalFix}
                          team={team}
                          currentSeasonYear={currentSeasonYear}
                          closeButtonVariant={closeButtonVariant}
                          showButtonText={showButtonText}
                          dataText={
                            row?.isSwappedOutPlayer || row?.isSwappedInPlayer
                              ? getTranslations(
                                  "player_swapped_text",
                                  "Swapped"
                                )
                              : null
                          }
                        />
                      </div>
                    ) : (
                      // ? UX guy wants click here instead of the button
                      <div className="si-player__card si-add">
                        <AddPlayer player={row} type={getPlayerType(row, 1)} />
                      </div>
                    )}
                  </li>
                ))}
                {showReplacedPlayer && replacedPlayer && (
                  <li>
                    <div className="si-player__card si-remove">
                      <RemovePlayer
                        player={replacedPlayer}
                        playerCardConfig={{
                          ...playerCardConfig,
                          addingExtraClass: "si-replaced",
                          isPlayerPlaying: replacedPlayer.IsActive === "1",
                          notPlayingOnlyIcon: true,
                          playerImageType: "pitch-view",
                        }}
                        dataText={
                          replacedPlayer?.isFinalFixOldPlayer
                            ? getTranslations(
                                "final_fix_replaced_text",
                                "Replaced"
                              )
                            : replacedPlayer?.isSwappedOutPlayer ||
                              replacedPlayer?.isSwappedInPlayer
                            ? getTranslations("player_swapped_text", "Swapped")
                            : null
                        }
                        showPoints={showPoints}
                        isLateOnBoard={isLateOnBoard}
                        isFinalFix={isFinalFix}
                        team={team}
                        currentSeasonYear={currentSeasonYear}
                        closeButtonVariant={closeButtonVariant}
                        showButtonText={showButtonText}
                      />
                    </div>
                  </li>
                )}
              </ul>
            </div>

            <div className="si-formation__row">
              <ul>
                {secondRow?.map((row, index) => (
                  <li key={index}>
                    {row?.PlayerId ? (
                      <div className="si-player__card si-remove">
                        <RemovePlayer
                          player={row}
                          playerCardConfig={{
                            ...playerCardConfig,
                            isPlayerPlaying: row?.IsActive === "1",
                            notPlayingOnlyIcon: true,
                            playerImageType: "pitch-view",
                            addingExtraClass: `${
                              row?.isSwappedInPlayer || row?.isSwappedOutPlayer
                                ? "si-replaced"
                                : ""
                            } ${row?.isSwappedInPlayer ? "isswappedin" : ""}`,
                          }}
                          isTurboDriver={
                            turboDriver?.PlayerId === row?.PlayerId
                          }
                          isExtraTurboDriver={
                            extraTurboDriver?.PlayerId === row?.PlayerId
                          }
                          showPoints={showPoints}
                          isLateOnBoard={isLateOnBoard}
                          isFinalFix={isFinalFix}
                          team={team}
                          currentSeasonYear={currentSeasonYear}
                          closeButtonVariant={closeButtonVariant}
                          showButtonText={showButtonText}
                          dataText={
                            row?.isSwappedOutPlayer || row?.isSwappedInPlayer
                              ? getTranslations(
                                  "player_swapped_text",
                                  "Swapped"
                                )
                              : null
                          }
                        />
                      </div>
                    ) : (
                      <div className="si-player__card si-add">
                        <AddPlayer player={row} type={getPlayerType(row, 1)} />
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            {!!newLinePlayers?.length && (
              <div className="si-formation__row">
                <ul>
                  {newLinePlayers?.map((row, index) => (
                    <li key={index}>
                      {row?.PlayerId ? (
                        <div className="si-player__card si-remove">
                          <RemovePlayer
                            player={row}
                            playerCardConfig={{
                              ...playerCardConfig,
                              isPlayerPlaying: row?.IsActive === "1",
                              notPlayingOnlyIcon: true,
                              playerImageType: "pitch-view",
                              addingExtraClass: "si-replaced",
                            }}
                            dataText={getTranslations(
                              "player_swapped_text",
                              "Swapped"
                            )}
                            isTurboDriver={
                              turboDriver?.PlayerId === row?.PlayerId
                            }
                            isExtraTurboDriver={
                              extraTurboDriver?.PlayerId === row?.PlayerId
                            }
                            showPoints={showPoints}
                            isLateOnBoard={isLateOnBoard}
                            isFinalFix={isFinalFix}
                            team={team}
                            currentSeasonYear={currentSeasonYear}
                            closeButtonVariant={closeButtonVariant}
                            showButtonText={showButtonText}
                          />
                        </div>
                      ) : (
                        <div className="si-player__card si-add">
                          <AddPlayer
                            player={row}
                            type={getPlayerType(row, 1)}
                          />
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="si-formation__constructors">
            <div className="si-formation__row">
              <ul>
                {thirdRow?.map((row, index) => (
                  <li key={index}>
                    {row?.PlayerId ? (
                      <div className="si-player__card si-remove">
                        <RemovePlayer
                          player={row}
                          playerCardConfig={{
                            ...playerCardConfig,
                            ctaBtn: isFinalFix
                              ? false
                              : hideCloseButton
                              ? false
                              : true,
                            playerImageType: "pitch-view",
                          }}
                          showPoints={showPoints}
                          isLateOnBoard={isLateOnBoard}
                          team={team}
                          currentSeasonYear={currentSeasonYear}
                          closeButtonVariant={closeButtonVariant}
                          showButtonText={showButtonText}
                        />
                      </div>
                    ) : (
                      <div className="si-player__card si-add">
                        <AddPlayer player={row} type={getPlayerType(row, 2)} />
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { Formations };
