import React, { useState, useEffect } from "react";
import { ONE_SECOND, UPCOMING_TIMER_OFFSET } from "../../Common/constants";
import core from "../../Common/core";
import {
  getDateISO8601Format,
  getTranslations,
  isMobile,
} from "../../Common/utils/index";

const MatchTimer = ({
  fixture,
  extraCls,
  isEos = null,
  NextSeasonDeadline = null,
  showShortTimes,
}) => {
  const [now, setDate] = useState(new Date());

  // const gameDate = fixture?.GameDate + fixture?.GMTOffset;
  const formattedDate = fixture?.SessionStartDateISO8601;
  let upcomingRaceDate = isEos
    ? NextSeasonDeadline
      ? new Date(NextSeasonDeadline)
      : new Date()
    : formattedDate
    ? new Date(formattedDate)
    : new Date();
  // Add offset to upcoming race date
  upcomingRaceDate = new Date(
    upcomingRaceDate.getTime() + UPCOMING_TIMER_OFFSET * ONE_SECOND
  );

  function refreshClock() {
    setDate(new Date());
  }
  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);

  const formatTime = (time) => {
    return time <= 0 ? "00" : time < 10 ? "0" + time : time;
  };

  const getDays = (date) => {
    return formatTime(core.daysBetween(now, date).days);
  };
  const getHours = (date) => {
    return formatTime(core.daysBetween(now, date).hours);
  };
  const getMinutes = (date) => {
    return formatTime(core.daysBetween(now, date).minutes);
  };

  return (
    <>
      <section
        className={`si-matchTimer__wrap ${
          extraCls ? `si-matchTimer__wrap--${extraCls}` : ""
        }`}
        aria-label="team lock deadline"
      >
        <h2 className="si-matchTimer__title">
          <span>
            {isEos ? (
              getTranslations(
                "eot_countdown_rev_up_your_engines",
                "REV UP YOUR ENGINES!"
              )
            ) : (
              <>
                {getTranslations(
                  "create_team_team_lock_deadline",
                  "Team Lock Deadline"
                )}
                :
              </>
            )}
          </span>
        </h2>
        <div className="si-matchTimer__watch">
          <ul>
            <li aria-label={`${getDays(upcomingRaceDate)} days`}>
              <span>{getDays(upcomingRaceDate)}</span>
              <span>
                {getTranslations(
                  isMobile() || showShortTimes
                    ? "create_team_days_mobile"
                    : "create_team_days_desktop",
                  isMobile() || showShortTimes ? "D" : "Days"
                )}
              </span>
            </li>

            <li aria-label={`${getHours(upcomingRaceDate)} hours`}>
              <span>{getHours(upcomingRaceDate)}</span>
              <span>
                {getTranslations(
                  isMobile() || showShortTimes
                    ? "create_team_hours_mobile"
                    : "create_team_hours_desktop",
                  isMobile() || showShortTimes ? "H" : "Hrs"
                )}
              </span>
            </li>

            <li aria-label={`${getMinutes(upcomingRaceDate)} mins`}>
              <span>{getMinutes(upcomingRaceDate)}</span>
              <span>
                {getTranslations(
                  isMobile() || showShortTimes
                    ? "create_team_minutes_mobile"
                    : "create_team_minutes_desktop",
                  isMobile() || showShortTimes ? "M" : "Mins"
                )}
              </span>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export { MatchTimer };
