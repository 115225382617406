import React from "react";
import config from "../../Common/config";
import { IMAGE_VERSION } from "../../Common/constants";
import { CtaButton } from "../Buttons";
import Media from "../media";

const NoDataFound = ({
  noDataThumb,
  noDataTitle,
  noDataCaption,
  noDataCta,
  noDataConfig,
}) => {
  return (
    <>
      <div className="si-noData__wrap">
        {noDataThumb ? (
          <div className="si-noData__thumb">
            <Media imgSrc={noDataThumb} imgAlt={noDataTitle.toLowerCase()} />
          </div>
        ) : (
          ""
        )}
        {noDataTitle ? (
          <h2 className="si-noData__title">
            <span>{noDataTitle}</span>
          </h2>
        ) : (
          ""
        )}
        {noDataCaption ? (
          <div className="si-noData__caption">{noDataCaption}</div>
        ) : (
          ""
        )}
        {noDataCta ? (
          <div className="si-noData__cta">
            <CtaButton variant={"link"} {...noDataConfig}>
              {noDataCta}
            </CtaButton>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export { NoDataFound };
