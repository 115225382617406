import React from "react";
import { BoosterChips } from "../BoosterChips";

const LeaguePoint = ({
  LeaguePointLbl,
  LeaguePointNum,
  LeaguePointUnit,
  fixture,
  handleClick = () => {},
  extraClass = "",
  booster = false,
  isWon = false,
}) => {
  return (
    <>
      <div
        className={`si-league__point 
          ${isWon ? "si-won" : ""} ${extraClass} ${
          !extraClass.includes("si-disabled") && isNaN(LeaguePointNum)
            ? "si-disabled"
            : ""
        }`}
        onClick={() => {
          handleClick();
        }}
      >
        <div className="si-league__point-box">
          <div className="si-league__point-text">
            {LeaguePointLbl}
            {booster && booster?.boosterCls && <BoosterChips {...booster} />}
          </div>
          <div className={`si-league__point-nums ${
            LeaguePointNum < 0 ? "si-negative" : ""
          }`}>
            {isWon && (
              <div className="si-league__point-icon">
                <i className="f1i-trophy"></i>
              </div>
            )}
            <span
              className={`${!(
                isNaN(LeaguePointNum) || LeaguePointNum === null
              )}`}
            >
              {isNaN(LeaguePointNum) ||
              LeaguePointNum === null ||
              LeaguePointNum === "" ? (
                "-"
              ) : (
                <>
                  {LeaguePointNum}
                  <em>{LeaguePointUnit ? LeaguePointUnit : ""}</em>
                </>
              )}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export { LeaguePoint };
