import React, { useState, useRef, useEffect } from "react";

// import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../Common/config";
import { AffordableToggleSwitch } from "../../AffordableToggleSwitch";
import { gameplay } from "../../../redux/actions";
import { getTranslations, getYearForImagePath } from "../../../Common/utils";
import {
  CLOUDINARY_IMAGE_BASE_URL,
  IMAGE_VERSION,
} from "../../../Common/constants";
import StatisticsImage from "../../Cloudinary/StatisticsImage";

const Filters = () => {
  const dispatch = useDispatch();
  let minGap = 0;
  const sliderTrack = useRef(null);
  const sliderOne = useRef(null);
  const sliderTwo = useRef(null);

  const handleSliderOneChange = (e) => {
    e.stopPropagation();
    if (parseInt(e.target.value) - parseInt(sliderTwoValue) <= minGap) {
      setSliderOneValue(e.target.value);
    }
  };
  const handleSliderTwoChange = (e) => {
    e.stopPropagation();
    if (parseInt(sliderOneValue) - parseInt(e.target.value) <= minGap) {
      setSliderTwoValue(e.target.value);
    }
  };

  const slideOne = () => {
    if (parseInt(sliderOneValue) - parseInt(sliderTwoValue) <= minGap) {
      setSliderOneValue(parseInt(sliderTwoValue) - minGap);
    }
  };
  const slideTwo = () => {
    if (parseInt(sliderTwoValue) - parseInt(sliderOneValue) <= minGap) {
      setSliderTwoValue(parseInt(sliderOneValue) + minGap);
    }
  };
  const fillColor = () => {
    const percent1 = (sliderOneValue / maxValue) * 100;
    const percent2 = (sliderTwoValue / maxValue) * 100;

    sliderTrack.current.style.background = `linear-gradient(to right, rgba(224, 222, 220, 1)${percent1}% , rgba(225, 6, 0, 1) ${percent1}% , rgba(225, 6, 0, 1) ${percent2}%, rgba(224, 222, 220, 1)${percent2}%)`;
  };

  const driverState = useSelector((state) => state.driver);
  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;

  const teamArray = driverState?.list
    ?.filter?.((x) => x.TeamName)
    ?.map((x) =>
      JSON.stringify({
        name: x.TeamName,
        id: x.TeamId,
      })
    );
  const teams = teamArray && [...new Set(teamArray)].map(JSON.parse);
  const gameplayState = useSelector((state) => state.gameplay);

  const playerValues = driverState?.list.map((x) => Math.ceil(x.Value));
  const minValue = Math.min(...playerValues, 0);
  const [maxValue, setSliderMaxValue] = useState(Math.max(...playerValues));

  const [sliderOneValue, setSliderOneValue] = useState(minValue);
  const [sliderTwoValue, setSliderTwoValue] = useState(maxValue);
  const [onlyAffordableSelected, setOnlyAffordableSelected] = useState(false);

  useEffect(() => {
    fillColor();

    dispatch(
      gameplay.setFilters({
        min: sliderOneValue,
        max: sliderTwoValue,
        teams: selectedTeams,
        affordable: onlyAffordableSelected,
      })
    );

    return null;
  }, [sliderOneValue, sliderTwoValue]);

  const [selectedTeams, setSelectedTeams] = useState([]);

  const handleSelectTeam = (e) => {
    const data = selectedTeams;
    const index = data.findIndex((x) => x === e.target.value);
    if (index > -1) {
      data.splice(index, 1);
    } else {
      data.push(e.target.value);
    }
    setSelectedTeams([...data]);
  };

  const handleOnlyAffordable = () => {
    setOnlyAffordableSelected(!onlyAffordableSelected);
  };

  useEffect(() => {
    // if (onlyAffordableSelected) {
    //   // setSliderMaxValue(
    //   //   Math.min(Math.max(...playerValues), gameplayState?.budget)
    //   // );
    //   setSliderOneValue(minValue);
    //   setSliderTwoValue(
    //     Math.min(Math.max(...playerValues), Math.ceil(gameplayState?.budget))
    //   );
    // }

    dispatch(
      gameplay.setFilters({
        min: sliderOneValue,
        max: sliderTwoValue,
        teams: selectedTeams,
        affordable: onlyAffordableSelected,
      })
    );
    return null;
  }, [onlyAffordableSelected]);

  useEffect(() => {
    dispatch(
      gameplay.setFilters({
        min: sliderOneValue,
        max: sliderTwoValue,
        teams: selectedTeams,
        affordable: onlyAffordableSelected,
      })
    );

    return null;
  }, [selectedTeams]);

  useEffect(() => {
    if (
      gameplayState?.selectedFilters &&
      !Object.keys(gameplayState?.selectedFilters).length
    ) {
      setSelectedTeams([]);

      setSliderOneValue(minValue);
      setSliderTwoValue(maxValue);

      setOnlyAffordableSelected(false);
    }
    return null;
  }, [gameplayState?.selectedFilters]);

  useEffect(() => {
    if (gameplayState?.appliedFilters?.length) {
      const teamFilter = gameplayState?.appliedFilters?.findIndex(
        (x) => x.key === "team"
      );
      const isTeamFilterSelected = teamFilter !== undefined && teamFilter > -1;
      const priceFilter = gameplayState?.appliedFilters?.findIndex(
        (x) => x.key === "price"
      );
      const isPriceFilterSelected =
        priceFilter !== undefined && priceFilter > -1;
      const affordableFilter = gameplayState?.appliedFilters?.findIndex(
        (x) => x.key === "affordable"
      );
      const isAffordableFilterSelected =
        affordableFilter !== undefined && affordableFilter > -1;

      if (isTeamFilterSelected) {
        setSelectedTeams(
          JSON.parse(
            JSON.stringify(gameplayState?.appliedFilters[teamFilter]?.teams)
          )
        );
      }
      if (isPriceFilterSelected) {
        setSliderOneValue(gameplayState.appliedFilters[priceFilter].min);
        setSliderTwoValue(gameplayState.appliedFilters[priceFilter].max);
      } else {
        setSliderOneValue(minValue);
        setSliderTwoValue(maxValue);
      }
      if (isAffordableFilterSelected) {
        setOnlyAffordableSelected(
          gameplayState.appliedFilters[affordableFilter].onlyAffordable
        );
      } else {
        setOnlyAffordableSelected(false);
      }
    }
    return null;
  }, []);

  return (
    <>
      <div className="si-filter__wrap">
        <div className="si-filter__container">
          <div className="si-affordable__toggle">
            <form
              className="si-radioBtn"
              action=""
              method="get"
              role="switch"
              aria-checked={onlyAffordableSelected}
              aria-label=""
            >
              <div className="si-radioBtn__wrap">
                <input
                  type="checkbox"
                  name="radio-toggle"
                  id="onlyAffordable"
                  checked={onlyAffordableSelected}
                  onChange={() => handleOnlyAffordable()}
                />
                <div className="si-radioBtn__lbl">
                  <span>
                    {getTranslations(
                      "player_filter_only_affordable",
                      "Only Affordable"
                    )}
                  </span>
                </div>
                <div className="si-radioBtn__action">
                  <span className="si-radioBtn__toggle"></span>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="si-filter__container">
          {" "}
          <h2 className="si-filter__title">
            <span>{getTranslations("player_filter_only_price", "Price")} </span>
          </h2>
          <div className="si-filter__ranger">
            <div className="si-filter__ranger-head ">
              <span>${sliderOneValue}M</span>
              <span>${sliderTwoValue}M</span>
            </div>
            <div
              className={`si-filter__ranger-body ${
                onlyAffordableSelected ? "si-disabled" : ""
              }`}
            >
              <div className="container">
                <input
                  type="range"
                  min={minValue}
                  max={maxValue}
                  value={sliderOneValue}
                  ref={sliderOne}
                  disabled={onlyAffordableSelected}
                  onChange={handleSliderOneChange}
                  onInput={() => slideOne()}
                />
                <input
                  type="range"
                  min={minValue}
                  max={maxValue}
                  value={sliderTwoValue}
                  ref={sliderTwo}
                  disabled={onlyAffordableSelected}
                  onChange={handleSliderTwoChange}
                  onInput={() => slideTwo()}
                />
                <div className="slider-track" ref={sliderTrack}></div>
              </div>
            </div>
          </div>
        </div>
        <div className="si-filter__container">
          <h2 className="si-filter__title">
            <span>{getTranslations("player_filter_only_teams", "Teams")}</span>
          </h2>
          <div className="si-filter__checkbox">
            {Array.from(teams || [])?.map((team, index) => {
              console.log({ team });
              const currentTeam = driverState?.list?.find(
                (item) => item?.PlayerId === team?.id
              );
              return (
                <div className="si-form__control" key={team?.id}>
                  <div className={`si-input si-team__${team?.id}`}>
                    <div className="si-checkBox">
                      <input
                        type="checkbox"
                        id={team?.id}
                        value={team?.id}
                        checked={selectedTeams.includes(team?.id)}
                        onChange={(e) => handleSelectTeam(e)}
                      />
                      <label htmlFor={team?.id}>
                        <StatisticsImage statisticsPlayer={currentTeam} />
                        {team?.name}
                      </label>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export { Filters };
