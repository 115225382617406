import { fixtureMatchStatus } from "../constants";
import { currentFixture, getMatchStatus } from "./fixture";
import { getTranslations } from "./index";

export const formatBoosterData = (booster) => {
  if (!booster) return;
  const boostersConfig = {
    1: {
      name: getTranslations("booster_1", "Limitless"),
      selectedName: getTranslations("selected_booster_1", "Limitless Chip"),
      activatedName: getTranslations(
        "booster_1_activated",
        "Limitless Activated"
      ),
      caption: getTranslations(
        "selected_booster_1_caption",
        "Unlimited transfers allowed with no cost cap restriction. This is reset after the race week"
      ),
      lockedText: getTranslations(
        "booster_1_locked_text",
        "*Chip can be used from the 2nd race."
      ),
    },
    2: {
      name: getTranslations("booster_2", "Wildcard"),
      selectedName: getTranslations("selected_booster_2", "Wildcard Chip"),
      activatedName: getTranslations(
        "booster_2_activated",
        "Wildcard Activated"
      ),
      caption: getTranslations(
        "selected_booster_2_caption",
        "Make unlimited changes to your team before the next Grand Prix."
      ),
      lockedText: getTranslations(
        "booster_2_locked_text",
        "*Chip can be used from the 2nd race."
      ),
    },
    3: {
      name: getTranslations("booster_3", "Final Fix"),
      selectedName: getTranslations("selected_booster_3", "Final Fix Chip"),
      activatedName: getTranslations(
        "booster_3_activated",
        "Final Fix Activated"
      ),
      caption: getTranslations(
        "selected_booster_3_caption",
        "Make one change to your team before the race starts. You will still keep the qualifying score of your original pick"
      ),
      lockedText: getTranslations(
        "booster_3_locked_text",
        "*Chip can be used after the qualifying race."
      ),
    },
    4: {
      name: getTranslations("booster_4", "Auto Pilot"),
      selectedName: getTranslations("selected_booster_4", "Auto Pilot Chip"),
      activatedName: getTranslations(
        "booster_4_activated",
        "Auto Pilot Activated"
      ),
      caption: getTranslations(
        "selected_booster_4_caption",
        "Top-scoring driver will automatically get DRS Boost (2x score) overriding manual selection"
      ),
    },
    5: {
      name: getTranslations("booster_5", "No Negative"),
      selectedName: getTranslations("selected_booster_5", "No Negative Chip"),
      activatedName: getTranslations(
        "booster_5_activated",
        "No Negative Activated"
      ),
      caption: getTranslations(
        "selected_booster_5_caption",
        "No negative points are applied for the entire race week -- including Qualifying, Sprint Race and Final Race"
      ),
    },
    6: {
      name: getTranslations("booster_6", "Extra DRS"),
      selectedName: getTranslations("selected_booster_6", "Extra DRS Chip"),
      activatedName: getTranslations(
        "booster_6_activated",
        "Extra DRS Activated"
      ),
      caption: getTranslations(
        "selected_booster_6_caption",
        "Apply 3x points booster to a driver of your choice"
      ),
      warning: getTranslations(
        "selected_booster_6_warning",
        "*You can change the Extra DRS driver before deadline."
      ),
    },
  };

  return {
    id: booster.BoosterId,
    boosterCls: `si-${booster?.BoosterName?.trim()
      .toLowerCase()
      .replace(/ /, "-")}`,
    boosterIcon: `f1i-${booster?.BoosterName?.trim()
      .toLowerCase()
      .replace(/ /, "-")}`,
    boosterText: boostersConfig[booster.BoosterId]?.name,
    boosterSelectedText: boostersConfig[booster.BoosterId]?.selectedName,
    boosterActivatedText: boostersConfig[booster.BoosterId]?.activatedName,
    boosterCaption: boostersConfig[booster.BoosterId]?.caption,
    lockedText: boostersConfig[booster.BoosterId]?.lockedText,
    enText: booster?.BoosterName?.trim(),
  };
};

export const canUseBooster = (
  booster,
  gameplayState,
  selectedTeam,
  fixtureState,
  constraintsState,
  isSwappedPlayersTeam
) => {
  const { id } = booster;
  const { teams, userGameDays, userGameDaysV1, mdid } = gameplayState;
  const team = teams[selectedTeam];
  switch (id) {
    case 1: // Limitless
      return (
        !team.islimitlesstaken &&
        !team.islateonboard &&
        team.isaccounting &&
        userGameDaysV1[team?.teamno]?.mddetails[mdid]?.mds === 0
      );
    // return true;
    case 2: // Wildcard
      return (
        !team.iswildcardtaken &&
        !team.islateonboard &&
        team.isaccounting &&
        userGameDaysV1[team?.teamno]?.mddetails[mdid]?.mds === 0
      );
    // return true;
    case 3: // Final fix
      return (
        userGameDaysV1 &&
        team &&
        team?.teamno &&
        mdid &&
        userGameDaysV1[team?.teamno] &&
        userGameDaysV1[team?.teamno]?.ftMdid != mdid &&
        !team.isfinalfixtaken &&
        !team.islateonboard &&
        userGameDaysV1[team?.teamno]?.mddetails[mdid]?.mds === 2 &&
        !isSwappedPlayersTeam
        // (currentFixture(fixtureState)?.SessionType === "Qualifying" ||
        //   (currentFixture(fixtureState)?.SessionType === "Sprint Qualifying" &&
        //     getMatchStatus(currentFixture(fixtureState)?.MatchStatus) ===
        //       "PC DONE") ||
        //   (currentFixture(fixtureState)?.SessionType === "Race" &&
        //     getMatchStatus(currentFixture(fixtureState)?.MatchStatus) ===
        //       "CURRENT")) &&
        // constraintsState?.data?.MatchdayId > userGameDaysV1[team?.teamno].ftGdid
      );
    case 4: // Auto Pilot
      return (
        userGameDaysV1 &&
        team &&
        !team.isautopilottaken &&
        !team.islateonboard &&
        userGameDaysV1[team?.teamno] &&
        userGameDaysV1[team?.teamno]?.mddetails[mdid]?.mds === 0
      );
    case 5: // No Negative
      return (
        userGameDaysV1 &&
        team &&
        !team.isnonigativetaken &&
        !team.islateonboard &&
        userGameDaysV1[team?.teamno] &&
        userGameDaysV1[team?.teamno]?.mddetails[mdid]?.mds === 0
      );
    case 6: // Extra DRS
      return (
        userGameDaysV1 &&
        team &&
        !team.isextradrstaken &&
        !team.islateonboard &&
        userGameDaysV1[team?.teamno] &&
        userGameDaysV1[team?.teamno]?.mddetails[mdid]?.mds === 0
      );

    default:
      return false;
  }
};

export const isBoosterUsed = (id, gameplayState, selectedTeam) => {
  // const { id } = booster;
  const { teams } = gameplayState;
  const team = teams[selectedTeam];
  switch (id) {
    case 1: // Limitless
      return !!team.islimitlesstaken;
    // return true;
    case 2: // Wildcard
      return !!team.iswildcardtaken;
    // return true;
    case 3: // Final fix
      return !!team.isfinalfixtaken;
    case 4: // Auto Pilot
      return !!team.isautopilottaken;
    case 5: // No Negative
      return !!team.isnonigativetaken;
    case 6: // Extra DRS
      return !!team.isextradrstaken;

    default:
      return false;
  }
};

export const getBooster = (boosters, value) => {
  const booster = boosters?.find((x) => x.BoosterId === value);
  return formatBoosterData(booster);
};
const hasTakenLimitLess = (gameDayId, gameDay) => {
  return gameDay?.limitLesstakengd == gameDayId;
};
const hasTakenWildcard = (gameDayId, gameDay) => {
  return gameDay?.wildCardtakengd == gameDayId;
};
const hasTakenFinalFix = (gameDayId, gameDay) => {
  return gameDay?.finalFixtakengd == gameDayId;
};
const hasTakenAutoPilot = (gameDayId, gameDay) => {
  return gameDay?.isAutopilottakengd == gameDayId;
};
const hasTakenNoNegative = (gameDayId, gameDay) => {
  return gameDay?.noNigativetakengd == gameDayId;
};
const hasTakenExtraDRS = (gameDayId, gameDay) => {
  return gameDay?.extraDrstakengd == gameDayId;
};

export const hasTakenBooster = (boosters, gameDayId, gameDay) => {
  // return getBooster(1);
  if (hasTakenLimitLess(gameDayId, gameDay)) {
    return getBooster(boosters, gameDay?.isLimitlesstaken);
  }
  if (hasTakenWildcard(gameDayId, gameDay)) {
    return getBooster(boosters, gameDay?.isWildcardtaken);
  }
  if (hasTakenFinalFix(gameDayId, gameDay)) {
    return getBooster(boosters, gameDay?.isFinalfixtaken);
  }
  if (hasTakenAutoPilot(gameDayId, gameDay)) {
    return getBooster(boosters, gameDay?.isAutopilottaken);
  }
  if (hasTakenNoNegative(gameDayId, gameDay)) {
    return getBooster(boosters, gameDay?.isNonigativetaken);
  }
  if (hasTakenExtraDRS(gameDayId, gameDay)) {
    return getBooster(boosters, gameDay?.isExtradrstaken);
  }
  return false;
};
