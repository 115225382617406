import React from "react";
import { useNavigate } from "react-router-dom";
import { getTranslations } from "../../Common/utils";
import { CtaButton } from "../index";

const BackButton = (props) => {
  const {
    callBackFunction = () => {},
    hasCustomBackAction,
    onBack = () => {},
  } = props;
  const navigate = useNavigate();
  const handleBack = () => {
    onBack();
    navigate(-1);
  };
  return (
    <div className="si-myTeamHeader__back">
      <CtaButton
        variant={"ghost"}
        size={"md"}
        onlyIcon={true}
        icon="f1i-ar-left"
        restProps={{
          ariaLabel: getTranslations(
            "manage_team_back_desc",
            "Click to go back"
          ),
        }}
        onClick={() =>
          !hasCustomBackAction ? handleBack() : callBackFunction()
        }
      />
    </div>
  );
};

export { BackButton };
