import React from "react";
import config from "../../../Common/config";
import { IMAGE_VERSION } from "../../../Common/constants";
import useCloudinaryData from "../../../Common/hooks/useCloudinaryData";
import Media from "../../media";

const PageLoader = ({ isNoBG = false }) => {
  const { cloudinaryBase, envVersion, currentSeasonYear } = useCloudinaryData();
  return (
    <>
      <div
        className={`si-pageloader__wrap ${
          isNoBG ? "si-pageloader__wrap--noBg" : ""
        }`}
      >
        <div className="si-pageloader__icon">
          <Media
            imgSrc={`${cloudinaryBase}/${envVersion}/Fantasy/${currentSeasonYear}/images/loader.svg`}
            imgAlt="loader"
          />
        </div>
      </div>
    </>
  );
};

export default PageLoader;
