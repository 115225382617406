import { useMemo } from "react";
import {
  getConstructorListViewImage,
  getDriverListViewImage,
} from "../../../Common/utils/cloudinary";
import useCloudinaryData from "../../../Common/hooks/useCloudinaryData";
import {
  CLOUDINARY_IMAGE_BASE_URL,
  IMAGE_VERSION,
} from "../../../Common/constants";
import Media from "../../media";

const ListViewImage = ({ player }) => {
  const { configData, currentSeasonYear } = useCloudinaryData();

  const playerImage = useMemo(() => {
    if (player?.PositionName === "DRIVER") {
      return getDriverListViewImage(player, currentSeasonYear, configData);
    } else if (player?.PositionName === "CONSTRUCTOR") {
      return getConstructorListViewImage(player, currentSeasonYear, configData);
    } else {
      return null;
    }
  }, [configData, currentSeasonYear, player]);

  return (
    <Media imgSrc={playerImage} imgAlt={player?.FUllName} />
    // <img
    //   src={playerImage}
    //   alt={player?.FUllName}
    //   onError={({ currentTarget }) => {
    //     currentTarget.onerror = null; // prevents looping
    //     currentTarget.src = `${CLOUDINARY_IMAGE_BASE_URL}common/fallback.svg?v=${IMAGE_VERSION}`;
    //   }}
    //   loading="lazy"
    // />
  );
};

export default ListViewImage;
