import React from "react";

const FixRound = ({ fixRoundConfig }) => {
  const { fixRoundLbl = false, roundNo, flagOff = false } = fixRoundConfig;
  return (
    <>
      <div className="si-fix__round">
        {fixRoundLbl ? (
          <div className="si-fix__round-lbl">
            <span>Starting Round:</span>
          </div>
        ) : (
          ""
        )}
        <div className="si-fix__round-text">
          {flagOff && <i className="f1i-chequered-flag"></i>}
          <span>Round {roundNo}</span>
        </div>
      </div>
    </>
  );
};

export { FixRound };
