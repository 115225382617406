import React from "react";
import { CtaButton } from "../../Buttons";
import { useDispatch } from "react-redux";
import { gameplay } from "../../../redux/actions/";
import { togglePlayerList } from "../../../Common/utils/team";
import { getTranslations, isMobile } from "../../../Common/utils/index";
import useIPadOrientation from "../../../Common/hooks/useIPadOrientation";
import { PORTRAIT } from "../../../Common/constants";

function AddPlayer({ type, player }) {
  const dispatch = useDispatch();
  const { isIPad, screenDimensionOrientation } = useIPadOrientation();

  const isIPadPortrait = isIPad && screenDimensionOrientation === PORTRAIT;

  const setSelectedTab = (val) => {
    dispatch(gameplay.setSelectedTab(val));
  };
  const setSelectedIndex = (val) => {
    dispatch(gameplay.setSelectedIndex(val));
  };

  const handleClick = (e) => {
    e.preventDefault();
    setSelectedTab(type === 1 ? "drivers" : "constructors");
    setSelectedIndex(player.formationIndex);
    togglePlayerList(dispatch, gameplay);
    if (isIPadPortrait || isMobile()) {
      document.body.classList.add("noBodyScroll");
    }
    if (isIPadPortrait) {
      dispatch(gameplay.togglePlayerList());
    }
  };

  const displayText =
    type === 1
      ? getTranslations("create_team_add_driver", "Add Driver")
      : getTranslations("create_team_add_constructor", "Add Constructor");
  return (
    <>
      <CtaButton
        onlyIcon={true}
        variant={"primaryLight"}
        icon="f1i-add"
        ariaLabel={displayText}
        onClick={(e) => handleClick(e)}
      ></CtaButton>
      <div className="si-player__card-placeholder">
        <span>{displayText}</span>
      </div>
    </>
  );
}

export { AddPlayer };
