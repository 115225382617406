import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import config from "../../Common/config";
import { CtaButton } from "../Buttons";
import { facebook, twitter } from "../../Common/utils/share";
import {
  copyToClipboard,
  getTranslations,
  isAndroid,
  isIOS,
} from "../../Common/utils";
import { league } from "../../redux/actions";
import {
  FANTASY_ANDROID,
  FANTASY_IOS,
  FANTASY_WEBSITE,
  IMAGE_VERSION,
  WEB,
  WEBVIEW,
} from "../../Common/constants";
import useOnClickOutside from "../../Common/hooks/useOnClickOutside";
import useDataLayer from "../../Common/hooks/useDataLayer";
import Media from "../media";
import useCloudinaryData from "../../Common/hooks/useCloudinaryData";

const ToastNotification = ({
  type,
  toastType,
  handleClose = () => null,
  alertText = "",
  alertCaption = "",
  isAlertCaptionHtml = false,
  alertCaption2 = "",
  alertCaption3 = "",
}) => {
  const { cloudinaryBase, currentSeasonYear, envVersion } = useCloudinaryData();
  const leagueState = useSelector((state) => state.league);
  const userState = useSelector((state) => state.user);
  const { createPrivateLeagueSuccessData, selectedLeague } = leagueState;
  const socialSharingRef = useRef();
  const alertRef = useRef();
  const dispatch = useDispatch();
  const pushToDataLayer = useDataLayer();
  const webview = userState?.webview;
  let foo = `#`;
  let toastOverlay = [
    "si-toastMsg__wrap--generic",
    "si-toastMsg__wrap--socialSharing",
    "si-toastMsg__wrap--alert",
  ];
  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;
  const toggleSharePopUpOverlay = () => {
    dispatch(league.toggleSharePopUpOverlay());
  };

  useOnClickOutside(socialSharingRef, toggleSharePopUpOverlay);
  useOnClickOutside(alertRef, handleClose);

  const copyClick = () => {
    dispatch(league.toggleCopyPopUp());
    setTimeout(() => {
      dispatch(league.toggleCopyPopUp());
    }, 1000);
  };

  const pushShareIntentDataLayer = (socialMedia) => {
    if (!socialMedia) return;

    const dataLayerObject = {
      pageName: "Leagues",
      navigationElement: "shareLeagueOverlay",
      actionType: `shareIntent | ${socialMedia}`,
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: webview ? WEBVIEW : WEB,
    };
    pushToDataLayer("socialInteraction", dataLayerObject);
  };

  switch (type) {
    case "generic":
      return (
        <div
          className={`si-toastMsg__wrap ${
            toastType === "success"
              ? "si-success"
              : toastType === "danger"
              ? "si-danger"
              : ""
          } ${toastOverlay[0]}`}
        >
          {toastType === "success" ? (
            <div className="si-toastMsg__icon">
              <i className="f1i-tick"></i>
            </div>
          ) : toastType === "danger" ? (
            <div className="si-toastMsg__icon">
              <i className="f1i-add"></i>
            </div>
          ) : (
            ""
          )}
          <h2 className="si-toastMsg__text">
            <span>
              {alertText}
              {/* {getTranslations(
                "copied_to_clipboard_toast_message",
                "Copied to clipboard"
              )} */}
            </span>
          </h2>
        </div>
      );
    case "socialsharing":
      return (
        <div
          className={`si-toastMsg__wrap ${toastOverlay[1]}`}
          ref={socialSharingRef}
        >
          <div className="si-toastMsg__close">
            <CtaButton
              variant={"primaryLight"}
              onlyIcon={true}
              icon="f1i-close"
              restProps={{
                "aria-label": getTranslations(
                  "create_league_completed_share_popup_close_aria_text",
                  "close popup"
                ),
              }}
              onClick={toggleSharePopUpOverlay}
            />
          </div>
          <h2 className="si-socialSharing-text">
            <span>
              {getTranslations(
                "create_league_completed_share_popup_title",
                "Share on"
              )}{" "}
              :
            </span>
          </h2>
          <div className="si-socialSharing">
            <ul>
              <li>
                <a
                  onClick={() => {
                    copyToClipboard("copyLeagueUrl");
                    copyClick();
                    pushShareIntentDataLayer("Copied to clipboard");
                  }}
                >
                  <Media
                    imgSrc={`${cloudinaryBase}/${envVersion}/Fantasy/${currentSeasonYear}/leagues/social-share/link.svg`}
                    imgAlt="link"
                  />
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    facebook({
                      url: `${window.location.href}/join/${
                        createPrivateLeagueSuccessData?.legcode ||
                        selectedLeague?.leagueCode
                      }`,
                    });
                    pushShareIntentDataLayer("Facebook");
                  }}
                >
                  <Media
                    imgSrc={`${cloudinaryBase}/${envVersion}/Fantasy/${currentSeasonYear}/leagues/social-share/facebook.svg?v=${IMAGE_VERSION}`}
                    imgAlt="facebook"
                  />
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    twitter({
                      postTitle: "",
                      url: `${window.location.href}/join/${
                        createPrivateLeagueSuccessData?.legcode ||
                        selectedLeague?.leagueCode
                      }`,
                    });
                    pushShareIntentDataLayer("Twitter");
                  }}
                >
                  <Media
                    imgSrc={`${cloudinaryBase}/${envVersion}/Fantasy/${currentSeasonYear}/leagues/social-share/twitter.svg?v=${IMAGE_VERSION}`}
                    imgAlt="twitter"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      );
    case "alert":
      return (
        <div className={`si-toastMsg__wrap ${toastOverlay[2]}`} ref={alertRef}>
          <div className="si-toastMsg__close">
            <CtaButton
              variant={"ghost"}
              onlyIcon={true}
              icon="f1i-close"
              onClick={(e) => {
                handleClose();
              }}
            />
          </div>
          <h2 className="si-alert__text">
            <span>{alertText ? alertText : ""}</span>
          </h2>
          {isAlertCaptionHtml ? (
            <h3 className="si-alert__caption">
              {alertCaption ? alertCaption : "Something went wrong."}
            </h3>
          ) : (
            <h3
              className="si-alert__caption"
              dangerouslySetInnerHTML={{
                __html: alertCaption ? alertCaption : "Something went wrong.",
              }}
            ></h3>
          )}
        </div>
      );
    case "profanity":
      return (
        <div className={`si-toastMsg__wrap ${toastOverlay[2]}`}>
          <div className="si-toastMsg__close">
            <CtaButton
              onlyIcon={true}
              variant={"ghost"}
              icon="f1i-close"
              onClick={(e) => handleClose()}
            />
          </div>
          <h2 className="si-alert__text">
            <span>{alertText ? alertText : ""}</span>
          </h2>
          <h3 className="si-alert__caption">
            <span>{alertCaption ? alertCaption : "Something went wrong."}</span>
          </h3>
          <h3 className="si-alert__caption">
            <span>
              {alertCaption2 ? alertCaption2 : "Something went wrong."}
            </span>
          </h3>
          <h3 className="si-alert__caption">
            <span>{alertCaption3 ? alertCaption3 : ""}</span>
          </h3>
        </div>
      );
    default:
      <></>;
      break;
  }
};

export { ToastNotification };
