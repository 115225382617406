import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import config from "../../Common/config";
import {
  CLOUDINARY_IMAGE_BASE_URL,
  IMAGE_VERSION,
} from "../../Common/constants";
import {
  getYearForImagePath,
  isMobile,
  normalizeString,
} from "../../Common/utils";
import { currentFixture } from "../../Common/utils/fixture";
import RaceThumbnail from "../Cloudinary/RaceThumbnail";

const MatchHeaderThumbnail = () => {
  // const [index, setIndex] = useState(0);
  const fixtureState = useSelector((state) => state.fixture);
  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;
  const circuitState = useSelector((state) => state.circuit);
  const circuits = circuitState?.data;
  const currentCircuit = circuits?.find(
    (x) => x?.MeetingId === currentFixture(fixtureState)?.MeetingId
  );

  // const currentFixture = fixtureState?.list?.find((x) => x.MHIsCurrent == 1);

  // const currentFixture = fixtureState?.list && fixtureState?.list[index];
  // const [maxFixtures, setMaxFixtures] = useState(null);

  // useEffect(() => {
  //   setMaxFixtures(fixtureState?.list?.length);
  // }, [fixtureState]);

  // function refreshFixture() {
  //   console.log(index, maxFixtures);
  //   if (index <= maxFixtures) {
  //     setIndex((prev) => prev + 1);
  //   }
  // }
  // useEffect(() => {
  //   const timerId = setInterval(refreshFixture, 1000);
  //   return function cleanup() {
  //     clearInterval(timerId);
  //   };
  // }, []);
  return (
    <>
      {!isMobile() && (
        <div className="si-matchHeader__thumbnail">
          <RaceThumbnail
            countryName={normalizeString(currentCircuit?.CircuitShortName)}
          />
        </div>
      )}
    </>
  );
};

export { MatchHeaderThumbnail };
