import { LEAGUE_BUSTER, TEAM_BUSTER } from "../../../../Common/busters";
import axios from "../../axios";
import { getURLString } from "../../index";
const URLString = (url) => getURLString(BASE_ROUTE, url);
const URLOppString = (url) => getURLString(BASE_ROUTE_OPP_TEAM, url);

const BASE_ROUTE = `services/user/gameplay`;
const BASE_ROUTE_OPP_TEAM = `services/user/opponentteam`;
const SUBMIT_USER_TEAM = `createteam`;
const GET_USER_TEAM = (guid, optType, teamNo, gameDayId, phaseId) =>
  `${guid}/getteam/${optType}/${teamNo}/${gameDayId}/${phaseId}`;

const GET_OPP_USER_TEAM = (
  optType,
  opponentGuid,
  teamNo,
  tourGamedayId,
  phaseId
) =>
  `opponentgamedayplayerteamget/${optType}/${opponentGuid}/${teamNo}/${tourGamedayId}/${phaseId}`;
const USER_TEAM_TRANSFER = `transferteam`;
const USER_TEAM_CAPTAIN_UPDATE = `captainupdate`;
const TEAM_NAME_UPDATE = `teamnameupdate`;
const GET_USER_GAME_DAYS = (
  guid,
  optType,
  teamNo
) => `${guid}/getusergamedays/${optType}/${teamNo}

`;
const GET_USER_GAME_DAYS_V1 = (guid, optType) =>
  `${guid}/getusergamedaysv1/${optType}`;

const GET_OPP_GAME_DAYS = (guid, optType, teamNo) =>
  `opponentgamedayget/${optType}/${guid}/${teamNo}`;

export function createTeam(params) {
  return axios.post(URLString(SUBMIT_USER_TEAM), params, {
    bustCookie: true,
    busterCookieName: TEAM_BUSTER,
  });
}

export function getTeam(params, livePointsBuster = false) {
  const { guid, optType, teamNo, gameDayId, phaseId } = params;
  return axios.get(
    URLString(GET_USER_TEAM(guid, optType, teamNo, gameDayId, phaseId)),
    {
      addBusterIfAvailable: true,
      busterCookieName: TEAM_BUSTER,
      livePointsBuster,
    }
  );
}

export function getOppTeam(params) {
  const { optType, opponentGuid, teamNo, tourGamedayId, phaseId } = params;
  return axios.get(
    URLOppString(
      GET_OPP_USER_TEAM(optType, opponentGuid, teamNo, tourGamedayId, phaseId)
    ),
    {
      addBusterIfAvailable: true,
      busterCookieName: TEAM_BUSTER,
    }
  );
}

export function transferTeam(params) {
  return axios.post(URLString(USER_TEAM_TRANSFER), params, {
    bustCookie: true,
    busterCookieName: TEAM_BUSTER,
  });
}

export function updateCaptain(params) {
  return axios.post(URLString(USER_TEAM_CAPTAIN_UPDATE), params, {
    bustCookie: true,
    busterCookieName: TEAM_BUSTER,
  });
}

export function updateTeamName(params) {
  return axios.post(URLString(TEAM_NAME_UPDATE), params, {
    bustCookie: true,
    busterCookieName: TEAM_BUSTER,
  });
}

export function getUserGameDays(params) {
  const { guid, optType, teamNo } = params;
  return axios.get(URLString(GET_USER_GAME_DAYS(guid, optType, teamNo)), {
    addBusterIfAvailable: true,
    busterCookieName: TEAM_BUSTER,
  });
}

export function getUserGameDaysV1(params) {
  const { guid, optType } = params;
  return axios.get(URLString(GET_USER_GAME_DAYS_V1(guid, optType)), {
    addBusterIfAvailable: true,
    busterCookieName: TEAM_BUSTER,
  });
}

export function getOppUserGameDays(params) {
  const { guid, optType, teamNo } = params;
  return axios.get(URLOppString(GET_OPP_GAME_DAYS(guid, optType, teamNo)), {
    addBusterIfAvailable: true,
    busterCookieName: TEAM_BUSTER,
  });
}

export function swapPlayers(params) {
  return axios.post(URLString("swapteam"), params, {
    bustCookie: true,
    busterCookieName: TEAM_BUSTER,
  });
}
