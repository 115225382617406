import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import config from "../../Common/config";
import {
  CLOUDINARY_IMAGE_BASE_URL,
  IMAGE_VERSION,
} from "../../Common/constants";
import { getTranslations, getYearForImagePath } from "../../Common/utils";
import { currentFixture } from "../../Common/utils/fixture";
import { MatchHeader } from "../MatchHeader";
import { PlayerCard } from "../PlayerCard";
import { CtaButton } from "../Buttons";
import Media from "../media";
import useCloudinaryData from "../../Common/hooks/useCloudinaryData";
function Overlays({
  overlayName,
  overlayThumbnailName,
  isThumbnailNameFullSrc = false,
  overlayErrorThumbnail = "",
  overlayTitle,
  overlaySubTitle,
  overlaySubTitle2,
  overlaySubTitle3,
  btnPrimary,
  btnSecondary = getTranslations("turbo_driver_modal_cancel_button", "Cancel"),
  btnPrimaryIcon,
  index,
  overlayConfig,
  announcementText,
  onCancelClick = () => {
    return null;
  },
  onConfirmClick = () => {
    return null;
  },
  component,
  confirmButtonState,
  player,
  playerCardConfig,
  customFooter = null,
  customClsBtnPrimary,
  customClsBtnSecondary,
  onResetClick = () => {
    return null;
  },
  resetButtonState = false,
}) {
  const { cloudinaryBase, currentSeasonYear, envVersion } = useCloudinaryData();
  const {
    closeBtn = false,
    submitBtn = true,
    cancelBtn = false,
    showOverlayTitle = true,
    showOverlaySub_Title = false,
    showOverlaySub_Title2 = false,
    showOverlaySub_Title3 = false,
    overlayHeaderThumbnail = false,
    showMatchHeader = false,
    showPlayerCardHeader = false,
    noOverlayFoot = true,
    isReset = false,
    announcementTag = false,
  } = overlayConfig;

  const fixtureState = useSelector((state) => state.fixture);
  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;

  useEffect(() => {
    const BODY_CLASS = "noBodyScroll";
    document.body.classList.add(BODY_CLASS);
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.body.classList.remove(BODY_CLASS);
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const escFunction = useCallback((event) => {
    if (event.key === "Escape" && cancelBtn) {
      onCancelClick();
    }
  }, []);

  return (
    <>
      <div
        className={`si-popup__wrap si-popup__wrap--${overlayName} si-fade in`}
      >
        {/* in | out */}
        <div className="si-popup__container">
          <div className="si-popup__container-border">
            <Media
              imgSrc={`${cloudinaryBase}/${envVersion}/Fantasy/${currentSeasonYear}/images/announcements/announcement-stroke.svg?v=${IMAGE_VERSION}`}
              imgAlt="stroke"
            />
          </div>
          {closeBtn && (
            <div className="si-popup__close">
              <CtaButton
                size={"md"}
                onlyIcon={true}
                variant={"ghost"}
                icon={"f1i-close"}
                onClick={() => onCancelClick()}
              ></CtaButton>
            </div>
          )}
          <div className="si-popup__head">
            {overlayHeaderThumbnail && (
              <div className="si-popup__thumbnail">
                <img
                  src={
                    overlayThumbnailName
                      ? overlayThumbnailName
                      : `${config.IMG_BASE}${overlayThumbnailName}?v=${IMAGE_VERSION}`
                  }
                  alt={overlayTitle}
                  loading="lazy"
                  onError={(e) => {
                    e.currentTarget.onerror = null;
                    e.currentTarget.src = `${CLOUDINARY_IMAGE_BASE_URL}common/fallback.svg?v=${IMAGE_VERSION}`;
                  }}
                />
              </div>
            )}

            {announcementTag && (
              <div className="si-announcement__tag">
                <i className="f1i-megaphone"></i>
                <span>{announcementText}</span>
              </div>
            )}

            {showOverlayTitle && (
              <>
                {overlayTitle && (
                  <div className="si-popup__heading">
                    <span>{overlayTitle}</span>
                  </div>
                )}
                {isReset && (
                  <div className="si-popup__heading-cta">
                    <CtaButton
                      size={"sm"}
                      leadingIcon={true}
                      variant={"link"}
                      // icon={"f1i-reset"}
                      onClick={onResetClick}
                      disabled={resetButtonState}
                    >
                      {getTranslations(
                        "player_filter_header_reset_button_new",
                        "Clear All"
                      )}
                    </CtaButton>
                  </div>
                )}
              </>
            )}
            {(showOverlaySub_Title || overlaySubTitle) && (
              <div className="si-popup__heading-sub">
                <span>{overlaySubTitle}</span>
              </div>
            )}
            {(showOverlaySub_Title2 || overlaySubTitle2) && (
              <div className="si-popup__heading-sub">
                <span>{overlaySubTitle2}</span>
              </div>
            )}
            {(showOverlaySub_Title3 || overlaySubTitle3) && (
              <div className="si-popup__heading-sub">
                <span>{overlaySubTitle3}</span>
              </div>
            )}
            {showMatchHeader && (
              <MatchHeader
                tagName="h2"
                MatchHeaderConfig={{ MatchHeaderRHS: false }}
                fixture={currentFixture(fixtureState)}
              />
            )}
            {showPlayerCardHeader && (
              <PlayerCard
                player={player}
                playerCardConfig={{
                  ...playerCardConfig,

                  playerType: webConfigData?.imagePaths?.driversLeft?.replace(
                    "{seasonYear}",
                    getYearForImagePath(webConfigData)
                  ),
                  teamLogo: true,
                  jersyNo: player?.Skill == 1,
                  playerTrendsLabel: true,
                  hidePlayerCardBot: false,
                  addingExtraClass:
                    player?.Skill == 1 ? "si-driver" : "si-constructor",
                  isPlayerPlaying: player.IsActive === "1",
                  playerImageType: "player-popup",
                }}
                teamCls={player}
                ariaLabel=""
              />
            )}
          </div>
          <div className="si-popup__body">{component}</div>
          {noOverlayFoot && (
            <div className="si-popup__foot">
              {cancelBtn && (
                <CtaButton
                  fullWidth={true}
                  variant={"ghost"}
                  onClick={onCancelClick}
                  classes={customClsBtnSecondary}
                >
                  {btnSecondary}
                </CtaButton>
              )}
              {submitBtn && (
                <CtaButton
                  fullWidth={true}
                  variant={"accent"}
                  icon={btnPrimaryIcon}
                  classes={customClsBtnPrimary}
                  ariaLabel={btnPrimary}
                  onClick={onConfirmClick}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !confirmButtonState) {
                      e.preventDefault();
                      onConfirmClick();
                    }
                  }}
                  disabled={confirmButtonState}
                >
                  {btnPrimary}
                </CtaButton>
              )}
              {customFooter && customFooter}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export { Overlays };
