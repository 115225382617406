import React from "react";
import { CtaButton } from "../Buttons";

const InputSearch = ({
  ariaLabel,
  label = "",
  handleSearch = () => {
    return null;
  },
  handleInput = () => {
    return null;
  },
  searchInput = "",
  disabled = false,
}) => {
  const inputID = label.toLowerCase().replace(/ /, "-");

  return (
    <>
      <div className="si-searchFilter">
        <div className="si-search-bar">
          <form
            className="si-search-form"
            action=""
            method=""
            role="search"
            aria-label={ariaLabel}
          >
            <div className="si-input si-input--search ">
              {label && (
                <label className="si-search__label" htmlFor={inputID}>
                  {label}
                </label>
              )}
              <input
                type="search"
                id={inputID}
                className={`${disabled ? "si-disabled" : ""}`}
                placeholder={label}
                spellCheck="false"
                value={searchInput}
                disabled={disabled}
                onInput={(e) => handleInput(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSearch();
                  }
                }}
              />
            </div>

            <CtaButton
              onlyIcon={true}
              variant={"ghost"}
              icon="f1i-search"
              onClick={() => handleSearch()}
              restProps={{ ariaLabel: "search" }}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export { InputSearch };
