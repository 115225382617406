import React from "react";
import { SectionHeadingWithFiller } from "../HeadingWithFiller";

const StaticTemplate = ({ customPageCls, staticConfig }) => {
  const { pageTitle, components } = staticConfig;
  return (
    <>
      <section className={`si-static__wrap si-static__wrap--${customPageCls}`}>
        <div className="si-static__row">
          <div className="si-main__container" role="main">
            <div className="si-main__row">
              {/* <h2 className="si-static__title">{pageTitle}</h2> */}
              <SectionHeadingWithFiller
                // dark={true}
                heading={pageTitle}
              />
            </div>
          </div>
          <div className="si-main__row">{components}</div>
        </div>
      </section>
    </>
  );
};

export { StaticTemplate };
