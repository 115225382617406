import React from "react";
import config from "../../Common/config";
import { IMAGE_VERSION } from "../../Common/constants";
import { CtaButton } from "../Buttons";
import { getTranslations } from "../../Common/utils";
import { useSelector } from "react-redux";
import useCloudinaryData from "../../Common/hooks/useCloudinaryData";
import Media from "../media";

const WhatsNew = ({
  wnSectionHeading,
  wnSectionTitle,
  wnSectionThumb,
  wnSectionCaption,
  wnSectionCta,
  wnSectionThumbAlt,
  wnSectionCTAHandler = () => {},
}) => {
  const translationState = useSelector((state) => state.translation);
  const { cloudinaryBase, currentSeasonYear, envVersion } = useCloudinaryData();

  return (
    <>
      <div className="si-main__container">
        <div className="si-whatNew__wrap">
          <div className="si-whatNew__grid">
            <h2 className="si-section__title">
              <span>{wnSectionHeading}</span>
            </h2>
          </div>
          <div className="si-whatNew__grid">
            <div className="si-whatNew__card">
              <div className="si-whatNew__card-top">
                {wnSectionThumb && (
                  <div className="si-whatNew__card-thumb">
                    <Media
                      imgSrc={`${cloudinaryBase}/${envVersion}/Fantasy/${currentSeasonYear}/images/${wnSectionThumb}?v=${IMAGE_VERSION}`}
                      imgAlt={wnSectionThumbAlt}
                    />
                  </div>
                )}
                {wnSectionTitle && (
                  <div className="si-whatNew__card-title">{wnSectionTitle}</div>
                )}
              </div>
              <div className="si-whatNew__card-bot">
                {wnSectionCaption && (
                  <div
                    className="si-whatNew__card-caption"
                    dangerouslySetInnerHTML={{
                      __html: wnSectionCaption.replace(
                        /{{LANGUAGE}}/g,
                        translationState?.language
                      ),
                    }}
                  ></div>
                )}
                {wnSectionCta && (
                  <div className="si-whatNew__card-cta">
                    <CtaButton
                      variant={"primaryLight"}
                      icon="f1i-chevron-right"
                      onClick={wnSectionCTAHandler}
                    >
                      {wnSectionCta}
                    </CtaButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { WhatsNew };
