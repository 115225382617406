import React from "react";
import { getTranslations } from "../../Common/utils";
import config from "../../Common/config";
import { IMAGE_VERSION } from "../../Common/constants";
import Media from "../media";

const Rank = ({ rank, isMyRank }) => {
  return (
    <div className="si-tracker__card-rank">
      {isMyRank
        ? getTranslations("eot_my_rank_label", "My Rank")
        : getTranslations("eot_tracker_rank_lbl", "Rank")}
      : <span>{rank}</span>
    </div>
  );
};

const TrackerCard = ({
  extraCls,
  isIconName,
  trkCrdTitle,
  trkCrdCaption,
  trkCrdUserRank,
  trkCrdComponentBottom,
  trkCrdComponentRight,
  isMyRank = false,
}) => {
  return (
    <>
      <div
        className={`si-tracker__card ${
          extraCls ? `si-tracker__card--${extraCls}` : ""
        }`}
      >
        <div className="si-tracker__card-top">
          <div className="si-tracker__card-topGrid">
            <h2 className="si-tracker__card-title">
              {isIconName ? (
                <div className="si-tracker__card-icon">
                  <Media
                    imgSrc={`${config.IMG_BASE}tracker/${isIconName}?v=${IMAGE_VERSION}`}
                    imgAlt={isIconName}
                  />
                </div>
              ) : (
                ""
              )}
              <span>{trkCrdTitle}</span>
            </h2>
            {trkCrdComponentRight && (
              <Rank rank={trkCrdUserRank} isMyRank={isMyRank} />
            )}
          </div>
        </div>
        <div className="si-tracker__card-bot">
          <h3 className="si-tracker__card-caption">{trkCrdCaption}</h3>
          {trkCrdComponentBottom && (
            <Rank rank={trkCrdUserRank} isMyRank={isMyRank} />
          )}
        </div>
      </div>
    </>
  );
};

export { TrackerCard };
