export const dummyManageLeagueForUser = {
  adminInfo: {
    teamNo: [1, 2, 3],
    teamid: 3580309,
    maxTeam: 3,
    leagueId: 43707,
    socialId: "5519542",
    teamName: ["gang11", "GANESHMAHANOR", "GANESHMAHANOR11"],
    meetingid: "Australian Grand Prix",
    leagueCode: "C8KL3FV3707",
    leagueName: "MT%20TEST",
    userTeamNo: [1, 2, 3],
    meetingdate: ["2022-04-09", "2022-04-10"],
    ptgamedayid: 3,
    memeberCount: "2",
    leagueReportFlg: null,
  },
  member: [
    {
      teamid: 4350401,
      socialId: "5523519",
      teamName: ["GANEHHH111", "GANEHHH1", "GANEHHH111111"],
      userTeamNo: [1, 2, 3],
      leagueReportFlg: 0,
    },
  ],
};

export const dummyManageLeagueForManager = {
  adminInfo: {
    guid: "25f12eec-b9bb-11ed-a08f-026a435400df",
    isAdmin: 1,
    socialid: "5519542",
    leagueName: "MT%20TEST",
    leagueCode: "C8KL3FV3707",
    maxTeam: 3,
    teamid: 3580309,
    leagueId: 43707,
    leagueReportFlg: 0,
    leagueReportCnt: 0,
    leagueReportDays: 0,
    leaguesUuUser: 2,
    ptgamedayid: 3,
    meetingid: "Australian Grand Prix",
    meetingdate: ["2022-04-09", "2022-04-10"],
  },
  member: [
    {
      teamid: 3580309,
      socialId: "5519542",
      teamName: ["gang11", "GANESHMAHANOR", "GANESHMAHANOR11"],
      userTeamNo: [1, 2, 3],
    },
    {
      teamid: 4350401,
      socialId: "5523519",
      teamName: ["GANEHHH111", "GANEHHH1", "GANEHHH111111"],
      userTeamNo: [1, 2, 3],
    },
  ],
};

export const dummyManageHTHLeagueForManager = {
  adminInfo: {
    guid: "992982b6-b9ba-11ed-a1fd-026a435400df",
    isAdmin: 1,
    socialid: "5523519",
    leagueName: "MY%20TEST2",
    leagueCode: "H1OEBYS9X09",
    maxTeam: 1,
    teamid: 4350401,
    leagueId: 17309,
    leagueReportFlg: 0,
    leagueReportCnt: 0,
    leagueReportDays: 0,
    leaguesUuUser: 2,
    ptgamedayid: 3,
    meetingid: "Australian Grand Prix",
    meetingdate: ["2022-04-09", "2022-04-10"],
  },
  member: [
    {
      teamid: 4350401,
      isAdmin: 1,
      socialId: "5523519",
      teamName: ["GANEHHH111"],
      userTeamNo: [1],
      leagueReportFlg: 0,
    },
    {
      teamid: 3580309,
      isAdmin: 0,
      socialId: "5519542",
      teamName: ["gang11"],
      userTeamNo: [1],
      leagueReportFlg: 0,
    },
  ],
};
