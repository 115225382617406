import React from "react";
import config from "../../Common/config";
import { IMAGE_VERSION } from "../../Common/constants";
import useCloudinaryData from "../../Common/hooks/useCloudinaryData";
import Media from "../media";
const WhatsNewCard = ({ thumbName, title, caption, gameRulesRef, index }) => {
  const { cloudinaryBase, currentSeasonYear, envVersion } = useCloudinaryData();
  return (
    <>
      <div className="si-whatNew__box">
        <div className="si-whatNew__box-thumb">
          <Media
            imgSrc={`${cloudinaryBase}/${envVersion}/Fantasy/${currentSeasonYear}/images/whats-new/${thumbName}`}
            imgAlt=""
          />
        </div>
        <h2 className="si-whatNew__box-title">
          <span>{title}</span>
        </h2>
        <div
          key={index}
          ref={(ele) => {
            gameRulesRef.current[index] = ele;
          }}
          className="si-whatNew__box-caption"
          dangerouslySetInnerHTML={{
            __html: caption,
          }}
        ></div>
      </div>
    </>
  );
};

export { WhatsNewCard };
