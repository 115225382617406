import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { statistics } from "../../../redux/actions";
import { PlayerName } from "../../PlayerCard/PlayerName";
import { PlayerTrends } from "../../PlayerCard/PlayerTrends";
import PerformanceList from "./PerformanceList";
import { CONSTRUCTOR, DRIVER } from "./constants";
import { getNumberSuffix, getTranslations } from "../../../Common/utils";
import { getPriceDifference } from "../../../Common/utils/gameplay";
import { currentFixture, getMatchStatus } from "../../../Common/utils/fixture";
import { ProvisionalPtsTooltip } from "../../ProvisionalPtsTooltip";
import useLivePoints from "../../../Common/hooks/useLivePoints";

const DriverConstructorInfo = ({
  player,
  isOppositeTeam = false,
  selectedGameday = null,
}) => {
  const dispatch = useDispatch();
  const playerStatisticsState = useSelector((state) => state.statistics);
  const driverState = useSelector((state) => state.driver);
  const fixtureState = useSelector((state) => state.fixture);
  const { teamLivePointsCondition } = useLivePoints();

  const [bestRaceFinished, setBestRaceFinished] = useState({});
  const [highestGridStart, setHighestGridStart] = useState({});
  const [fastestPitstopAward, setFastestPitstopAward] = useState({});

  const { list: driversList } = driverState;
  const { playerStatistics, loading: playerStatisticsLoading } =
    playerStatisticsState;
  const { PlayerId } = player;

  const playerLatestStatistics =
    playerStatistics?.[playerStatistics?.length - 1];

  const playerLatestWeekendData = playerLatestStatistics?.content?.WeekendData;
  const playerOldValue =
    playerStatisticsLoading || !playerLatestWeekendData?.OldPlayerValue
      ? 0
      : playerLatestWeekendData?.OldPlayerValue;
  const playerNewValue =
    playerStatisticsLoading || !playerLatestWeekendData?.PlayerValue
      ? 0
      : playerLatestWeekendData?.PlayerValue;

  useEffect(() => {
    dispatch(statistics.getStatistics(PlayerId, teamLivePointsCondition));
  }, []);

  useEffect(() => {
    const PlayerId = player?.PlayerId;
    const currentPlayer = driversList?.find(
      (item) => item?.PlayerId === PlayerId
    );

    const BestRaceFinished = currentPlayer?.BestRaceFinished;
    const HigestGridStart = currentPlayer?.HigestGridStart;
    const FastestPitstopAward = currentPlayer?.FastestPitstopAward;
    const BestRaceFinishCount = currentPlayer?.BestRaceFinishCount;
    const HighestGridStartCount = currentPlayer?.HighestGridStartCount;
    const FastestPitstopAwardCount = currentPlayer?.FastestPitstopAwardCount;

    setBestRaceFinished({
      value: BestRaceFinished ? parseInt(BestRaceFinished) : 0,
      count: BestRaceFinishCount,
    });
    setHighestGridStart({
      value: HigestGridStart ? parseInt(HigestGridStart) : 0,
      count: HighestGridStartCount,
    });
    setFastestPitstopAward({
      value: FastestPitstopAward ? parseInt(FastestPitstopAward) : 0,
      count: FastestPitstopAwardCount,
    });
  }, [player, driversList]);

  const { PositionName } = player;

  const dirConList = [
    {
      id: 0,
      label:
        PositionName === CONSTRUCTOR
          ? getTranslations(
              "driver_constructor_card_highest_championship_finish",
              "Highest championship finish"
            )
          : getTranslations(
              "driver_constructor_card_best_race_finish",
              "Best Race Finish"
            ),
      value: `${bestRaceFinished?.value}<sup>${getNumberSuffix(
        bestRaceFinished?.value
      )}</sup>`,
      prefix: `x${bestRaceFinished?.count}`,
    },
    {
      id: 1,
      label:
        PositionName === CONSTRUCTOR
          ? getTranslations(
              "driver_constructor_card_fastest_pitstop_awards",
              "Fastest pitstop awards"
            )
          : getTranslations(
              "driver_constructor_card_highest_grid_start",
              "Highest Grid Start"
            ),
      value:
        PositionName === CONSTRUCTOR
          ? `${fastestPitstopAward?.value}<sup>${getNumberSuffix(
              fastestPitstopAward?.value
            )}</sup>`
          : `${highestGridStart?.value}<sup>${getNumberSuffix(
              highestGridStart?.value
            )}</sup>`,
      prefix:
        PositionName === CONSTRUCTOR
          ? `x${fastestPitstopAward?.count}`
          : `x${highestGridStart?.count}`,
    },
    {
      id: 2,
      label: getTranslations(
        "driver_constructor_card_season_points",
        "Season points"
      ),
      value:
        playerStatistics
          ?.find((stats) => stats?.content?.isSeasonData)
          ?.content?.StatsWise?.find(
            (x) => x?.Event?.toLowerCase()?.trim() === "total"
          )?.Value || 0,
      prefix: "Pts",
    },
    {
      id: 3,
      label: getTranslations(
        "driver_constructor_card_percentage_word_picked",
        "Percentage picked"
      ),
      value: playerStatisticsLoading
        ? "-"
        : playerStatistics?.find((stats) => stats?.content?.isSeasonData)
            ?.content?.SelectedPercentage || 0,
      prefix: playerStatisticsLoading ? "" : "%",
    },
  ];

  return (
    <>
      <div className="si-driCon__playerInfo">
        <PlayerName playerName={player?.FUllName} />
        <div className="si-player__trends-lbl">
          <span>
            {PositionName === CONSTRUCTOR
              ? getTranslations(
                  "driver_constructor_card_constructors_value",
                  "Constructors value"
                )
              : getTranslations(
                  "driver_constructor_card_driver_value",
                  "Driver value"
                )}
          </span>
        </div>
        <PlayerTrends
          trendStatus={
            // player?.Value === player?.Value
            //   ? "si-equal"
            //   :
            playerNewValue < playerOldValue ? "si-down" : "si-up"
          }
          costText={playerNewValue === 0 ? "-" : `$${playerNewValue}M`}
          trendText={`$${getPriceDifference(playerNewValue, playerOldValue)}M`}
          ariaLabel=""
          showTrend={
            getPriceDifference(playerNewValue, playerOldValue) === "0.0" ||
            playerOldValue === 0
              ? false
              : true
          }
        />
      </div>
      <div className="si-driCon__list">
        <ul>
          {dirConList?.map((item, index) => (
            <li
              key={item.id}
              aria-label={`${item?.label} ${item?.value}${item.prefix}`}
            >
              <div className="si-driCon__list-title">
                <span>
                  {item.label}{" "}
                  {item.id === 2 &&
                    getMatchStatus(
                      currentFixture(fixtureState)?.MatchStatus
                    ) === "PROVISIONAL POINTS" && (
                      <ProvisionalPtsTooltip isTooltipTrue={true} />
                    )}
                </span>
              </div>
              <div className="si-driCon__list-stats">
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${item.value} <em>${item.prefix}</em>`,
                  }}
                ></span>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <PerformanceList
        statistics={playerStatistics}
        isOppositeTeam={isOppositeTeam}
        selectedGameday={selectedGameday}
      />
    </>
  );
};

export { DriverConstructorInfo };
