import React, { useState, useEffect } from "react";
import { PlayerName } from "./PlayerName";
import { PlayerStats } from "./PlayerStats";
import { PlayerTeamJersyNo } from "./PlayerTeamJersyNo";
import { PlayerTeamLogo } from "./PlayerTeamLogo";
import { PlayerThumbnail } from "./PlayerThumbnail";
import { PlayerTrends } from "./PlayerTrends";
import { useDispatch, useSelector } from "react-redux";
import { gameplay } from "../../redux/actions";
import core from "../../Common/core";
import { isPlayerAdded, canAddPlayer } from "../../Common/utils/team";
import {
  getTranslations,
  getYearForImagePath,
  playerPoints,
  toNumber,
} from "../../Common/utils";
import PlayerButton from "./PlayerButton";
import {
  LIVE_POINTS_CLASS,
  TRENDS_MIN_RACE_WEEK,
} from "../../Common/constants";
import { getPriceDifference } from "../../Common/utils/gameplay";
import { useParams } from "react-router-dom";
import useLivePoints from "../../Common/hooks/useLivePoints";
import PlayerImageSection from "./PlayerImageSection";

function PlayerCard({
  teamCls,
  ariaLabel,
  player,
  index,
  playerCardConfig,
  isTurboDriver = false,
  isExtraTurboDriver = false,
  dataText = "",
  showPoints = false,
  isLateOnBoard = false,
  isFinalFix = false,
  team = {},
  currentSeasonYear = null,
  closeButtonVariant,
  showButtonText,
}) {
  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;

  const {
    ctaBtn = false,
    disableRemoveCTA = false,
    teamLogo = false,
    jersyNo = false,
    playerStatsInList = false,
    playerTrendsLabel = false,
    ctaAppendClass = "primaryDark",
    playerType = webConfigData?.imagePaths?.driversFront?.replace(
      "{seasonYear}",
      getYearForImagePath(webConfigData, currentSeasonYear)
    ),
    hidePlayerCardBot = true,
    addDisabledClass = false,
    addingExtraClass = "",
    nameType = "full",
    turnOffTrends = true,
    showPopup = true,
    isPlayerPlaying = true,
    notPlayingOnlyIcon = false,
    playerImageType = "",
  } = playerCardConfig;
  const dispatch = useDispatch();
  const params = useParams();
  const { teamLivePointsCondition } = useLivePoints();
  const gameplayState = useSelector((state) => state.gameplay);
  const constraintsState = useSelector((state) => state.constraints);
  const driverState = useSelector((state) => state.driver);
  const {
    selectedTab,
    selectedPlayers,
    selectedView,
    budget,
    selectedTurboDriver,
    selectedExtraTurboDriver,
    replacedPlayer,
    teams,
    selectedTeam,
    oppDetailedTeams,
  } = gameplayState;

  // const showButtonText = () => {
  //   return core.isMobile() && selectedView === "grid";
  // };
  const isDisabled = () => {
    if (isPlayerAdded(player, selectedPlayers) && disableRemoveCTA) {
      return true;
    }
    return (
      addDisabledClass &&
      (isReplacedPlayer(player) ||
        (!isPlayerAdded(player, selectedPlayers) &&
          !canAddPlayer(player, selectedPlayers, budget, gameplayState)))
    );
  };

  // const isTurboDriver = () => {
  //   return (
  //     player?.PlayerId === selectedTurboDriver?.PlayerId || player?.isCaptain
  //   );
  // };

  const isReplacedPlayer = (player) => {
    return player?.PlayerId === replacedPlayer?.PlayerId;
  };

  const togglePlayerPopup = (player) => {
    if (!showPopup) return;
    dispatch(gameplay.togglePlayerPopup(player));
  };

  return (
    <>
      <div
        className={`si-player__box si-team__${player?.TeamId} ${
          isTurboDriver ? "si-turbo" : ""
        } ${addingExtraClass} ${isDisabled() ? "si-opacity" : ""} ${
          isExtraTurboDriver ? "si-extraDrs" : ""
        }`}
        aria-label={ariaLabel}
      >
        <div
          className="si-player__card-top"
          onClick={() => togglePlayerPopup(player)}
          data-text={dataText}
        >
          <PlayerImageSection
            currentSeasonYear={currentSeasonYear}
            isPlayerPlaying={isPlayerPlaying}
            jersyNo={jersyNo}
            notPlayingOnlyIcon={notPlayingOnlyIcon}
            player={player}
            playerType={playerType}
            teamLogo={teamLogo}
            playerImageType={playerImageType}
            isTurboDriver={isTurboDriver}
            isExtraTurboDriver={isExtraTurboDriver}
            isFinalFix={isFinalFix}
          />
        </div>
        {hidePlayerCardBot && (
          <div
            className="si-player__card-bot"
            onClick={() => togglePlayerPopup(player)}
          >
            <div className="si-player__card-names">
              <PlayerName
                playerName={
                  nameType === "full" ? player?.FUllName : player?.DisplayName
                }
                player={player}
              />
              {playerStatsInList && <PlayerStats player={player} />}
            </div>
            {turnOffTrends && (
              <div className="si-player__card-trends">
                {playerTrendsLabel && (
                  <div className="si-player__trends-lbl">
                    <span>
                      {getTranslations("create_team_sort_head_3", "Value")}
                    </span>
                  </div>
                )}
                {!showPoints && (
                  <PlayerTrends
                    trendStatus={
                      // player?.Value === player?.OldPlayerValue
                      //   ? "si-equal"
                      //   :
                      player?.Value < player?.OldPlayerValue
                        ? "si-down"
                        : "si-up"
                    }
                    costText={`$${Number(player?.Value)?.toFixed(1)}M`}
                    trendText={`$${getPriceDifference(
                      player?.Value,
                      player?.OldPlayerValue
                    )}M`}
                    spanClass="si-bgTxt"
                    ariaLabel=""
                    showTrend={
                      getPriceDifference(
                        player?.Value,
                        player?.OldPlayerValue
                      ) === "0.0" || player?.OldPlayerValue === 0
                        ? false
                        : true
                    }
                  />
                )}
                {showPoints && (
                  // && !isLateOnBoard
                  <PlayerTrends
                    trendStatus={
                      playerPoints({
                        player,
                        team,
                        oppDetailedTeams,
                      }) === 0
                        ? "si-equal si-points"
                        : playerPoints({
                            player,
                            team,
                            oppDetailedTeams,
                          }) < 0
                        ? "si-down si-points"
                        : "si-up si-points"
                    }
                    costText={getTranslations(
                      "driver_stats_modal_points",
                      `Points`
                    )}
                    trendText={playerPoints({
                      player,
                      team,
                      oppDetailedTeams,
                    })}
                    ariaLabel=""
                    trendSpanClass={
                      teamLivePointsCondition ? LIVE_POINTS_CLASS : ""
                    }
                  />
                )}
              </div>
            )}
          </div>
        )}
        {ctaBtn && (
          <div className="si-player__card-cta">
            <PlayerButton
              player={player}
              ctaAppendClass={ctaAppendClass}
              showButtonText={showButtonText}
              index={index}
              disableRemoveCTA={disableRemoveCTA}
              closeButtonVariant={closeButtonVariant}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { PlayerCard };
