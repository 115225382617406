import { useMemo } from "react";
import {
  getPlayerPopupConstructorImage,
  getPlayerPopupDriverImage,
} from "../../../Common/utils/cloudinary";
import useCloudinaryData from "../../../Common/hooks/useCloudinaryData";
import {
  CLOUDINARY_IMAGE_BASE_URL,
  IMAGE_VERSION,
} from "../../../Common/constants";
import Media from "../../media";

const PlayerPopupImage = ({ player }) => {
  const { configData, currentSeasonYear } = useCloudinaryData();

  const playerImage = useMemo(() => {
    if (player?.PositionName === "DRIVER") {
      return getPlayerPopupDriverImage(player, currentSeasonYear, configData);
    } else if (player?.PositionName === "CONSTRUCTOR") {
      return getPlayerPopupConstructorImage(
        player,
        currentSeasonYear,
        configData
      );
    } else {
      return null;
    }
  }, [configData, currentSeasonYear, player]);

  return <Media imgSrc={playerImage} imgAlt={player?.FUllName} />;
};

export default PlayerPopupImage;
