import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MAX_LEAGUE_NAME_CHARACTERS } from "../../../Common/constants";
import { disableEmojiInput, getTranslations } from "../../../Common/utils";
import { league } from "../../../redux/actions";
import { AffordableToggleSwitch } from "../../AffordableToggleSwitch";
import { ErrorMessage } from "../../Error/message";
import { PvtPubCardChips } from "../../PvtPubCardChips";

const CreateALeague = (props) => {
  const dispatch = useDispatch();
  const gameplayState = useSelector((state) => state.gameplay);
  const [leagueType, setLeagueType] = useState("");
  const [leagueName, setLeagueName] = useState("");
  const [maxParticipants, setMaxParticipants] = useState(10);
  const [maxTeams, setMaxTeams] = useState(1);
  const [checked, setChecked] = useState(false);

  const handleLeagueTypeChange = (e) => {
    setLeagueType(e.target.value);
  };
  const handleLeagueNameChange = (e) => {
    let val = disableEmojiInput(e.target.value);
    setLeagueName(val);
    // setLeagueName(e.target.value);
  };
  const handleMaxParticipantsChange = (e) => {
    // if (leagueType == "private" && Number(e.target.value) < 5) {
    //   setMaxParticipants(5);
    // } else {
    setMaxParticipants(Number(e.target.value));
    // }
  };
  const handleMaxTeamsChange = (e) => {
    let value = Number(e.target.value);
    dispatch(league.setMaxTeams(value));
    setMaxTeams(value);
  };

  const handleChecked = () => {
    setChecked(!checked);
  };

  const handlePasteMaxTeam = (event) => {
    event.preventDefault();
    const pastedText = (event.clipboardData || window.clipboardData).getData(
      "text"
    );
    const filteredText = pastedText.replace(/[^0-9]/g, "");
    setMaxParticipants(filteredText);
  };
  useEffect(() => {
    if (checked) {
      setMaxParticipants(99999999);
    } else if (leagueType == "h2h") {
      setMaxParticipants(2);
    } else {
      setMaxParticipants(10);
    }
  }, [checked]);

  useEffect(() => {
    if (leagueType == "h2h") {
      setMaxParticipants(2);
      setChecked(false);
      setMaxTeams(1);
    } else if (leagueType == "public") {
      setChecked(true);
    } else {
      setChecked(false);
      setMaxParticipants(10);
      setMaxTeams(3);
    }
  }, [leagueType]);

  useEffect(() => {
    dispatch(
      league.setCreateLeagueData({
        leagueType,
        leagueName,
        maxParticipants,
        maxTeams,
      })
    );
  }, [leagueType, leagueName, maxParticipants, maxTeams, dispatch]);

  return (
    <>
      <div className="si-league__overlay">
        <form className="" action="" method="" aria-label="">
          <div className="si-league__overlay-box">
            <div className="si-league__overlay-box-radio">
              <h2 className="si-league__overlay-box-title">
                <span>
                  {getTranslations(
                    "create_league_popup_choose_type_caption",
                    "Choose a type of league"
                  )}
                </span>
              </h2>
              <div className="si-radioBtn__wrap">
                <div
                  className={`si-radioBtn ${
                    leagueType === "private" ? "si-classic" : ""
                  }`}
                >
                  <input
                    type="radio"
                    id="createPrivateLeague"
                    name="leagueType"
                    value="private"
                    onChange={(e) => handleLeagueTypeChange(e)}
                  />
                  <label htmlFor="createPrivateLeague">
                    <div className="si-league__radio-title">
                      <span>
                        {getTranslations(
                          "create_league_popup_private_league_text",
                          "Classic Private League"
                        )}
                      </span>
                    </div>
                    <div className="si-league__radio-caption">
                      <span>
                        {getTranslations(
                          "create_league_popup_private_league_caption",
                          "Can join only through invite"
                        )}
                      </span>
                    </div>
                    <PvtPubCardChips PvtPubCardChipsNameConfig={([0], [0])} />
                  </label>
                </div>
                <div
                  className={`si-radioBtn ${
                    leagueType === "h2h" ? "si-h2h" : ""
                  }`}
                >
                  <input
                    type="radio"
                    id="createH2hLeague"
                    name="leagueType"
                    value="h2h"
                    onChange={(e) => handleLeagueTypeChange(e)}
                  />
                  <label htmlFor="createH2hLeague">
                    <div className="si-league__radio-title">
                      <span>
                        {getTranslations(
                          "create_league_popup_h2h_league_text",
                          "Head 2 Head Private League"
                        )}
                      </span>
                    </div>
                    <div className="si-league__radio-caption">
                      <span>
                        {getTranslations(
                          "create_league_popup_h2h_league_caption",
                          "Compete against just one other player in this league"
                        )}
                      </span>
                    </div>
                    <PvtPubCardChips PvtPubCardChipsNameConfig={([1], [1])} />
                  </label>
                </div>
                <div
                  className={`si-radioBtn ${
                    leagueType === "public" ? "si-public" : ""
                  }`}
                >
                  <input
                    type="radio"
                    id="createPublicLeague"
                    name="leagueType"
                    value="public"
                    onChange={(e) => handleLeagueTypeChange(e)}
                  />
                  <label htmlFor="createPublicLeague">
                    <div className="si-league__radio-title">
                      <span>
                        {getTranslations(
                          "create_league_popup_public_league_text",
                          "Public League"
                        )}
                      </span>
                    </div>
                    <div className="si-league__radio-caption">
                      <span>
                        {getTranslations(
                          "create_league_popup_public_league_caption",
                          "Anyone can join -- no limits"
                        )}
                      </span>
                    </div>
                    <PvtPubCardChips PvtPubCardChipsNameConfig={([2], [2])} />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="si-league__overlay-box">
            <div className="si-input ">
              <label className="si-lbl" htmlFor="createLeagueName">
                <span>
                  {getTranslations(
                    "create_league_popup_league_name_label",
                    "League Name"
                  )}
                </span>
                <span className="si-maxLimit">
                  {getTranslations(
                    "create_league_popup_league_name_validation",
                    "Max. {{MAX_CHARACTERS}} characters"
                  ).replace("{{MAX_CHARACTERS}}", MAX_LEAGUE_NAME_CHARACTERS)}
                </span>
              </label>
              <input
                type="text"
                id="createLeagueName"
                className=""
                placeholder={getTranslations(
                  "create_league_popup_league_name_placeholder",
                  "Enter your league name"
                )}
                value={leagueName}
                spellCheck="false"
                maxLength={MAX_LEAGUE_NAME_CHARACTERS}
                onChange={(e) => handleLeagueNameChange(e)}
              />
            </div>
            <AffordableToggleSwitch
              toggleLabelText={getTranslations(
                "create_league_popup_unlimited_participants_toggle",
                "Unlimited no. of participants"
              )}
              handleChecked={handleChecked}
              checked={checked}
              leagueType={leagueType}
            />
            <div className=" si-league__overlay-box--grid">
              <div className="si-input ">
                <label className="si-lbl" htmlFor="maximumParticipants">
                  <span>
                    {getTranslations(
                      "create_league_popup_maximum_participants_label",
                      "Maximum Participants"
                    )}
                  </span>
                </label>
                <input
                  type={checked ? "text" : "number"}
                  id="maximumParticipants"
                  className=""
                  placeholder="10"
                  disabled={checked || leagueType == "h2h"}
                  value={checked ? "-" : maxParticipants}
                  min={2}
                  max={99999999}
                  spellCheck="false"
                  onChange={(e) => handleMaxParticipantsChange(e)}
                  onKeyDown={(event) => {
                    if (event.key === ".") event.preventDefault();
                  }}
                  onPaste={handlePasteMaxTeam}
                />
              </div>
              <div className="si-input ">
                <label className="si-lbl" htmlFor="numberTeamsAllow">
                  <span>
                    {getTranslations(
                      "create_league_popup_maximum_teams_label_short",
                      "Teams per user"
                    )}
                  </span>
                </label>
                <select
                  id="numberTeamsAllow"
                  name="numberTeamsAllow"
                  aria-disabled="true"
                  aria-expanded="true"
                  aria-label="Select your country"
                  disabled={leagueType == "h2h"}
                  value={maxTeams}
                  onChange={(e) => handleMaxTeamsChange(e)}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                </select>
              </div>
            </div>
          </div>
          {!checked && leagueType !== "h2h" && maxParticipants < 5 ? (
            <ErrorMessage
              text={getTranslations(
                "create_league_popup_max_participants_error",
                "Maximum teams can't be less than 5"
              )}
            />
          ) : (
            ""
          )}
        </form>
      </div>
    </>
  );
};

export { CreateALeague };
