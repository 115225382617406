import React from "react";
import RaceweekFlag from "../Cloudinary/RaceweekFlag";
import { FixRound } from "../Fixtures/FixRound";
import { FixDates } from "../Fixtures/FixDates";

const RaceDetails = ({
  rw_flag,
  rw_round,
  rw_location,
  rw_caption,
  rw_fixture,
  tagName,
  rw_flag_size = "md",
}) => {
  const CustomTag = tagName;
  return (
    <>
      <div className="si-rd__wrap">
        <div className="si-rd__top">
          {rw_flag && (
            <RaceweekFlag size={rw_flag_size} countryName={rw_flag} />
          )}
          {rw_round && <FixRound {...rw_round} />}
        </div>
        {rw_location && (
          <div className="si-rd__mid">
            <CustomTag className="si-matchHeader__title">
              <span>{rw_location}</span>
            </CustomTag>
          </div>
        )}
        {rw_caption && (
          <div className="si-rd__bot">
            <div className="si-matchHeader__discrip">
              <p>{rw_caption}</p>
            </div>
          </div>
        )}
        {rw_fixture && (
          <>
            <FixDates {...rw_fixture} />
          </>
        )}
      </div>
    </>
  );
};

export default RaceDetails;
