import React from "react";
import SectionLoader from "../../Loaders/SectionLoader";
import MyTeamHeader from "../../../Components/MyTeam/MyTeamHeader";
import {
  currentFixture,
  getFixtureDateForLeagues,
  getMatchStatus,
} from "../../../Common/utils/fixture";
import { PlayerBudget } from "../../PlayerBudget";
import { BoosterChips } from "../../BoosterChips";
import { useSelector } from "react-redux";
import { getPoints, getTranslations, isMobile } from "../../../Common/utils";
import { ProvisionalPtsTooltip } from "../../ProvisionalPtsTooltip";
import { ErrorMessage } from "../../Error/message";
import { EXTRA_TRANSFER_COST, PORTRAIT } from "../../../Common/constants";
import { Formations } from "../../Formations";
import { PlayerInfo } from "../../PlayerInfo";
import { TotalPointsSection } from "../../TotalPointsSection";
import useLivePoints from "../../../Common/hooks/useLivePoints";
import Notes from "../../Notes";
import { useParams } from "react-router-dom";
import useIPadOrientation from "../../../Common/hooks/useIPadOrientation";

const OppositeTeam = ({
  fetchOppDetailedTeamLoading,
  oppositeTeamDetails,
  teamNo,
  meetingFixtures,
  fixtureStart,
  fixtureEnd,
  selectedOpponentBooster,
  selectedOption,
  hasMadeExtraTransfers,
  transfersLeft,
  playerCardConfig,
}) => {
  // const { teamNo, phaseId, gameDayId } = useParams();
  const fixtureState = useSelector((state) => state.fixture);
  const gameplayState = useSelector((state) => state.gameplay);
  const { teamLivePointsCondition } = useLivePoints();
  const isAllRaces = selectedOption?.id === 0;

  const { isIPad, screenDimensionOrientation } = useIPadOrientation();
  const isIPadPortrait = isIPad && screenDimensionOrientation === PORTRAIT;

  const getShowReplacedPlayerKey = () => {
    if (oppositeTeamDetails?.boosterid === 3) {
      return true;
    } else if (oppositeTeamDetails?.playerSwapDetails?.length) {
      return true;
    } else {
      return false;
    }
  };

  const getReplacedPlayer = () => {
    if (oppositeTeamDetails?.boosterid === 3) {
      return oppositeTeamDetails.team?.find((x) => x.isFinalFixOldPlayer);
    } else if (oppositeTeamDetails?.playerSwapDetails?.length) {
      return oppositeTeamDetails.team?.find((x) => x.isSwappedOutPlayer);
    } else {
      return null;
    }
  };

  const getNewLinePlayer = () => {
    if (
      oppositeTeamDetails?.boosterid === 3 &&
      oppositeTeamDetails?.playerSwapDetails?.length
    ) {
      return [oppositeTeamDetails.team?.find((x) => x.isSwappedOutPlayer)];
    }
  };

  return (
    <>
      <div className="si-league__wrap">
        {fetchOppDetailedTeamLoading ? (
          <SectionLoader />
        ) : !oppositeTeamDetails ? null : (
          <>
            <div className="si-main__container">
              <div className="si-main__row">
                <div className="si-league__header si-league__header--manage">
                  <div className="si-myTeam__head">
                    <MyTeamHeader
                      teamNumber={`T${oppositeTeamDetails?.teamno}`}
                      team={{
                        name: oppositeTeamDetails?.teamname,
                        availableTransfers: oppositeTeamDetails?.usersubsleft,
                        maxTransfers: oppositeTeamDetails?.usersubs,
                      }}
                      isSelectTeam={true}
                      showCheckbox={false}
                      index={teamNo - 1}
                      showRound={true}
                      showDate={true}
                      fixRoundLbl={false}
                      roundNo={meetingFixtures?.[0]?.MeetingNumber}
                      fixDates={getFixtureDateForLeagues([
                        fixtureStart,
                        fixtureEnd,
                      ])}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="si-myTeam__grid">
              <div className="si-myTeam__grid-item">
                <div className="si-myTeam__details">
                  <PlayerBudget
                    hideBugdetProgress="true"
                    playerBudgetLabelKey=""
                    budget={oppositeTeamDetails?.budget}
                    showLimitless={oppositeTeamDetails?.boosterid === 1}
                  />
                  {selectedOpponentBooster && selectedOpponentBooster[0] ? (
                    <BoosterChips
                      boosterCls={selectedOpponentBooster?.[0]?.boosterCls}
                      boosterIcon={selectedOpponentBooster?.[0]?.boosterIcon}
                      hideBoosterText={isMobile()}
                      boosterText={selectedOpponentBooster?.[0]?.boosterText}
                    />
                  ) : (
                    ""
                  )}
                  <TotalPointsSection
                    displayProvisionalTooltip={
                      currentFixture(fixtureState)?.GamedayId ===
                        selectedOption?.id &&
                      getMatchStatus(
                        currentFixture(fixtureState)?.MatchStatus
                      ) === "PROVISIONAL POINTS"
                    }
                    gdPoints={getPoints(oppositeTeamDetails?.gdpoints)}
                    livePointsCondition={isAllRaces && teamLivePointsCondition}
                  />
                </div>
                {!!oppositeTeamDetails?.islateonboard && (
                  <div className="si-errMsg__wrap">
                    <ErrorMessage
                      text={getTranslations(
                        "completed_races_team_late_onboard_message",
                        "*Late team creation incurs a 10 point deduction. Points will be calculated from the date of creation."
                      )}
                    />
                  </div>
                )}
                {hasMadeExtraTransfers && (
                  <div className="si-errMsg__wrap">
                    <ErrorMessage
                      text={getTranslations(
                        "completed_races_team_additonal_transfer_message",
                        `Additional transfers points deducted from overall score. (-4x{{ADDITIONAL_TRANSFERS}} = -{{ADDITIONAL_TRANSFER_COST}} pts)`
                      )
                        .replace(
                          "{{ADDITIONAL_TRANSFERS}}",
                          Math.abs(transfersLeft)
                        )
                        .replace(
                          "{{ADDITIONAL_TRANSFER_COST}}",
                          Math.abs(
                            transfersLeft * oppositeTeamDetails?.extrasubscost
                          )
                        )}
                    />
                  </div>
                )}
                {!!oppositeTeamDetails?.inactive_driver_penality_points &&
                  !isMobile() &&
                  !isIPadPortrait && (
                    <div className="si-errMsg__wrap">
                      <ErrorMessage
                        // customCls={"si-error "}
                        // iconName={"f1i-error"}
                        text={getTranslations(
                          "completed_races_team_inactive_driver_swap_message",
                          "Inactive driver penalty points = {INACTIVE_PENALTIES} pts"
                        ).replace(
                          "{INACTIVE_PENALTIES}",
                          oppositeTeamDetails?.inactive_driver_penality_points
                        )}
                      />
                    </div>
                  )}
                <div className="si-myTeam__body">
                  <div className="si-myTeam__formation">
                    <Formations
                      selectedPlayers={oppositeTeamDetails?.team}
                      playerCardConfig={playerCardConfig}
                      budget={oppositeTeamDetails?.budget}
                      turboDriver={oppositeTeamDetails?.team?.find(
                        (x) => x?.isCaptain
                      )}
                      extraTurboDriver={oppositeTeamDetails?.team?.find(
                        (x) => x?.isExtraDRS
                      )}
                      isLateOnBoard={!!oppositeTeamDetails?.islateonboard}
                      showPoints={true}
                      showReplacedPlayer={getShowReplacedPlayerKey()}
                      replacedPlayer={getReplacedPlayer()}
                      newLinePlayers={getNewLinePlayer()}
                      hideCloseButton={true}
                      isFinalFix={oppositeTeamDetails?.boosterid === 3}
                      team={oppositeTeamDetails}
                    />
                  </div>
                </div>
              </div>
            </div>

            <PlayerInfo
              hasCTAs={false}
              isOppositeTeam={true}
              selectedGameday={selectedOption}
            />
          </>
        )}
      </div>
    </>
  );
};

export default OppositeTeam;
