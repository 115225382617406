import {
  CLOUDINARY_IMAGE_BASE_URL,
  IMAGE_VERSION,
} from "../../../Common/constants";
import useCloudinaryData from "../../../Common/hooks/useCloudinaryData";
import Media from "../../media";

const TrackMap = ({ countryName }) => {
  const { configData, cloudinaryBase, envVersion, currentSeasonYear } =
    useCloudinaryData();

  const trackMapImg = configData?.imagePaths?.trackMapImg;

  const trackMapImagePath = trackMapImg
    ?.replaceAll("<cloudinary_base_path>", cloudinaryBase)
    ?.replaceAll("<env_version>", envVersion)
    ?.replaceAll("<current_season_year>", currentSeasonYear)
    ?.replaceAll("<circuit_short_name>", countryName);

  return (
    <Media imgSrc={trackMapImagePath} imgAlt={countryName} />
    // <img
    //   src={trackMapImagePath}
    //   alt={countryName}
    //   onError={({ currentTarget }) => {
    //     currentTarget.onerror = null; // prevents looping
    //     currentTarget.src = `${CLOUDINARY_IMAGE_BASE_URL}common/fallback.svg?v=${IMAGE_VERSION}`;
    //   }}
    //   loading="lazy"
    // />
  );
};

export default TrackMap;
