import {
  FETCH_TEAM_PENDING,
  FETCH_TEAM_REJECTED,
  FETCH_TEAM_FULFILLED,
  SET_SELECTED_TAB,
  TOGGLE_SELECTED_PLAYER,
  RESET_SELECTED_PLAYERS,
  SET_SELECTED_INDEX,
  SET_BUDGET,
  SET_SELECTED_VIEW,
  TOGGLE_PLAYER_LIST,
  TOGGLE_TURBO_DRIVER_POPUP,
  TOGGLE_TEAM_NAME_POPUP,
  TOGGLE_PROFILE_POPUP,
  TOGGLE_TRANSFER_POPUP,
  TOGGLE_CHIPS_POPUP,
  TOGGLE_FILTER_POPUP,
  TOGGLE_ALERT_POPUP,
  TOGGLE_CIRCUIT_POPUP,
  TOGGLE_CONFIRM_CHIPS_POPUP,
  TOGGLE_PLAYER_POPUP,
  TOGGLE_EXTRA_TURBO_DRIVER_POPUP,
  SET_TURBO_DRIVER,
  SET_TEMP_TURBO_DRIVER,
  SET_TEAM_NAME,
  SET_PROFILE_DATA,
  SAVE_TEAM_PENDING,
  SAVE_TEAM_REJECTED,
  SAVE_TEAM_FULFILLED,
  SAVE_TEAM_RESET,
  TRANSFER_TEAM_PENDING,
  TRANSFER_TEAM_REJECTED,
  TRANSFER_TEAM_FULFILLED,
  UPDATE_CAPTAIN_PENDING,
  UPDATE_CAPTAIN_REJECTED,
  UPDATE_CAPTAIN_FULFILLED,
  SET_SELECTED_TEAM,
  SET_SELECTED_BOOSTER,
  RESET_SELECTED_BOOSTER,
  SET_TEMP_BOOSTER,
  SET_FILTERS,
  RESET_FILTERS,
  APPLY_FILTERS,
  FETCH_BOOSTERS_PENDING,
  FETCH_BOOSTERS_REJECTED,
  FETCH_BOOSTERS_FULFILLED,
  SET_REPLACED_PLAYER,
  SET_TEMP_EXTRA_TURBO_DRIVER,
  SET_EXTRA_TURBO_DRIVER,
  UPDATE_TEAM_NAME_PENDING,
  UPDATE_TEAM_NAME_REJECTED,
  UPDATE_TEAM_NAME_FULFILLED,
  RESET_UPDATE_TEAM_NAME,
  TRANSFER_TEAM_RESET,
  GET_USER_GAME_DAYS_PENDING,
  GET_USER_GAME_DAYS_REJECTED,
  GET_USER_GAME_DAYS_FULFILLED,
  FETCH_DETAILED_TEAM_PENDING,
  FETCH_DETAILED_TEAM_REJECTED,
  FETCH_DETAILED_TEAM_FULFILLED,
  RESET_UPDATE_CAPTAIN,
  TOGGLE_RACE_WEEK_TAB,
  RESET_GO_BACK,
  DISCARD_TRANSFERS,
  FETCH_OPP_DETAILED_TEAM_PENDING,
  FETCH_OPP_DETAILED_TEAM_REJECTED,
  FETCH_OPP_DETAILED_TEAM_FULFILLED,
  FETCH_OPP_USER_GAME_DAYS_PENDING,
  FETCH_OPP_USER_GAME_DAYS_REJECTED,
  FETCH_OPP_USER_GAME_DAYS_FULFILLED,
  RESET_OPP_TEAM,
  RESET_GAMEPLAY_PERSISTER,
  GET_USER_GAME_DAYS_V1_PENDING,
  GET_USER_GAME_DAYS_V1_REJECTED,
  GET_USER_GAME_DAYS_V1_FULFILLED,
  FETCH_H2H_OPPONENT_USER_GAME_DAYS_PENDING,
  FETCH_H2H_OPPONENT_USER_GAME_DAYS_REJECTED,
  FETCH_H2H_OPPONENT_USER_GAME_DAYS_FULFILLED,
  FETCH_H2H_OPPONENT_TEAM_PENDING,
  FETCH_H2H_OPPONENT_TEAM_REJECTED,
  FETCH_H2H_OPPONENT_TEAM_FULFILLED,
  RESET_H2H_OPPONENT,
  FETCH_TEAM_FOR_RACEWEEK_PENDING,
  FETCH_TEAM_FOR_RACEWEEK_REJECTED,
  FETCH_TEAM_FOR_RACEWEEK_FULFILLED,
  FETCH_RATING_STATISTICS_PENDING,
  FETCH_RATING_STATISTICS_REJECTED,
  FETCH_RATING_STATISTICS_FULFILLED,
  FETCH_SORTING_STATISTICS_PENDING,
  FETCH_SORTING_STATISTICS_REJECTED,
  FETCH_SORTING_STATISTICS_FULFILLED,
  FETCH_SWAP_SUGGESTIONS_PENDING,
  FETCH_SWAP_SUGGESTIONS_REJECTED,
  FETCH_SWAP_SUGGESTIONS_FULFILLED,
  REPLACE_SWAP_PLAYERS,
  SET_SWAP_SUGGESTION_OVERLAY_DATA,
  RESET_SWAP_SUGGESTION_OVERLAY_DATA,
  REPLACE_SWAP_PLAYERS_PENDING,
  REPLACE_SWAP_PLAYERS_REJECTED,
  REPLACE_SWAP_PLAYERS_FULFILLED,
  RESET_REPLACE_SWAP_PLAYERS,
  RESET_TEMP_BOOSTER,
  PAGE_NOT_FOUND,
  RESET_PAGE_NOT_FOUND,
  RESET_ALERT_POPUP,
} from "../../constants/gameplay";
import {
  APPLY_AUTO_PILOT_PENDING,
  APPLY_AUTO_PILOT_REJECTED,
  APPLY_AUTO_PILOT_FULFILLED,
  APPLY_EXTRA_DRS_PENDING,
  APPLY_EXTRA_DRS_REJECTED,
  APPLY_EXTRA_DRS_FULFILLED,
  APPLY_FINAL_FIX_PENDING,
  APPLY_FINAL_FIX_REJECTED,
  APPLY_FINAL_FIX_FULFILLED,
  APPLY_LIMITLESS_PENDING,
  APPLY_LIMITLESS_REJECTED,
  APPLY_LIMITLESS_FULFILLED,
  APPLY_NON_NEGATIVE_PENDING,
  APPLY_NON_NEGATIVE_REJECTED,
  APPLY_NON_NEGATIVE_FULFILLED,
  RESET_EXTRA_DRS,
  RESET_AUTO_PILOT,
  RESET_FINAL_FIX,
  RESET_LIMITLESS,
  RESET_NON_NEGATIVE,
  CHANGE_EXTRA_DRS_PENDING,
  CHANGE_EXTRA_DRS_REJECTED,
  CHANGE_EXTRA_DRS_FULFILLED,
  RESET_CHANGE_EXTRA_DRS,
  RESET_EXTRA_TURBO_DRIVER,
} from "../../constants/booster";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { persistReducer } from "redux-persist";
import { formatBoosterData } from "../../../Common/utils/booster";
import {
  decodeName,
  getSessionIndex,
  getSwappedPlayerPoints,
} from "../../../Common/utils";
import { RE_RENDER_LOCAL_STORAGE_KEY } from "../../../Common/constants";

const initialState = {
  selectedPlayers: [{}, {}, {}, {}, {}, {}, {}],
  selectedView: "list",
  showPlayerList: false,
  selectedTeam: 0,
  selectedFilters: [],
  teamName: "",
};

const autoPilotCaptain = (playerid, drivers, player) => {
  const points = (points) => (isNaN(Number(points)) ? 0 : Number(points));
  const teamPlayers = playerid
    .sort((a, b) => a.playerpostion - b.playerpostion)
    .map((player) => {
      const isSwappedInPlayer = player?.isSwappedInPlayer;
      const isSwappedOutPlayer = player?.isSwappedOutPlayer;
      const swappedRaceCategory = player?.swappedRaceCategory;
      const SessionWisePoints = player?.SessionWisePoints;

      const swappedSessionIndex = getSessionIndex(
        SessionWisePoints,
        swappedRaceCategory
      );
      let swapPoints = null;

      if (isSwappedInPlayer || isSwappedOutPlayer) {
        swapPoints = getSwappedPlayerPoints({
          SessionWisePoints: SessionWisePoints,
          playerSessionIndex: swappedSessionIndex,
          playerType: isSwappedInPlayer ? "NEW" : "OLD",
          isCaptainReplaced: false,
          isNoNegative: false,
          isExtraTurboDriver: false,
        });
      }

      return {
        ...drivers.find((x) => x.PlayerId === player.id),
        PlayerId: player?.id,
        isSwappedInPlayer,
        swappedRaceCategory,
        SessionWisePoints,
        swapPoints,
      };
    });
  const driversPlayers = teamPlayers
    .filter((x) => x.Skill === 1)
    .sort((a, b) => {
      let aKey = "GamedayPoints";
      let bKey = "GamedayPoints";

      if (a.isSwappedInPlayer || a.isSwappedOutPlayer) {
        aKey = "swapPoints";
      }
      if (b.isSwappedInPlayer || b.isSwappedOutPlayer) {
        bKey = "swapPoints";
      }

      return points(b?.[bKey]) - points(a?.[aKey]);
    });
  return driversPlayers[0];
};

function removeDuplicateCombinationsInSwapPlayers(arr) {
  if (!arr?.length) {
    return [];
  }
  const seenCombinations = new Set();
  const uniqueArray = [];

  for (const obj of arr) {
    const combination = `${obj?.swapped_in_player}-${obj?.swapped_out_player}`; // Create a unique string

    if (!seenCombinations.has(combination)) {
      seenCombinations.add(combination);
      uniqueArray.push(obj);
    }
  }

  return uniqueArray;
}

export const formatTeamDataV1 = (data, drivers, userGameDaysV1 = null) => {
  const {
    autopilottakengd,
    boosterid,
    capplayerid,
    extradrstakengd,
    extraSubscost,
    finalfixtakengd,
    finalfxnewplayerid,
    finalfxoldplayerid,
    finalfxracecat,
    fttourgdid,
    fttourmdid,
    gdpoints,
    gdrank,
    is_wildcard_taken_gd_id,
    isautopilottaken,
    isaccounting,
    isboostertaken,
    isextradrstaken,
    isfinalfixtaken,
    islimitlesstaken,
    islateonboard,
    isnonigativetaken,
    iswildcard,
    iswildcardtaken,
    limitlesstakengd,
    matchday,
    maxteambal,
    mgcapplayerid,
    nonigativetakengd,
    ovpoints,
    ovrank,
    playerid,
    race_category,
    subsallowed,
    teambal,
    teamno,
    teamname,
    teamval,
    usersubs,
    usersubsleft,
    wildcardtakengd,
    team_info,
    extrasubscost,
    iswebpurifycalled,
    issystemnameupd,
    webpurifyresponse,
    player_swap_details,
    inactive_driver_penality_points,
  } = data;

  // Check if final fix is applied
  if (playerid?.length > 7) {
    let oldPlayer = playerid.find((player) => player.id == finalfxoldplayerid);
    let newPlayer = playerid.find((player) => player.id == finalfxnewplayerid);
    let tempPosition = 5.1;
    newPlayer.playerpostion = oldPlayer.playerpostion;
    oldPlayer.playerpostion = tempPosition;
  }

  let playersList = [...playerid];

  const uniquePlayerSwapDetails =
    removeDuplicateCombinationsInSwapPlayers(player_swap_details);

  let tempPosition = 5.2;

  uniquePlayerSwapDetails?.forEach((swapDetail) => {
    const swappedInPlayer = swapDetail?.swapped_in_player;
    const swappedOutPlayer = swapDetail?.swapped_out_player;
    const swappedRaceCategory = swapDetail?.race_category;

    if (swappedInPlayer && swappedOutPlayer) {
      const swappedInPlayerPosition = playerid?.find(
        (x) => x.id == swappedInPlayer
      )?.playerpostion;
      const swappedInPlayerItem = drivers?.find(
        (x) => x.PlayerId == swappedInPlayer
      );
      const swappedOutPlayerItem = drivers?.find(
        (x) => x.PlayerId == swappedOutPlayer
      );

      playersList = playersList?.filter(
        (player) => player?.id !== swappedInPlayer
      );

      playersList.push({
        ...swappedInPlayerItem,
        id: swappedInPlayer,
        playerpostion: swappedInPlayerPosition,
        isSwappedInPlayer: true,
        swappedRaceCategory,
      });
      playersList.push({
        ...swappedOutPlayerItem,
        id: swappedOutPlayer,
        playerpostion: tempPosition,
        isSwappedOutPlayer: true,
        swappedRaceCategory,
      });
      tempPosition++;
    }
  });

  // check if players are swapped

  const teamPlayers = playersList
    .sort((a, b) => a.playerpostion - b.playerpostion)
    .map((player) => ({
      CountryName: drivers?.find((x) => x.PlayerId === player.id)?.CountryName,
      DisplayName: drivers?.find((x) => x.PlayerId === player.id)?.DisplayName,
      DriverTLA: drivers?.find((x) => x.PlayerId === player.id)?.DriverTLA,
      FUllName: drivers?.find((x) => x.PlayerId === player.id)?.FUllName,
      IsActive: drivers?.find((x) => x.PlayerId === player.id)?.IsActive,
      PlayerId: player?.id,
      PositionName: drivers?.find((x) => x.PlayerId === player.id)
        ?.PositionName,
      Skill: drivers?.find((x) => x.PlayerId === player.id)?.Skill,
      Status: drivers?.find((x) => x.PlayerId === player.id)?.Status,
      TeamId: drivers?.find((x) => x.PlayerId === player.id)?.TeamId,
      TeamName: drivers?.find((x) => x.PlayerId === player.id)?.TeamName,
      Value: drivers?.find((x) => x.PlayerId === player.id)?.Value,
      isCaptain:
        boosterid === 4 &&
        (userGameDaysV1?.[teamno]?.mddetails?.[autopilottakengd]?.mds === 3 ||
          userGameDaysV1?.[teamno]?.mdDetails?.[autopilottakengd]?.mds === 3 ||
          (userGameDaysV1?.[teamno]?.islastday &&
            autopilottakengd === userGameDaysV1?.[teamno]?.lastdaygdid))
          ? autoPilotCaptain(playerid, drivers, player)?.PlayerId === player.id
          : drivers?.find((x) => x.PlayerId == capplayerid)?.PlayerId ===
            player.id,
      isExtraDRS:
        drivers?.find((x) => x.PlayerId == mgcapplayerid)?.PlayerId ===
        player.id,
      ...drivers?.find((x) => x.PlayerId === player.id),
      isFinalFixOldPlayer: player.id == finalfxoldplayerid,
      isFinalFixNewPlayer: player.id == finalfxnewplayerid,
      isSwappedOutPlayer: player?.isSwappedOutPlayer,
      isSwappedInPlayer: player?.isSwappedInPlayer,
      PlayerPosition: player?.playerpostion,
      swappedRaceCategory: player?.swappedRaceCategory,
    }));

  const captain = teamPlayers?.find((x) => x.isCaptain);
  const extraDRS = drivers?.find((x) => x.PlayerId == mgcapplayerid);
  const budget = team_info?.teamBal;
  return {
    team: teamPlayers,
    selectedCaptain: captain,
    selectedExtraDRS: extraDRS,
    budget,
    boosterid,
    gdpoints,
    gdrank,
    isaccounting,
    isboostertaken,
    iswildcard,
    matchday,
    maxteambal,
    ovpoints,
    ovrank,
    race_category,
    teamno,
    teamname: decodeName(teamname),
    teamval,
    usersubs,
    usersubsleft,
    autopilottakengd,
    extradrstakengd,
    finalfixtakengd,
    fttourgdid,
    fttourmdid,
    is_wildcard_taken_gd_id,
    isautopilottaken,
    isextradrstaken,
    isfinalfixtaken,
    islimitlesstaken,
    islateonboard,
    isnonigativetaken,
    iswildcardtaken,
    limitlesstakengd,
    nonigativetakengd,
    wildcardtakengd,
    subsallowed,
    extraSubscost,
    team_info,
    extrasubscost,
    finalfxnewplayerid,
    finalfxoldplayerid,
    finalfxracecat,
    iswebpurifycalled,
    issystemnameupd,
    webpurifyresponse,
    playerid,
    playerSwapDetails: uniquePlayerSwapDetails,
    inactive_driver_penality_points,
  };
};

const formatTeamData = (data, drivers, userGameDays = null) => {
  const {
    autopilottakengd,
    boosterid,
    capplayerid,
    extradrstakengd,
    extraSubscost,
    finalfixtakengd,
    finalfxnewplayerid,
    finalfxoldplayerid,
    finalfxracecat,
    fttourgdid,
    fttourmdid,
    gdpoints,
    gdrank,
    is_wildcard_taken_gd_id,
    isautopilottaken,
    isaccounting,
    isboostertaken,
    isextradrstaken,
    isfinalfixtaken,
    islimitlesstaken,
    islateonboard,
    isnonigativetaken,
    iswildcard,
    iswildcardtaken,
    limitlesstakengd,
    matchday,
    maxteambal,
    mgcapplayerid,
    nonigativetakengd,
    ovpoints,
    ovrank,
    playerid,
    race_category,
    subsallowed,
    teambal,
    teamno,
    teamname,
    teamval,
    usersubs,
    usersubsleft,
    wildcardtakengd,
    team_info,
    extrasubscost,
    iswebpurifycalled,
    issystemnameupd,
    webpurifyresponse,
  } = data;

  // Check if final fix is applied
  if (playerid?.length > 7) {
    let oldPlayer = playerid.find((player) => player.id == finalfxoldplayerid);
    let newPlayer = playerid.find((player) => player.id == finalfxnewplayerid);
    let tempPosition = 5.1;
    newPlayer.playerpostion = oldPlayer.playerpostion;
    oldPlayer.playerpostion = tempPosition;
  }

  const teamPlayers = playerid
    .sort((a, b) => a.playerpostion - b.playerpostion)
    .map((player) => ({
      CountryName: drivers?.find((x) => x.PlayerId === player.id)?.CountryName,
      DisplayName: drivers?.find((x) => x.PlayerId === player.id)?.DisplayName,
      DriverTLA: drivers?.find((x) => x.PlayerId === player.id)?.DriverTLA,
      FUllName: drivers?.find((x) => x.PlayerId === player.id)?.FUllName,
      IsActive: drivers?.find((x) => x.PlayerId === player.id)?.IsActive,
      PlayerId: player?.id,
      PositionName: drivers?.find((x) => x.PlayerId === player.id)
        ?.PositionName,
      Skill: drivers?.find((x) => x.PlayerId === player.id)?.Skill,
      Status: drivers?.find((x) => x.PlayerId === player.id)?.Status,
      TeamId: drivers?.find((x) => x.PlayerId === player.id)?.TeamId,
      TeamName: drivers?.find((x) => x.PlayerId === player.id)?.TeamName,
      Value: drivers?.find((x) => x.PlayerId === player.id)?.Value,
      isCaptain:
        boosterid === 4 &&
        (userGameDays?.[teamno]?.mdDetails?.[autopilottakengd]?.mds === 3 ||
          (userGameDays?.[teamno]?.isLastday &&
            autopilottakengd === userGameDays?.[teamno]?.lastDaygdid))
          ? autoPilotCaptain(playerid, drivers)?.PlayerId === player.id
          : drivers?.find((x) => x.PlayerId == capplayerid)?.PlayerId ===
            player.id,
      isExtraDRS:
        drivers?.find((x) => x.PlayerId == mgcapplayerid)?.PlayerId ===
        player.id,
      ...drivers?.find((x) => x.PlayerId === player.id),
      isFinalFixOldPlayer: player.id == finalfxoldplayerid,
      isFinalFixNewPlayer: player.id == finalfxnewplayerid,
    }));
  const captain = teamPlayers?.find((x) => x.isCaptain);
  const extraDRS = drivers?.find((x) => x.PlayerId == mgcapplayerid);
  const budget = team_info?.teamBal;
  return {
    team: teamPlayers,
    selectedCaptain: captain,
    selectedExtraDRS: extraDRS,
    budget,
    boosterid,
    gdpoints,
    gdrank,
    isaccounting,
    isboostertaken,
    iswildcard,
    matchday,
    maxteambal,
    ovpoints,
    ovrank,
    race_category,
    teamno,
    teamname: decodeName(teamname),
    teamval,
    usersubs,
    usersubsleft,
    autopilottakengd,
    extradrstakengd,
    finalfixtakengd,
    fttourgdid,
    fttourmdid,
    is_wildcard_taken_gd_id,
    isautopilottaken,
    isextradrstaken,
    isfinalfixtaken,
    islimitlesstaken,
    islateonboard,
    isnonigativetaken,
    iswildcardtaken,
    limitlesstakengd,
    nonigativetakengd,
    wildcardtakengd,
    subsallowed,
    extraSubscost,
    team_info,
    extrasubscost,
    finalfxnewplayerid,
    finalfxoldplayerid,
    finalfxracecat,
    iswebpurifycalled,
    issystemnameupd,
    webpurifyresponse,
  };
};

const gameplay = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEAM_PENDING:
      return { ...state, loading: true, error: false, success: false };
    case FETCH_TEAM_REJECTED:
      return { ...state, loading: false, error: true, success: false };
    case FETCH_TEAM_FULFILLED:
      let updatedFetchTeamState = {};
      if (
        (state?.selectedTeam !== undefined || state?.selectedTeam !== null) &&
        action.payload.data?.userTeam &&
        action.payload.drivers
      ) {
        const teamData = action.payload.data?.userTeam[state?.selectedTeam];
        // if (teamData) {
        //   const selectedTeamData = formatTeamData(
        //     teamData,
        //     action.payload.drivers
        //   );
        //   updatedFetchTeamState["selectedPlayers"] = selectedTeamData?.team;
        //   updatedFetchTeamState["selectedTurboDriver"] =
        //     selectedTeamData?.selectedCaptain;

        //   updatedFetchTeamState["selectedExtraTurboDriver"] =
        //     selectedTeamData?.selectedExtraDRS || undefined;

        //   updatedFetchTeamState["budget"] = selectedTeamData?.budget;
        //   updatedFetchTeamState["teamName"] = decodeURIComponent(
        //     selectedTeamData?.teamname.replace(/\+/g, " ")
        //   );
        // }
        const teams = [];
        action.payload.data?.userTeam
          ?.sort((a, b) => a.teamno - b.teamno)
          ?.forEach((element) => {
            if (element) {
              teams.push(
                formatTeamDataV1(
                  element,
                  action.payload.drivers,
                  state.userGameDaysV1
                )
              );
            }
          });
        updatedFetchTeamState["teams"] = teams;
        // let fetchTeamSelectedBoosters = state?.selectedBooster || [];
        let fetchTeamSelectedBoosters = [];
        for (let index = 0; index < teams.length; index++) {
          const element = teams[index];
          if (element.boosterid) {
            const booster = state?.boosters?.Value?.find(
              (x) => x.BoosterId === element.boosterid
            );
            fetchTeamSelectedBoosters[index] = formatBoosterData(booster);
          }
        }
        updatedFetchTeamState["selectedBooster"] = fetchTeamSelectedBoosters;
      }

      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        ...updatedFetchTeamState,
        mdid: action.payload.data.mdid,
      };
    case FETCH_TEAM_FOR_RACEWEEK_PENDING:
      return {
        ...state,
        fetchTeamRaceweekLoading: true,
        fetchTeamRaceweekError: false,
        fetchTeamRaceweekSuccess: false,
      };
    case FETCH_TEAM_FOR_RACEWEEK_REJECTED:
      return {
        ...state,
        fetchTeamRaceweekLoading: false,
        fetchTeamRaceweekError: true,
        fetchTeamRaceweekSuccess: false,
      };
    case FETCH_TEAM_FOR_RACEWEEK_FULFILLED:
      let updatedFetchTeamRaceweekState = {};
      if (
        (state?.selectedRaceweekTeam !== undefined ||
          state?.selectedRaceweekTeam !== null) &&
        action.payload.data?.userTeam &&
        action.payload.drivers
      ) {
        const teamData =
          action.payload.data?.userTeam[state?.selectedRaceweekTeam];
        // if (teamData) {
        //   const selectedTeamData = formatTeamData(
        //     teamData,
        //     action.payload.drivers
        //   );
        //   updatedFetchTeamState["selectedPlayers"] = selectedTeamData?.team;
        //   updatedFetchTeamState["selectedTurboDriver"] =
        //     selectedTeamData?.selectedCaptain;

        //   updatedFetchTeamState["selectedExtraTurboDriver"] =
        //     selectedTeamData?.selectedExtraDRS || undefined;

        //   updatedFetchTeamState["budget"] = selectedTeamData?.budget;
        //   updatedFetchTeamState["teamName"] = decodeURIComponent(
        //     selectedTeamData?.teamname.replace(/\+/g, " ")
        //   );
        // }
        const raceweekTeams = [];
        action.payload.data?.userTeam
          ?.sort((a, b) => a.teamno - b.teamno)
          ?.forEach((element) => {
            if (element) {
              raceweekTeams.push(
                formatTeamDataV1(
                  element,
                  action.payload.drivers,
                  state.userGameDaysV1
                )
              );
            }
          });
        updatedFetchTeamRaceweekState["raceweekTeams"] = raceweekTeams;
        // let fetchTeamSelectedBoosters = state?.selectedBooster || [];
        let fetchRaceweekTeamSelectedBoosters = [];
        for (let index = 0; index < raceweekTeams.length; index++) {
          const element = raceweekTeams[index];
          if (element.boosterid) {
            const booster = state?.boosters?.Value?.find(
              (x) => x.BoosterId === element.boosterid
            );
            fetchRaceweekTeamSelectedBoosters[index] =
              formatBoosterData(booster);
          }
        }
        updatedFetchTeamRaceweekState["raceweekSelectedBooster"] =
          fetchRaceweekTeamSelectedBoosters;
      }

      return {
        ...state,
        fetchTeamRaceweekLoading: false,
        fetchTeamRaceweekError: false,
        fetchTeamRaceweekSuccess: true,
        ...updatedFetchTeamRaceweekState,
        raceweekMdid: action.payload.data.mdid,
      };
    case SET_SELECTED_TAB:
      return {
        ...state,
        selectedTab: action.payload,
      };
    case TOGGLE_SELECTED_PLAYER:
      const selectedPlayerIndex = state?.selectedPlayers?.findIndex(
        (player) => player.PlayerId === action.payload?.player.PlayerId
      );
      const isPlayerSelected = selectedPlayerIndex > -1;

      let players = state?.selectedPlayers
        ? [...state?.selectedPlayers]
        : [{}, {}, {}, {}, {}, {}, {}];
      let budget = state.budget;
      let index = action.payload.index
        ? action.payload.index
        : state.selectedIndex
        ? state.selectedIndex
        : players.findIndex((x) => !Object.keys(x).length);

      if (action.payload.player.Skill === 2) {
        index =
          state.selectedIndex && state.selectedIndex > 4
            ? state.selectedIndex
            : players && Object.keys(players[5]).length
            ? 6
            : 5;
      }

      if (action.payload.player.Skill === 1 && state.selectedIndex > 4) {
        index = state?.selectedPlayers?.findIndex((x) => !x.PlayerId);
      }
      if (
        isPlayerSelected &&
        players[selectedPlayerIndex].PlayerId ===
          state?.selectedTurboDriver?.PlayerId
      ) {
        state.selectedTurboDriver = null;
      }
      if (
        isPlayerSelected &&
        players[selectedPlayerIndex].PlayerId ===
          state?.selectedExtraTurboDriver?.PlayerId
      ) {
        state.selectedExtraTurboDriver = null;
      }

      if (
        !isPlayerSelected &&
        Array.isArray(state?.teams) &&
        state?.teams[state.selectedTeam]?.selectedCaptain?.PlayerId ===
          action.payload?.player.PlayerId
      ) {
        state.selectedTurboDriver = action.payload?.player;
      }
      if (
        !isPlayerSelected &&
        Array.isArray(state?.teams) &&
        state?.teams[state.selectedTeam]?.selectedExtraDRS?.PlayerId ===
          action.payload?.player.PlayerId
      ) {
        state.selectedExtraTurboDriver = action.payload?.player;
      }

      if (
        isPlayerSelected &&
        players[selectedPlayerIndex].PlayerId ===
          state?.selectedExtraTurboDriver?.PlayerId
      ) {
        state.selectedExtraTurboDriver = null;
      }

      // if (state?.selectedTeam > -1 && state?.teams) {
      //   const alreadySelectedPlayerIndex = state?.teams[
      //     state?.selectedTeam
      //   ]?.team?.findIndex((x) => x.isCaptain);
      //   if (
      //     alreadySelectedPlayerIndex != undefined &&
      //     alreadySelectedPlayerIndex !== -1
      //   ) {
      //     state.teams[state.selectedTeam].team[
      //       alreadySelectedPlayerIndex
      //     ].isCaptain = false;
      //   }
      // }

      if (isPlayerSelected) {
        budget = budget + players[selectedPlayerIndex].Value;
        players.splice(selectedPlayerIndex, 1, {});
      } else {
        budget = budget - action.payload?.player.Value;
        budget = Number(budget.toFixed(1));
        players[index] = action.payload.player;
      }

      return {
        ...state,
        selectedPlayers: players,
        budget,
        selectedIndex: null,
        selectedTurboDriver: state.selectedTurboDriver,
      };
    case RESET_SELECTED_PLAYERS:
      if (action?.payload === "update") {
        return {
          ...state,
          selectedPlayers: state.teams[state.selectedTeam].team,
          selectedTurboDriver: state.teams[state.selectedTeam].selectedCaptain,
          budget: state.teams[state.selectedTeam].budget,
        };
      } else {
        return {
          ...state,
          selectedPlayers: [{}, {}, {}, {}, {}, {}, {}],
          budget: 100,
          selectedTurboDriver: null,
          teamName: "",
          tempTurboDriver: undefined,
          selectedExtraTurboDriver: undefined,
          tempExtraTurboDriver: undefined,
        };
      }
    case SET_SELECTED_INDEX:
      return {
        ...state,
        selectedIndex: action.payload,
      };
    case SET_BUDGET:
      return {
        ...state,
        budget: action.payload,
      };
    case SET_SELECTED_VIEW:
      const view = state.selectedView === "list" ? "grid" : "list";
      return {
        ...state,
        selectedView: view,
      };
    case TOGGLE_PLAYER_LIST:
      return {
        ...state,
        showPlayerList: !state.showPlayerList,
      };
    case TOGGLE_TURBO_DRIVER_POPUP:
      return {
        ...state,
        showTurboDriverPopup: !state.showTurboDriverPopup,
      };
    case TOGGLE_TEAM_NAME_POPUP:
      return {
        ...state,
        showTeamNamePopup: !state.showTeamNamePopup,
      };
    case TOGGLE_PROFILE_POPUP:
      return {
        ...state,
        showProfilePopup: !state.showProfilePopup,
      };
    case TOGGLE_TRANSFER_POPUP:
      return {
        ...state,
        showTransferPopup: !state.showTransferPopup,
      };
    case TOGGLE_CHIPS_POPUP:
      return {
        ...state,
        showSelectChipsPopup: !state.showSelectChipsPopup,
      };
    case TOGGLE_FILTER_POPUP:
      return {
        ...state,
        showFilterPopup: !state.showFilterPopup,
      };
    case TOGGLE_ALERT_POPUP:
      return {
        ...state,
        showAlertPopup: !state.showAlertPopup,
      };
    case RESET_ALERT_POPUP:
      return {
        ...state,
        showAlertPopup: false,
      };
    case TOGGLE_CIRCUIT_POPUP:
      return {
        ...state,
        showCircuitPopup: !state.showCircuitPopup,
      };
    case TOGGLE_CONFIRM_CHIPS_POPUP:
      return {
        ...state,
        showSelectChipsConfirmPopup: !state.showSelectChipsConfirmPopup,
      };
    case TOGGLE_PLAYER_POPUP:
      return {
        ...state,
        showPlayerPopup: !state.showPlayerPopup,
        popupPlayer: action.payload,
      };
    case TOGGLE_EXTRA_TURBO_DRIVER_POPUP:
      return {
        ...state,
        showExtraTurboDriverPopup: !state.showExtraTurboDriverPopup,
      };
    case SET_TURBO_DRIVER:
      return {
        ...state,
        selectedTurboDriver: state.tempTurboDriver,
        selectedExtraTurboDriver:
          state.tempTurboDriver?.PlayerId ===
          state.selectedExtraTurboDriver?.PlayerId
            ? null
            : state.selectedExtraTurboDriver,
        tempTurboDriver: null,
      };
    case SET_TEMP_TURBO_DRIVER:
      return {
        ...state,
        tempTurboDriver: action.payload,
      };
    case SET_EXTRA_TURBO_DRIVER:
      return {
        ...state,
        selectedExtraTurboDriver: state.tempExtraTurboDriver,
        selectedTurboDriver:
          state.tempExtraTurboDriver?.PlayerId ===
          state.selectedTurboDriver?.PlayerId
            ? null
            : state.selectedTurboDriver,
        tempExtraTurboDriver: null,
      };
    case SET_TEMP_EXTRA_TURBO_DRIVER:
      return {
        ...state,
        tempExtraTurboDriver: action.payload,
      };
    case SET_TEAM_NAME:
      return {
        ...state,
        teamName: action.payload,
      };
    case SET_PROFILE_DATA:
      return {
        ...state,
        profileData: action.payload,
      };
    case SAVE_TEAM_PENDING:
      return {
        ...state,
        saveLoading: true,
        saveError: false,
        saveSuccess: false,
      };
    case SAVE_TEAM_REJECTED:
      return {
        ...state,
        saveLoading: false,
        saveError: true,
        saveSuccess: false,
        saveErrorData: action.payload.data,
      };
    case SAVE_TEAM_FULFILLED:
      return {
        ...state,
        saveLoading: false,
        saveError: false,
        saveSuccess: true,
        savedTeam: action.payload.data,
        saveErrorData: null,
        teamName: "",
      };
    case SAVE_TEAM_RESET:
      return {
        ...state,
        saveLoading: false,
        saveError: false,
        saveSuccess: false,
        saveErrorData: null,
        // teamName: "",
      };
    case TRANSFER_TEAM_PENDING:
      return {
        ...state,
        transferLoading: true,
        transferError: false,
        transferSuccess: false,
      };
    case TRANSFER_TEAM_REJECTED:
      return {
        ...state,
        transferLoading: false,
        transferError: true,
        transferSuccess: false,
        transferErrorData: action.payload.data,
      };
    case TRANSFER_TEAM_FULFILLED:
      return {
        ...state,
        transferLoading: false,
        transferError: false,
        transferSuccess: true,
        // teams: action.payload.data,
      };
    case TRANSFER_TEAM_RESET:
      return {
        ...state,
        transferLoading: false,
        transferError: false,
        transferSuccess: false,
        transferErrorData: undefined,
      };
    case UPDATE_CAPTAIN_PENDING:
      return {
        ...state,
        updateCaptainLoading: true,
        updateCaptainError: false,
        updateCaptainSuccess: false,
      };
    case UPDATE_CAPTAIN_REJECTED:
      return {
        ...state,
        updateCaptainLoading: false,
        updateCaptainError: true,
        updateCaptainSuccess: false,
        updateCaptainErrorData: action.payload.data,
      };
    case UPDATE_CAPTAIN_FULFILLED:
      return {
        ...state,
        updateCaptainLoading: false,
        updateCaptainError: false,
        updateCaptainSuccess: true,
        // teams: action.payload.data,
      };
    case RESET_UPDATE_CAPTAIN:
      return {
        ...state,
        updateCaptainLoading: false,
        updateCaptainError: false,
        updateCaptainSuccess: false,
        updateCaptainErrorData: undefined,
        // teams: action.payload.data,
      };
    case SET_SELECTED_TEAM:
      let updatedSelectedTeamState = {};
      if (!action.payload) {
        updatedSelectedTeamState["selectedPlayers"] =
          initialState?.selectedPlayers;
        updatedSelectedTeamState["selectedTurboDriver"] = null;

        updatedSelectedTeamState["selectedExtraTurboDriver"] = undefined;

        updatedSelectedTeamState["budget"] = 100;
        updatedSelectedTeamState["teamName"] = "";
        updatedSelectedTeamState["selectedTeam"] = undefined;
      } else {
        const teamData = state?.teams && state?.teams[action.payload?.teamNo];
        if (teamData) {
          const selectedTeamData = teamData;
          updatedSelectedTeamState["selectedPlayers"] = selectedTeamData?.team;
          updatedSelectedTeamState["selectedTurboDriver"] =
            selectedTeamData?.selectedCaptain;

          updatedSelectedTeamState["selectedExtraTurboDriver"] =
            selectedTeamData?.selectedExtraDRS || undefined;

          updatedSelectedTeamState["budget"] = selectedTeamData?.budget;
          updatedSelectedTeamState["teamName"] = decodeName(
            selectedTeamData?.teamname
          );
          updatedSelectedTeamState["selectedTeam"] = action.payload?.teamNo;
        }
      }
      return {
        ...state,
        ...updatedSelectedTeamState,
      };
    case SET_SELECTED_BOOSTER:
      let boosterArray = [];
      let otherBoosterData = {};
      if (state?.selectedBooster?.length) {
        if (
          state?.selectedBooster[action.payload.team]?.id === 6 &&
          !state.tempBooster
        ) {
          otherBoosterData["tempExtraTurboDriver"] = undefined;
          otherBoosterData["selectedExtraTurboDriver"] = undefined;
        }
        if (
          state?.selectedBooster[action.payload.team]?.id === 3 &&
          !state.tempBooster
        ) {
          otherBoosterData["replacedPlayer"] = undefined;
        }
        boosterArray = JSON.parse(JSON.stringify(state.selectedBooster));
        boosterArray[action.payload.team] = state.tempBooster;
      } else {
        boosterArray[action.payload.team] = state.tempBooster;
      }
      return {
        ...state,
        selectedBooster: boosterArray,
        tempBooster: null,
        ...otherBoosterData,
      };
    case RESET_SELECTED_BOOSTER:
      const newSelectedBoosters = [...state.selectedBooster];
      newSelectedBoosters[action.payload?.team] = null;
      return {
        ...state,
        selectedBooster: newSelectedBoosters,
      };
    case SET_TEMP_BOOSTER:
      return {
        ...state,
        tempBooster: action.payload,
      };
    case RESET_TEMP_BOOSTER:
      return {
        ...state,
        tempBooster: null,
      };
    case SET_FILTERS:
      let setNewFilters = {};
      if (action.payload.min !== undefined) {
        setNewFilters["minPrice"] = action.payload.min;
      }
      if (action.payload.max !== undefined) {
        setNewFilters["maxPrice"] = action.payload.max;
      }
      if (action.payload.teams !== undefined) {
        setNewFilters["teams"] = [...action.payload.teams];
      }
      if (action.payload.affordable !== undefined) {
        setNewFilters["affordable"] = action.payload.affordable;
      }
      return {
        ...state,
        selectedFilters: setNewFilters,
      };
    case RESET_GO_BACK:
      return {
        ...state,
        appliedFilters: undefined,
        selectedFilters: {},
      };
    case RESET_FILTERS:
      return {
        ...state,
        selectedFilters: {},
      };
    case APPLY_FILTERS:
      return {
        ...state,
        appliedFilters: [
          {
            key: "price",
            min: state.selectedFilters.minPrice,
            max: state.selectedFilters.maxPrice,
          },
          {
            key: "team",
            teams: JSON.parse(JSON.stringify(state.selectedFilters.teams)),
          },
          {
            key: "affordable",
            onlyAffordable: state.selectedFilters.affordable,
          },
        ],
      };
    case GET_USER_GAME_DAYS_PENDING:
      return {
        ...state,
        userGameDaysLoading: true,
        userGameDaysError: false,
        userGameDaysSuccess: false,
      };
    case GET_USER_GAME_DAYS_REJECTED:
      return {
        ...state,
        userGameDaysLoading: false,
        userGameDaysError: true,
        userGameDaysSuccess: false,
        userGameDaysErrorData: action.payload.data,
      };
    case GET_USER_GAME_DAYS_FULFILLED:
      let userGameDays;
      if (state?.userGameDays) {
        userGameDays = {
          ...state.userGameDays,
          [action.payload.teamNo]: action.payload.data,
        };
      } else {
        userGameDays = { [action.payload.teamNo]: action.payload.data };
      }
      return {
        ...state,
        userGameDaysLoading: false,
        userGameDaysError: false,
        userGameDaysSuccess: true,
        userGameDays: userGameDays,
      };
    case GET_USER_GAME_DAYS_V1_PENDING:
      return {
        ...state,
        userGameDaysV1Loading: true,
        userGameDaysV1Error: false,
        userGameDaysV1Success: false,
      };
    case GET_USER_GAME_DAYS_V1_REJECTED:
      return {
        ...state,
        userGameDaysV1Loading: false,
        userGameDaysV1Error: true,
        userGameDaysV1Success: false,
        userGameDaysV1ErrorData: action.payload.data,
      };
    case GET_USER_GAME_DAYS_V1_FULFILLED:
      return {
        ...state,
        userGameDaysV1Loading: false,
        userGameDaysV1Error: false,
        userGameDaysV1Success: true,
        userGameDaysV1: action.payload.data,
      };
    case FETCH_BOOSTERS_PENDING:
      return {
        ...state,
        fetchBoostersLoading: true,
        fetchBoostersError: false,
        fetchBoostersSuccess: false,
      };
    case FETCH_BOOSTERS_REJECTED:
      return {
        ...state,
        fetchBoostersLoading: false,
        fetchBoostersError: true,
        fetchBoostersSuccess: false,
      };
    case FETCH_BOOSTERS_FULFILLED:
      return {
        ...state,
        fetchBoostersLoading: false,
        fetchBoostersError: false,
        fetchBoostersSuccess: true,
        boosters: action.payload.data,
      };
    case SET_REPLACED_PLAYER:
      return {
        ...state,
        replacedPlayer: action.payload,
      };
    case UPDATE_TEAM_NAME_PENDING:
      return {
        ...state,
        updateTeamNameLoading: true,
        updateTeamNameError: false,
        updateTeamNameSuccess: false,
      };
    case UPDATE_TEAM_NAME_REJECTED:
      return {
        ...state,
        updateTeamNameLoading: false,
        updateTeamNameError: true,
        updateTeamNameSuccess: false,
        updateTeamNameErrorData: action.payload.data,
      };
    case UPDATE_TEAM_NAME_FULFILLED:
      let newUpdateTeamNameState = {};
      newUpdateTeamNameState["teams"] = state.teams;
      newUpdateTeamNameState.teams[state.selectedTeam].teamname =
        state.teamName;
      return {
        ...state,
        ...newUpdateTeamNameState,
        updateTeamNameLoading: false,
        updateTeamNameError: false,
        updateTeamNameSuccess: true,
      };
    case RESET_UPDATE_TEAM_NAME:
      return {
        ...state,
        updateTeamNameLoading: false,
        updateTeamNameError: false,
        updateTeamNameSuccess: false,
        updateTeamNameErrorData: undefined,
        // teamName: "",
      };
    case APPLY_AUTO_PILOT_PENDING:
      return {
        ...state,
        autoPilotLoading: true,
        autoPilotError: false,
        autoPilotSuccess: false,
      };
    case APPLY_AUTO_PILOT_REJECTED:
      return {
        ...state,
        autoPilotLoading: false,
        autoPilotError: true,
        autoPilotSuccess: false,
        autoPilotErrorData: action.payload.data,
      };
    case APPLY_AUTO_PILOT_FULFILLED:
      return {
        ...state,
        autoPilotLoading: false,
        autoPilotError: false,
        autoPilotSuccess: true,
      };
    case RESET_AUTO_PILOT:
      return {
        ...state,
        autoPilotLoading: false,
        autoPilotError: false,
        autoPilotSuccess: false,
        autoPilotErrorData: undefined,
      };
    case APPLY_EXTRA_DRS_PENDING:
      return {
        ...state,
        extraDRSLoading: true,
        extraDRSError: false,
        extraDRSSuccess: false,
      };
    case APPLY_EXTRA_DRS_REJECTED:
      return {
        ...state,
        extraDRSLoading: false,
        extraDRSError: true,
        extraDRSSuccess: false,
        extraDRSErrorData: action.payload.data,
      };
    case APPLY_EXTRA_DRS_FULFILLED:
      return {
        ...state,
        extraDRSLoading: false,
        extraDRSError: false,
        extraDRSSuccess: true,
      };
    case RESET_EXTRA_DRS:
      return {
        ...state,
        extraDRSLoading: false,
        extraDRSError: false,
        extraDRSSuccess: false,
        extraDRSErrorData: undefined,
      };
    case CHANGE_EXTRA_DRS_PENDING:
      return {
        ...state,
        changeExtraDRSLoading: true,
        changeExtraDRSError: false,
        changeExtraDRSSuccess: false,
      };
    case CHANGE_EXTRA_DRS_REJECTED:
      return {
        ...state,
        changeExtraDRSLoading: false,
        changeExtraDRSError: true,
        changeExtraDRSSuccess: false,
        changeExtraDRSErrorData: action.payload.data,
      };
    case CHANGE_EXTRA_DRS_FULFILLED:
      return {
        ...state,
        changeExtraDRSLoading: false,
        changeExtraDRSError: false,
        changeExtraDRSSuccess: true,
      };
    case RESET_CHANGE_EXTRA_DRS:
      return {
        ...state,
        changeExtraDRSLoading: false,
        changeExtraDRSError: false,
        changeExtraDRSSuccess: false,
        changeExtraDRSErrorData: undefined,
      };
    case APPLY_FINAL_FIX_PENDING:
      return {
        ...state,
        finalFixLoading: true,
        finalFixError: false,
        finalFixSuccess: false,
      };
    case APPLY_FINAL_FIX_REJECTED:
      return {
        ...state,
        finalFixLoading: false,
        finalFixError: true,
        finalFixSuccess: false,
        finalFixErrorData: action.payload.data,
      };
    case APPLY_FINAL_FIX_FULFILLED:
      return {
        ...state,
        finalFixLoading: false,
        finalFixError: false,
        finalFixSuccess: true,
        replacedPlayer: undefined,
      };
    case RESET_FINAL_FIX:
      return {
        ...state,
        finalFixLoading: false,
        finalFixError: false,
        finalFixSuccess: false,
        finalFixErrorData: undefined,
      };
    case APPLY_LIMITLESS_PENDING:
      return {
        ...state,
        limitlessLoading: true,
        limitlessError: false,
        limitlessSuccess: false,
      };
    case APPLY_LIMITLESS_REJECTED:
      return {
        ...state,
        limitlessLoading: false,
        limitlessError: true,
        limitlessSuccess: false,
        limitlessErrorData: action.payload.data,
      };
    case APPLY_LIMITLESS_FULFILLED:
      return {
        ...state,
        limitlessLoading: false,
        limitlessError: false,
        limitlessSuccess: true,
      };
    case RESET_LIMITLESS:
      return {
        ...state,
        limitlessLoading: false,
        limitlessError: false,
        limitlessSuccess: false,
        limitlessErrorData: undefined,
      };
    case APPLY_NON_NEGATIVE_PENDING:
      return {
        ...state,
        nonNegativeLoading: true,
        nonNegativeError: false,
        nonNegativeSuccess: false,
      };
    case APPLY_NON_NEGATIVE_REJECTED:
      return {
        ...state,
        nonNegativeLoading: false,
        nonNegativeError: true,
        nonNegativeSuccess: false,
        nonNegativeErrorData: action.payload.data,
      };
    case APPLY_NON_NEGATIVE_FULFILLED:
      return {
        ...state,
        nonNegativeLoading: false,
        nonNegativeError: false,
        nonNegativeSuccess: true,
      };
    case RESET_NON_NEGATIVE:
      return {
        ...state,
        nonNegativeLoading: false,
        nonNegativeError: false,
        nonNegativeSuccess: true,
        nonNegativeErrorData: undefined,
      };
    case FETCH_DETAILED_TEAM_PENDING:
      return {
        ...state,
        fetchDetailedTeamLoading: true,
        fetchDetailedTeamError: false,
        fetchDetailedTeamSuccess: false,
      };
    case FETCH_DETAILED_TEAM_REJECTED:
      return {
        ...state,
        fetchDetailedTeamLoading: false,
        fetchDetailedTeamError: true,
        fetchBoostersSuccess: false,
      };
    case FETCH_DETAILED_TEAM_FULFILLED:
      let updatedDetailedFetchTeamState = {};
      if (
        (state?.selectedTeam !== undefined || state?.selectedTeam !== null) &&
        action.payload.data?.userTeam
      ) {
        const detailedTeams1 = action.payload.data?.userTeam?.sort(
          (a, b) => a.teamno - b.teamno
        );
        const teamData = detailedTeams1[action.payload?.selectedTeam];
        const selectedTeamData = formatTeamDataV1(
          teamData,
          action.payload.drivers,
          state.userGameDaysV1
        );
        updatedDetailedFetchTeamState["selectedDetailedPlayers"] =
          selectedTeamData?.team;
        updatedDetailedFetchTeamState["selectedDetailedTurboDriver"] =
          selectedTeamData?.selectedCaptain;

        updatedDetailedFetchTeamState["selectedDetailedExtraTurboDriver"] =
          selectedTeamData?.selectedExtraDRS || undefined;

        updatedDetailedFetchTeamState["detailedBudget"] =
          selectedTeamData?.budget;
        updatedDetailedFetchTeamState["detailedTeamName"] = decodeName(
          selectedTeamData?.teamname
        );
      }
      const detailedTeams = [];
      action.payload.data?.userTeam?.forEach((element) => {
        detailedTeams.push(
          formatTeamDataV1(
            element,
            action.payload.drivers,
            state.userGameDaysV1
          )
        );
      });
      updatedDetailedFetchTeamState["detailedTeams"] = detailedTeams;
      let fetchDetailedTeamSelectedBoosters = [];
      for (let index = 0; index < detailedTeams.length; index++) {
        const element = detailedTeams[index];
        if (element.boosterid) {
          const booster = state?.boosters?.Value?.find(
            (x) => x.BoosterId === element.boosterid
          );
          fetchDetailedTeamSelectedBoosters[index] = formatBoosterData(booster);
        }
      }
      updatedDetailedFetchTeamState["selectedDetailedBooster"] =
        fetchDetailedTeamSelectedBoosters;
      return {
        ...state,
        fetchDetailedTeamLoading: false,
        fetchDetailedTeamError: false,
        fetchDetailedTeamSuccess: true,
        ...updatedDetailedFetchTeamState,
      };
    case TOGGLE_RACE_WEEK_TAB:
      return {
        ...state,
        selectedRaceWeekTab: action.payload,
      };

    case FETCH_OPP_DETAILED_TEAM_PENDING:
      return {
        ...state,
        fetchOppDetailedTeamLoading: true,
        fetchOppDetailedTeamError: false,
        fetchOppDetailedTeamSuccess: false,
      };
    case FETCH_OPP_DETAILED_TEAM_REJECTED:
      return {
        ...state,
        fetchOppDetailedTeamLoading: false,
        fetchOppDetailedTeamError: true,
        fetchOppDetailedTeamSuccess: false,
      };
    case FETCH_OPP_DETAILED_TEAM_FULFILLED:
      let oppDetailedTeam = {};
      if (
        (state?.selectedTeam !== undefined || state?.selectedTeam !== null) &&
        action.payload.data?.userTeam
      ) {
        const detailedTeams1 = action.payload.data?.userTeam?.sort(
          (a, b) => a.teamno - b.teamno
        );
        const teamData = action.payload.data?.userTeam[0];
        const selectedTeamData = formatTeamDataV1(
          teamData,
          action.payload.drivers,
          state.userGameDaysV1
        );
        oppDetailedTeam["selectedOpponentPlayers"] = selectedTeamData?.team;
        oppDetailedTeam["selectedOpponentTurboDriver"] =
          selectedTeamData?.selectedCaptain;

        oppDetailedTeam["selectedOpponentExtraTurboDriver"] =
          selectedTeamData?.selectedExtraDRS || undefined;

        oppDetailedTeam["opponentBudget"] = selectedTeamData?.budget;
        oppDetailedTeam["opponentTeamName"] = decodeName(
          selectedTeamData?.teamname
        );
      }
      const oppDetailedTeams = [];
      action.payload.data?.userTeam?.forEach((element) => {
        oppDetailedTeams.push(
          formatTeamDataV1(element, action.payload.drivers, {
            [element?.teamno]: state.oppUserGameDays,
          })
        );
      });
      oppDetailedTeam["oppDetailedTeams"] = oppDetailedTeams;
      let fetchOppDetailedTeamSelectedBoosters = [];
      for (let index = 0; index < oppDetailedTeams.length; index++) {
        const element = oppDetailedTeams[index];
        if (element?.boosterid) {
          const booster = state?.boosters?.Value?.find(
            (x) => x.BoosterId === element.boosterid
          );
          fetchOppDetailedTeamSelectedBoosters[index] =
            formatBoosterData(booster);
        }
      }
      oppDetailedTeam["selectedOpponentBooster"] =
        fetchOppDetailedTeamSelectedBoosters;
      return {
        ...state,
        fetchOppDetailedTeamLoading: false,
        fetchOppDetailedTeamError: false,
        fetchOppDetailedTeamSuccess: true,
        ...oppDetailedTeam,
      };
    case DISCARD_TRANSFERS:
      let discardedTransferState = {};
      discardedTransferState["selectedPlayers"] =
        state?.teams?.[state?.selectedTeam]?.team;
      return {
        ...state,
        ...discardedTransferState,
      };
    case FETCH_OPP_USER_GAME_DAYS_PENDING:
      return {
        ...state,
        oppUserGameDaysLoading: true,
        oppUppUserGameDaysError: false,
        oppUserGameDaysSuccess: false,
      };
    case FETCH_OPP_USER_GAME_DAYS_REJECTED:
      return {
        ...state,
        oppUserGameDaysLoading: false,
        oppUppUserGameDaysError: true,
        oppUserGameDaysSuccess: false,
        oppUserGameDaysErrorData: action.payload.data,
      };
    case FETCH_OPP_USER_GAME_DAYS_FULFILLED:
      return {
        ...state,
        oppUserGameDaysLoading: false,
        oppUserGameDaysError: false,
        oppUserGameDaysSuccess: true,
        oppUserGameDays: action.payload.data,
      };
    case RESET_OPP_TEAM:
      return {
        ...state,
        oppUserGameDaysLoading: false,
        oppUserGameDaysError: false,
        oppUserGameDaysSuccess: true,
        oppUserGameDays: undefined,
        fetchOppDetailedTeamLoading: false,
        fetchOppDetailedTeamError: false,
        fetchOppDetailedTeamSuccess: false,
        selectedOpponentPlayers: undefined,
        selectedOpponentTurboDriver: undefined,
        selectedOpponentExtraTurboDriver: undefined,
        opponentBudget: undefined,
        opponentTeamName: undefined,
        oppDetailedTeams: undefined,
        selectedOpponentBooster: undefined,
      };
    case FETCH_H2H_OPPONENT_TEAM_PENDING:
      return {
        ...state,
        fetchH2HOppTeamLoading: true,
        fetchH2HOppTeamError: false,
        fetchH2HOppTeamSuccess: false,
      };
    case FETCH_H2H_OPPONENT_TEAM_REJECTED:
      return {
        ...state,
        fetchH2HOppTeamLoading: false,
        fetchH2HOppTeamError: true,
        fetchH2HOppTeamSuccess: false,
      };
    case FETCH_H2H_OPPONENT_TEAM_FULFILLED:
      let h2hOppTeam = {};
      if (
        (state?.selectedTeam !== undefined || state?.selectedTeam !== null) &&
        action.payload.data?.userTeam
      ) {
        const teamData = action.payload.data?.userTeam[0];
        const selectedTeamData = formatTeamDataV1(
          teamData,
          action.payload.drivers,
          state.h2hOppUserGameDays
        );
      }
      const h2hOpponetTeams = [];
      action.payload.data?.userTeam?.forEach((element) => {
        console.log({ element });
        h2hOpponetTeams.push(
          formatTeamDataV1(
            element,
            action.payload.drivers,
            state.h2hOppUserGameDays
          )
        );
      });

      h2hOppTeam["teamDetails"] = h2hOpponetTeams;
      let h2hOpponentTeamBoosters = [];
      for (let index = 0; index < h2hOpponetTeams.length; index++) {
        const element = h2hOpponetTeams[index];
        if (element?.boosterid) {
          const booster = state?.boosters?.Value?.find(
            (x) => x.BoosterId === element.boosterid
          );
          h2hOpponentTeamBoosters[index] = formatBoosterData(booster);
        }
      }

      h2hOppTeam["selectedOpponentBooster"] = h2hOpponentTeamBoosters;
      return {
        ...state,
        fetchH2HOppTeamLoading: false,
        fetchH2HOppTeamError: false,
        fetchH2HOppTeamSuccess: true,
        h2hOppTeam,
      };
    case FETCH_H2H_OPPONENT_USER_GAME_DAYS_PENDING:
      return {
        ...state,
        h2hOppUserGameDaysLoading: true,
        h2hOppUserGameDaysError: false,
        h2hOppUserGameDaysSuccess: false,
      };
    case FETCH_H2H_OPPONENT_USER_GAME_DAYS_REJECTED:
      return {
        ...state,
        h2hOppUserGameDaysLoading: false,
        h2hOppUserGameDaysError: true,
        h2hOppUserGameDaysSuccess: false,
        h2hOppUserGameDaysErrorData: action.payload.data,
      };
    case FETCH_H2H_OPPONENT_USER_GAME_DAYS_FULFILLED:
      return {
        ...state,
        h2hOppUserGameDaysLoading: false,
        h2hOppUserGameDaysError: false,
        h2hOppUserGameDaysSuccess: true,
        h2hOppUserGameDays: {
          [action.payload.params.teamNo]: action.payload.data,
        },
      };
    case RESET_H2H_OPPONENT:
      return {
        ...state,
        fetchH2HOppTeamLoading: false,
        fetchH2HOppTeamError: false,
        fetchH2HOppTeamSuccess: false,
        h2hOppTeam: undefined,
        h2hOppUserGameDaysLoading: false,
        h2hOppUserGameDaysError: false,
        h2hOppUserGameDaysSuccess: false,
        h2hOppUserGameDays: undefined,
      };
    case RESET_GAMEPLAY_PERSISTER: {
      return {
        ...initialState,
        selectedPlayers: state.selectedPlayers,
        budget: state.budget,
        selectedTurboDriver: state.selectedTurboDriver,
        teamName: state.teamName,
      };
    }

    case FETCH_RATING_STATISTICS_PENDING:
      return { ...state, loading: true, error: false, success: false };
    case FETCH_RATING_STATISTICS_REJECTED:
      return { ...state, loading: false, error: true, success: false };
    case FETCH_RATING_STATISTICS_FULFILLED:
      const ratingData = action?.payload?.data?.Value;

      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        ratingStats: ratingData,
      };

    case FETCH_SORTING_STATISTICS_PENDING:
      return { ...state, loading: true, error: false, success: false };
    case FETCH_SORTING_STATISTICS_REJECTED:
      return { ...state, loading: false, error: true, success: false };
    case FETCH_SORTING_STATISTICS_FULFILLED:
      const sortingData = action?.payload?.data?.Value;

      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        sortingStats: sortingData,
      };

    case FETCH_SWAP_SUGGESTIONS_PENDING:
      return {
        ...state,
        swapSuggestionPending: true,
        swapSuggestionRejected: false,
        swapSuggestionFulfilled: false,
        swapSuggestionData: null,
      };
    case FETCH_SWAP_SUGGESTIONS_REJECTED:
      return {
        ...state,
        swapSuggestionPending: false,
        swapSuggestionRejected: true,
        swapSuggestionFulfilled: false,
        swapSuggestionData: null,
      };
    case FETCH_SWAP_SUGGESTIONS_FULFILLED:
      return {
        ...state,
        swapSuggestionPending: false,
        swapSuggestionRejected: false,
        swapSuggestionFulfilled: true,
        swapSuggestionData: action.payload.data,
      };
    case SET_SWAP_SUGGESTION_OVERLAY_DATA:
      const {
        newTeam,
        isTurboDriverSwap,
        isExtraTurboDriverSwap,
        swapInDriver,
      } = action.payload;
      const newSwapSuggestionOverlayObj = {
        selectedPlayers: newTeam,
      };

      if (isTurboDriverSwap) {
        newSwapSuggestionOverlayObj["selectedTurboDriver"] = swapInDriver;
      }
      if (isExtraTurboDriverSwap) {
        newSwapSuggestionOverlayObj["selectedExtraTurboDriver"] = swapInDriver;
      }

      return {
        ...state,
        ...newSwapSuggestionOverlayObj,
      };
    case RESET_SWAP_SUGGESTION_OVERLAY_DATA:
      return {
        ...state,
        selectedPlayers: null,
        selectedTurboDriver: null,
        selectedExtraTurboDriver: null,
      };
    case REPLACE_SWAP_PLAYERS_PENDING:
      return {
        ...state,
        replaceSwapPlayersPending: true,
        replaceSwapPlayersRejected: false,
        replaceSwapPlayersFulfilled: false,
        replaceSwapPlayersData: null,
      };
    case REPLACE_SWAP_PLAYERS_REJECTED:
      return {
        ...state,
        replaceSwapPlayersPending: false,
        replaceSwapPlayersRejected: true,
        replaceSwapPlayersFulfilled: false,
        replaceSwapPlayersData: null,
      };
    case REPLACE_SWAP_PLAYERS_FULFILLED:
      return {
        ...state,
        replaceSwapPlayersPending: false,
        replaceSwapPlayersRejected: false,
        replaceSwapPlayersFulfilled: true,
        replaceSwapPlayersData: action.payload.data,
      };
    case RESET_REPLACE_SWAP_PLAYERS:
      return {
        ...state,
        replaceSwapPlayersPending: false,
        replaceSwapPlayersRejected: false,
        replaceSwapPlayersFulfilled: false,
        replaceSwapPlayersData: null,
      };
    case RESET_EXTRA_TURBO_DRIVER:
      return {
        ...state,
        selectedExtraTurboDriver: null,
      };
    case PAGE_NOT_FOUND:
      return {
        ...state,
        pageNotFound: true,
      };
    case RESET_PAGE_NOT_FOUND:
      return {
        ...state,
        pageNotFound: false,
      };

    default:
      return state;
  }
};

const gameplayPersistConfig = {
  key: "gameplay",
  keyPrefix: "si-persist:",
  storage: storage,
  whitelist: ["selectedPlayers", "budget", "selectedTurboDriver", "teamName"],
};

const gameplayPersistedReducer = persistReducer(
  gameplayPersistConfig,
  gameplay
);

export default gameplayPersistedReducer;
