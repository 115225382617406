import {
  CLOUDINARY_IMAGE_BASE_URL,
  IMAGE_VERSION,
} from "../../../Common/constants";
import useCloudinaryData from "../../../Common/hooks/useCloudinaryData";
import Media from "../../media";

const TrackInfo = ({ circuitLocation }) => {
  const { configData, cloudinaryBase, envVersion, currentSeasonYear } =
    useCloudinaryData();

  const trackInfoImg = configData?.imagePaths?.trackInfoImg;

  const trackInfoImagePath = trackInfoImg
    ?.replaceAll("<cloudinary_base_path>", cloudinaryBase)
    ?.replaceAll("<env_version>", envVersion)
    ?.replaceAll("<current_season_year>", currentSeasonYear)
    ?.replaceAll("<circuit_short_name>", circuitLocation);

  return (
    <Media imgSrc={trackInfoImagePath} imgAlt={circuitLocation} />
    // <img
    //   src={trackInfoImagePath}
    //   alt={circuitLocation}
    //   onError={({ currentTarget }) => {
    //     currentTarget.onerror = null; // prevents looping
    //     currentTarget.src = `${CLOUDINARY_IMAGE_BASE_URL}common/fallback.svg?v=${IMAGE_VERSION}`;
    //   }}
    //   loading="lazy"
    // />
  );
};

export default TrackInfo;
