import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { gameplay } from "../../redux/actions";
import { DriverConstructorInfo, Overlays } from "../../ReusableComponents";
import PlayerButton from "../PlayerCard/PlayerButton";

const PlayerInfo = ({
  hasCTAs = true,
  isOppositeTeam = false,
  selectedGameday = null,
  pushTurboDriverDataLayer = () => {},
}) => {
  const dispatch = useDispatch();
  const gameplayState = useSelector((state) => state.gameplay);

  const { showPlayerPopup, popupPlayer, selectedTurboDriver, boosters } =
    gameplayState;

  const togglePlayerPopup = (player) => {
    dispatch(gameplay.togglePlayerPopup(player));
  };

  return (
    <>
      {showPlayerPopup && (
        <Overlays
          overlayName="driCon"
          customFooter={
            hasCTAs ? (
              <>
                <PlayerButton
                  player={popupPlayer}
                  closeButtonVariant={"primaryLight"}
                  ctaAppendClass="primaryDark"
                  type="info"
                  isTurboDriver={
                    selectedTurboDriver?.PlayerId === popupPlayer?.PlayerId
                  }
                  pushTurboDriverDataLayer={pushTurboDriverDataLayer}
                />
              </>
            ) : (
              ""
            )
          }
          overlayConfig={{
            closeBtn: true,
            showPlayerCardHeader: true,
            submitBtn: false,
          }}
          component={
            <DriverConstructorInfo
              player={popupPlayer}
              isOppositeTeam={isOppositeTeam}
              selectedGameday={selectedGameday}
            />
          }
          player={popupPlayer}
          onConfirmClick={() => togglePlayerPopup()}
          onCancelClick={() => togglePlayerPopup()}
        />
      )}
    </>
  );
};

export { PlayerInfo };
