import React, { useEffect, useState } from "react";
import { PvtPubCardChips } from "../../PvtPubCardChips";
import { IncludeRaceMini } from "../../IncludeRaceMini";
import AccordionChild from "../../Accordion/AccordionChild";
import { Accordion } from "../../Accordion";
import { ErrorMessage } from "../../Error/message";
import { getLeagueTypeId } from "../../../Common/utils/league";
import { getFixtureFromRaceWeek } from "../../../Common/utils/fixture";
import { useSelector, useDispatch } from "react-redux";
import { getTranslations } from "../../../Common/utils";
import { MiniLeagueCardInfo } from "../../MiniLeagueCardInfo";
import { MiniLeagueMatchTimer } from "./matchTimer";
import MiniLeagueBody from "../../MiniLeagueBody";
import {
  getMiniLeaguesData,
  miniLeaguesData,
} from "../../PrizeWrap/miniLeaguesData";
import { league } from "../../../redux/actions";
import { MatchHeader } from "../../MatchHeader";

const MiniLeague = ({ leagueData }) => {
  const {
    leagueName,
    leagueType,
    raceweek,
    leagueId,
    locktime,
    noOfteam,
    noOfTeams,
  } = leagueData;

  const fixtureState = useSelector((state) => state.fixture);
  const leagueState = useSelector((state) => state.league);
  const [includedRaces, setIncludedRaces] = useState([]);
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(0);
  const [currentMiniLeague, setCurrentMiniLeague] = useState({});
  const { miniLeaguesPrizesSuccess, miniLeaguesPrizesData } = leagueState;
  const [accordionActiveIndexArr, setAccordionActiveIndexArr] = useState([
    { isActive: true },
    { isActive: false },
  ]);
  const [isUpdateAccordion, setIsUpdateAccordion] = useState(false);
  const dispatch = useDispatch();

  const updateActiveAccordionIndex = (index, value) => {
    if (!value) {
      setActiveAccordionIndex(-1);
    } else {
      setActiveAccordionIndex(index);
    }
    setIsUpdateAccordion(true);
  };

  useEffect(() => {
    dispatch(league.getMiniLeaguesPrizes());
  }, []);

  useEffect(() => {
    if (miniLeaguesPrizesSuccess) {
      const completeMiniLeaguesData = getMiniLeaguesData(miniLeaguesPrizesData);
      setCurrentMiniLeague(
        completeMiniLeaguesData?.find(
          (item) => item?.name?.toLowerCase() === leagueName?.toLowerCase()
        )
      );
    }
  }, [miniLeaguesPrizesSuccess, miniLeaguesPrizesData]);

  const updateActiveAccordionIndexArrayData = (data) =>
    setAccordionActiveIndexArr(data);

  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;

  const accordionChilds = [
    {
      id: 0,
      accordionConfig: { showAccordRHS: false },
      headComponentLHS: (
        <>
          <div className="si-includeRace__accordion-title">
            <span>
              {getTranslations("included_races_title", "Included Races")} (
              {includedRaces?.length})
            </span>
          </div>
        </>
      ),
      bodyComponent: (
        <>
          <div className="si-includeRace__list">
            <ul>
              {includedRaces &&
                includedRaces?.map((race) => {
                  const fixture = fixtureState?.list?.find(
                    (fixtureItem) => fixtureItem?.Gameday === race?.Gameday
                  );

                  return (
                    <li>
                      <MatchHeader
                        MatchHeaderConfig={{
                          MatchHeaderRHS: false,
                          MatchHeaderExtra: false,
                          showFixtureData: true,
                          MatchFixtureInfo: false,
                        }}
                        fixture={fixture}
                      />
                    </li>
                  );
                })}
            </ul>
          </div>
        </>
      ),
    },
    {
      id: 1,
      accordionConfig: { showAccordRHS: false },
      headComponentLHS: (
        <>
          <div className="si-includeRace__accordion-title">
            <span>
              {getTranslations("mini_league_info_prizes_header", "Prizes")} (3)
            </span>
          </div>
        </>
      ),
      bodyComponent: (
        <MiniLeagueBody
          miniLeague={currentMiniLeague}
          showMainDescription={false}
        />
      ),
    },
  ];

  useEffect(() => {
    setIncludedRaces(getFixtureFromRaceWeek(fixtureState?.list, raceweek));
  }, [fixtureState, raceweek]);

  return (
    <>
      <div className="si-congratulations__header">
        <MiniLeagueCardInfo
          MiniLeagueCardInfoConfig={{
            showThumb: true,
            showLeagueCount: false,
            imageSrc: "mini-leagues/logo/",
            imageName: `${leagueId}.png`,
            leagueName: leagueName,
            showPvtPubCardChips: true,
            showMaxTeams: true,
            type: getLeagueTypeId(leagueType),
            maxTeams: noOfTeams ? noOfTeams : noOfteam,
            locktime,
            leagueData,
          }}
        />
        {/* <MiniLeagueMatchTimer locktime={locktime} /> */}
        {/* <ErrorMessage text="*Only F1 TV members can join this Mini League" /> */}
      </div>
      <div className="si-includeRace__accordion">
        <Accordion
          isDisabled={false}
          data={accordionActiveIndexArr}
          activeIndex={activeAccordionIndex}
          updateData={updateActiveAccordionIndexArrayData}
          isUpdateAccordion={isUpdateAccordion}
          accordionChild={accordionChilds?.map(
            (
              { id, accordionConfig, headComponentLHS, bodyComponent },
              index
            ) => {
              const { isActive } = accordionActiveIndexArr[index];
              return (
                <AccordionChild
                  key={id}
                  isActive={isActive}
                  accordionConfig={accordionConfig}
                  headComponentLHS={headComponentLHS}
                  bodyComponent={bodyComponent}
                  index={index}
                  updateActiveIndex={updateActiveAccordionIndex}
                />
              );
            }
          )}
        />
      </div>
    </>
  );
};

export { MiniLeague };
