import React, { useCallback, useMemo, useState } from "react";
import { InputSearch } from "../InputSearch";
import { CtaButton } from "../Buttons";
import { getTranslations, isMobile } from "../../Common/utils";
import useIPadOrientation from "../../Common/hooks/useIPadOrientation";
import { PORTRAIT } from "../../Common/constants";
import { useSelector } from "react-redux";
import { Overlays } from "../Overlays";
import { LeaguesFilter } from "../Overlays/LeaguesFilter";
import { LeaguesSort } from "../Overlays/LeaguesSort";
import { useSearchParams } from "react-router-dom";

const LeagueSearchSortFilter = ({
  handleLeagueSearch,
  leagueSearch,
  handleLeagueSearchInput,
  isJoinLeaguePage = false,
}) => {
  const [searchParams] = useSearchParams();
  const forMobileNoSpan = isMobile();
  const leagueState = useSelector((state) => state.league);
  const [showLeagueFilterPopup, setShowLeagueFilterPopup] = useState(false);
  const [showLeagueSortPopup, setShowLeagueSortPopup] = useState(false);
  const [isLeaguesFilterReset, setIsLeaguesFilterReset] = useState(false);
  const [isLeaguesFilterApply, setIsLeaguesFilterApply] = useState(false);
  const [isLeaguesSortReset, setIsLeaguesSortReset] = useState(false);
  const [isLeaguesSortApply, setIsLeaguesSortApply] = useState(false);
  const [leagueFilterButtonStatus, setLeagueFilterButtonStatus] =
    useState(true);
  const [leagueSortButtonStatus, setLeagueSortButtonStatus] = useState(true);

  const { isIPad, screenDimensionOrientation } = useIPadOrientation();
  const isIPadPortrait = isIPad && screenDimensionOrientation === PORTRAIT;

  const leagueTypeKey = searchParams.get("type");

  const leaguesFilterAndSortData = leagueState?.leaguesFilterAndSortData;
  const leagueSortValues = leagueState?.leagueSortValues;
  const leagueFilterValues = leagueState?.leagueFilterValues;

  const leaguesFilterData = leaguesFilterAndSortData?.filter;
  const leagueFilterSearchFieldData = leaguesFilterData?.common?.find(
    (item) => item?.name === "league_filter_leauge_search"
  );
  const leagueFilterSearchFieldDataApplicableFields =
    leagueFilterSearchFieldData?.applicable;

  const toggleLeagueFilterPopup = () =>
    setShowLeagueFilterPopup((value) => !value);
  const toggleLeaguesFilterReset = () =>
    setIsLeaguesFilterReset((value) => !value);
  const toggleLeaguesFilterApply = () =>
    setIsLeaguesFilterApply((value) => !value);

  const toggleLeagueSortPopup = () => setShowLeagueSortPopup((value) => !value);
  const toggleLeaguesSortReset = () => setIsLeaguesSortReset((value) => !value);
  const toggleLeaguesSortApply = () => setIsLeaguesSortApply((value) => !value);

  const getEnabledOrDisabledSearchInputForLeagueType = useMemo(() => {
    if (isJoinLeaguePage) {
      return !leagueFilterSearchFieldDataApplicableFields?.joined;
    }
    switch (leagueTypeKey) {
      case "featured":
        return !leagueFilterSearchFieldDataApplicableFields?.feature;
      case "pinned":
        return !leagueFilterSearchFieldDataApplicableFields?.pinned;
      case "mini":
        return !leagueFilterSearchFieldDataApplicableFields?.mini;
      case "private-classic":
        return !leagueFilterSearchFieldDataApplicableFields?.classic;
      case "private-hth":
        return !leagueFilterSearchFieldDataApplicableFields?.hth;
      case "public-classic":
        return !leagueFilterSearchFieldDataApplicableFields?.public;
      default:
        return true;
    }
  }, [
    leagueFilterSearchFieldDataApplicableFields,
    leagueTypeKey,
    isJoinLeaguePage,
  ]);

  const getEnabledOrDisabledFilterApplyButton = useCallback((value) => {
    setLeagueFilterButtonStatus(value);
  }, []);

  const getEnabledOrDisabledSortApplyButton = useCallback((value) => {
    setLeagueSortButtonStatus(value);
  }, []);

  return (
    <>
      <div className="si-leagueFilterBar__search">
        <InputSearch
          label="Search"
          handleSearch={handleLeagueSearch}
          searchInput={leagueSearch}
          handleInput={handleLeagueSearchInput}
          disabled={getEnabledOrDisabledSearchInputForLeagueType}
        />
      </div>
      <div className="si-leagueFilterBar__ctas">
        <CtaButton
          variant={"secondary"}
          onlyIcon={isMobile() || isIPadPortrait}
          classes={`${!!leagueFilterValues ? "si-filterApplied" : ""}`}
          icon="f1i-filter"
          onClick={toggleLeagueFilterPopup}
        >
          {getTranslations("create_team_filter", "FILTER")}
        </CtaButton>
        {!isJoinLeaguePage && (
          <CtaButton
            variant={"secondary"}
            onlyIcon={isMobile() || isIPadPortrait}
            classes={`${!!leagueSortValues ? "si-filterApplied" : ""}`}
            icon="f1i-menu"
            ariaLabel="Sort"
            onClick={toggleLeagueSortPopup}
          >
            {getTranslations("sort_cta_text", "SORT")}
          </CtaButton>
        )}
      </div>
      {showLeagueFilterPopup && (
        <Overlays
          overlayName="filter"
          overlayTitle={getTranslations(
            "league_filter_overlay_header_text",
            "Filter"
          )}
          btnPrimary={getTranslations(
            "player_filter_header_confirm_button",
            "Apply"
          )}
          component={
            <LeaguesFilter
              isLeaguesFilterReset={isLeaguesFilterReset}
              toggleLeaguesFilterReset={toggleLeaguesFilterReset}
              isLeaguesFilterApply={isLeaguesFilterApply}
              toggleLeaguesFilterApply={toggleLeaguesFilterApply}
              toggleLeagueFilterPopup={toggleLeagueFilterPopup}
              isJoinLeaguePage={isJoinLeaguePage}
              getEnabledOrDisabledFilterApplyButton={
                getEnabledOrDisabledFilterApplyButton
              }
            />
          }
          overlayConfig={{ cancelBtn: true, isReset: true }}
          onCancelClick={toggleLeagueFilterPopup}
          onConfirmClick={() => {
            toggleLeaguesFilterApply();
          }}
          onResetClick={() => {
            toggleLeaguesFilterReset();
          }}
          resetButtonState={false}
          confirmButtonState={leagueFilterButtonStatus}
        />
      )}
      {showLeagueSortPopup && (
        <Overlays
          overlayName="filter"
          overlayTitle={getTranslations(
            "league_sort_overlay_header_text",
            "Sort"
          )}
          btnPrimary={getTranslations(
            "player_filter_header_confirm_button",
            "Apply"
          )}
          component={
            <LeaguesSort
              isLeaguesSortReset={isLeaguesSortReset}
              toggleLeaguesSortReset={toggleLeaguesSortReset}
              isLeaguesSortApply={isLeaguesSortApply}
              toggleLeaguesSortApply={toggleLeaguesSortApply}
              toggleLeagueSortPopup={toggleLeagueSortPopup}
              getEnabledOrDisabledSortApplyButton={
                getEnabledOrDisabledSortApplyButton
              }
            />
          }
          overlayConfig={{ cancelBtn: true, isReset: true }}
          onCancelClick={toggleLeagueSortPopup}
          onConfirmClick={() => {
            toggleLeaguesSortApply();
          }}
          onResetClick={() => {
            toggleLeaguesSortReset();
          }}
          resetButtonState={false}
          confirmButtonState={leagueSortButtonStatus}
        />
      )}
    </>
  );
};

export default LeagueSearchSortFilter;
