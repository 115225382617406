import React from "react";
import config from "../../Common/config";
import { IMAGE_VERSION } from "../../Common/constants";
import { getTranslations, isMobile } from "../../Common/utils";
import { CtaButton } from "../Buttons";
import { FixDates } from "../Fixtures/FixDates";
import { FixRound } from "../Fixtures/FixRound";
import { PvtPubCardChips } from "../PvtPubCardChips";
import { currentFixture, getMatchStatus } from "../../Common/utils/fixture";
import { MatchData } from "../MatchHeader";
import { useSelector } from "react-redux";
import { MiniLeagueMatchTimer } from "../Overlays/MiniLeague/matchTimer";
import { LeagueCard } from "../LeagueCard";

const MiniLeagueCardInfo = ({ MiniLeagueCardInfoConfig }) => {
  const fixtureState = useSelector((state) => state.fixture);
  const {
    showPvtPubCardChips = false,
    showThumb = false,
    conditionClsName,
    teamID,
    leagueName,
    leagueCount,
    imageSrc,
    imageName,
    showLeagueCount = true,
    type = 0,
    showRound = false,
    roundNo = "",
    showDate = false,
    fixDates = "",
    fixRoundLbl = false,
    editName = false,
    adminIcon = false,
    errorIcon = false,
    showProvisionalPts = false,
    locktime,
    handleEditClick = () => {
      return null;
    },
    showMaxTeams = false,
    maxTeams,
    leagueData,
  } = MiniLeagueCardInfoConfig;

  return (
    <>
      <div className="si-miniLeagueCardBox">
        <LeagueCard
          data={{ ...leagueData, leagueType: "Mini", teamCount: 1 }}
          showJoinCta={false}
          showLeagueBanner={false}
          showMiniInfo={false}
          hideRanks={true}
          showRightIcon={false}
          showMaxTeams={true}
        />
        {!!locktime && <MiniLeagueMatchTimer locktime={locktime} />}
      </div>
    </>
  );
};

export { MiniLeagueCardInfo };
