import { toLowercaseNoSpaces } from ".";

function getCardBackgroundImage(cardBackgroundPath = "", currentSeasonYear) {
  return cardBackgroundPath?.replaceAll(
    "<current_season_year>",
    currentSeasonYear
  );
}

function getFrontDriverImagePath(imgPath = "", player, currentSeasonYear) {
  return imgPath
    ?.replaceAll("<current_season_year>", currentSeasonYear)
    ?.replaceAll(
      "<toLowercaseNoSpaces(driver.TeamName)>",
      toLowercaseNoSpaces(player?.TeamName)
    )
    ?.replaceAll(
      "<toLowercaseNoSpaces(driver.DriverReference)>",
      toLowercaseNoSpaces(player?.DriverReference)
    );
}

function getPlayerNumberImage(imgPath = "", player, currentSeasonYear) {
  return imgPath
    ?.replaceAll("<current_season_year>", currentSeasonYear)
    ?.replaceAll(
      "<toLowercaseNoSpaces(driver.TeamName)>",
      toLowercaseNoSpaces(player?.TeamName)
    )
    ?.replaceAll(
      "<toLowercaseNoSpaces(driver.DriverReference)>",
      toLowercaseNoSpaces(player?.DriverReference)
    );
}

function getRightDriverImagePath(imgPath = "", player, currentSeasonYear) {
  return imgPath
    ?.replaceAll("<current_season_year>", currentSeasonYear)
    ?.replaceAll(
      "<toLowercaseNoSpaces(driver.TeamName)>",
      toLowercaseNoSpaces(player?.TeamName)
    )
    ?.replaceAll(
      "<toLowercaseNoSpaces(driver.DriverReference)>",
      toLowercaseNoSpaces(player?.DriverReference)
    );
}

function getLeftDriverImagePath(imgPath = "", player, currentSeasonYear) {
  return imgPath
    ?.replaceAll("<current_season_year>", currentSeasonYear)
    ?.replaceAll(
      "<toLowercaseNoSpaces(driver.TeamName)>",
      toLowercaseNoSpaces(player?.TeamName)
    )
    ?.replaceAll(
      "<toLowercaseNoSpaces(driver.DriverReference)>",
      toLowercaseNoSpaces(player?.DriverReference)
    );
}

function getCarRightConstructorImagePath(
  imgPath = "",
  player,
  currentSeasonYear
) {
  return imgPath
    ?.replaceAll("<current_season_year>", currentSeasonYear)
    ?.replaceAll(
      "<toLowercaseNoSpaces(constructor.FUllName)>",
      toLowercaseNoSpaces(player?.FUllName)
    );
}

function getConstructorLogoImage(imgPath = "", player, currentSeasonYear) {
  return imgPath
    ?.replaceAll("<current_season_year>", currentSeasonYear)
    ?.replaceAll(
      "<toLowercaseNoSpaces(constructor.FUllName)>",
      toLowercaseNoSpaces(player?.FUllName)
    );
}

function getDriverLogoImage(imgPath = "", player, currentSeasonYear) {
  return imgPath
    ?.replaceAll("<current_season_year>", currentSeasonYear)
    ?.replaceAll(
      "<toLowercaseNoSpaces(driver.TeamName)>",
      toLowercaseNoSpaces(player?.TeamName)
    );
}

function getCardBackgroundShortImage(imgPath, currentSeasonYear) {
  return imgPath?.replaceAll("<current_season_year>", currentSeasonYear);
}

function getHexagonShapeImage(imgPath, currentSeasonYear) {
  return imgPath?.replaceAll("<current_season_year>", currentSeasonYear);
}

function getHexagonStrokeImage(imgPath, currentSeasonYear) {
  return imgPath?.replaceAll("<current_season_year>", currentSeasonYear);
}

export function getStatisticsDriverImage(
  player,
  currentSeasonYear,
  configData = {}
) {
  const {
    cloudinaryBaseUrl = null,
    cloudinaryEnvVersion = null,
    imagePaths = {},
    driverConstructorImgParts = {},
    teamBackgroundColor = null,
    accessibleTeamBackgroundColor = null,
  } = configData;
  const { card_background, front_player_image } = driverConstructorImgParts;
  const { statViewDriverImg = "" } = imagePaths;

  const frontPlayerImagePath = getFrontDriverImagePath(
    front_player_image,
    player,
    currentSeasonYear
  );
  const cardBackgroundImg = getCardBackgroundImage(
    card_background,
    currentSeasonYear
  );

  const statisticsDriverImagePath = statViewDriverImg
    ?.replaceAll("<cloudinary_base_path>", cloudinaryBaseUrl)
    ?.replaceAll("<env_version>", cloudinaryEnvVersion)
    ?.replaceAll(
      "<accessible_background_color>",
      accessibleTeamBackgroundColor?.[player?.TeamId]
    )
    ?.replaceAll("<background_color>", teamBackgroundColor?.[player?.TeamId])
    ?.replaceAll("<front_player_image>", frontPlayerImagePath)
    ?.replaceAll("<card_background>", cardBackgroundImg);

  return statisticsDriverImagePath;
}

export function getStatisticsConstructorImage(
  player,
  currentSeasonYear,
  configData = {}
) {
  const {
    cloudinaryBaseUrl = null,
    cloudinaryEnvVersion = null,
    imagePaths = {},
    driverConstructorImgParts = {},
    teamBackgroundColor = null,
    accessibleTeamBackgroundColor = null,
  } = configData;
  const {
    card_background,
    constructor_logo_image,
    car_right_constructor_image,
  } = driverConstructorImgParts;
  const { statViewConstructorImg = "" } = imagePaths;

  const cardBackgroundImg = getCardBackgroundImage(
    card_background,
    currentSeasonYear
  );
  const constructorLogoImage = getConstructorLogoImage(
    constructor_logo_image,
    player,
    currentSeasonYear
  );
  const carRightConstructorImg = getCarRightConstructorImagePath(
    car_right_constructor_image,
    player,
    currentSeasonYear
  );

  const statisticsConstructorImagePath = statViewConstructorImg
    ?.replaceAll("<cloudinary_base_path>", cloudinaryBaseUrl)
    ?.replaceAll("<env_version>", cloudinaryEnvVersion)
    ?.replaceAll("<constructor_logo_image>", constructorLogoImage)
    ?.replaceAll("<background_color>", teamBackgroundColor?.[player?.TeamId])
    ?.replaceAll(
      "<accessible_background_color>",
      accessibleTeamBackgroundColor?.[player?.PlayerId]
    )
    ?.replaceAll("<car_right_constructor_image>", carRightConstructorImg)
    ?.replaceAll("<card_background>", cardBackgroundImg);

  return statisticsConstructorImagePath;
}

export function getPlayerPopupDriverImage(
  player,
  currentSeasonYear,
  configData = {}
) {
  const {
    cloudinaryBaseUrl = null,
    cloudinaryEnvVersion = null,
    imagePaths = {},
    driverConstructorImgParts = {},
    teamBackgroundColor = null,
    accessibleTeamBackgroundColor = null,
  } = configData;
  const {
    card_background,
    player_number_image,
    driver_logo_image,
    right_player_image,
  } = driverConstructorImgParts;
  const { gridViewDriverImg = "" } = imagePaths;
  const cardBackgroundImg = getCardBackgroundImage(
    card_background,
    currentSeasonYear
  );
  const rightPlayerImagePath = getRightDriverImagePath(
    right_player_image,
    player,
    currentSeasonYear
  );
  const playerNumberImage = getPlayerNumberImage(
    player_number_image,
    player,
    currentSeasonYear
  );
  const driverLogoImage = getDriverLogoImage(
    driver_logo_image,
    player,
    currentSeasonYear
  );

  return gridViewDriverImg
    ?.replaceAll("<cloudinary_base_path>", cloudinaryBaseUrl)
    ?.replaceAll("<env_version>", cloudinaryEnvVersion)
    ?.replaceAll("<background_color>", teamBackgroundColor?.[player?.TeamId])
    ?.replaceAll("<player_number_image>", playerNumberImage)
    ?.replaceAll(
      "<accessible_background_color>",
      accessibleTeamBackgroundColor?.[player?.TeamId]
    )
    ?.replaceAll("<driver_logo_image>", driverLogoImage)
    ?.replaceAll("<right_player_image>", rightPlayerImagePath)
    ?.replaceAll("<current_season_year>", currentSeasonYear)
    ?.replaceAll("<card_background>", cardBackgroundImg);
}

export function getPlayerPopupConstructorImage(
  player,
  currentSeasonYear,
  configData = {}
) {
  const {
    cloudinaryBaseUrl = null,
    cloudinaryEnvVersion = null,
    imagePaths = {},
    driverConstructorImgParts = {},
    teamBackgroundColor = null,
    accessibleTeamBackgroundColor = null,
  } = configData;
  const {
    card_background,
    constructor_logo_image,
    car_right_constructor_image,
  } = driverConstructorImgParts;
  const { gridViewConstructorImg = "" } = imagePaths;
  const cardBackgroundImg = getCardBackgroundImage(
    card_background,
    currentSeasonYear
  );
  const carRightConstructorImg = getCarRightConstructorImagePath(
    car_right_constructor_image,
    player,
    currentSeasonYear
  );
  const constructorLogoImage = getConstructorLogoImage(
    constructor_logo_image,
    player,
    currentSeasonYear
  );

  return gridViewConstructorImg
    ?.replaceAll("<cloudinary_base_path>", cloudinaryBaseUrl)
    ?.replaceAll("<env_version>", cloudinaryEnvVersion)
    ?.replaceAll("<background_color>", teamBackgroundColor?.[player?.TeamId])
    ?.replaceAll(
      "<accessible_background_color>",
      accessibleTeamBackgroundColor?.[player?.PlayerId]
    )
    ?.replaceAll("<constructor_logo_image>", constructorLogoImage)
    ?.replaceAll("<car_right_constructor_image>", carRightConstructorImg)
    ?.replaceAll("<current_season_year>", currentSeasonYear)
    ?.replaceAll("<card_background>", cardBackgroundImg);
}

export function getDriverListViewImage(
  player,
  currentSeasonYear,
  configData = {}
) {
  const {
    cloudinaryBaseUrl = null,
    cloudinaryEnvVersion = null,
    imagePaths = {},
    driverConstructorImgParts = {},
    teamBackgroundColor = null,
    accessibleTeamBackgroundColor = null,
  } = configData;
  const {
    card_background,
    player_number_image,
    driver_logo_image,
    left_player_image,
  } = driverConstructorImgParts;
  const { listViewDriverImg = "" } = imagePaths;
  const cardBackgroundImg = getCardBackgroundImage(
    card_background,
    currentSeasonYear
  );
  const leftPlayerImagePath = getLeftDriverImagePath(
    left_player_image,
    player,
    currentSeasonYear
  );
  const playerNumberImage = getPlayerNumberImage(
    player_number_image,
    player,
    currentSeasonYear
  );
  const driverLogoImage = getDriverLogoImage(
    driver_logo_image,
    player,
    currentSeasonYear
  );

  return listViewDriverImg
    ?.replaceAll("<cloudinary_base_path>", cloudinaryBaseUrl)
    ?.replaceAll("<env_version>", cloudinaryEnvVersion)
    ?.replaceAll("<background_color>", teamBackgroundColor?.[player?.TeamId])
    ?.replaceAll("<player_number_image>", playerNumberImage)
    ?.replaceAll(
      "<accessible_background_color>",
      accessibleTeamBackgroundColor?.[player?.TeamId]
    )
    ?.replaceAll("<driver_logo_image>", driverLogoImage)
    ?.replaceAll("<left_player_image>", leftPlayerImagePath)
    ?.replaceAll("<current_season_year>", currentSeasonYear)
    ?.replaceAll("<card_background>", cardBackgroundImg);
}

export function getConstructorListViewImage(
  player,
  currentSeasonYear,
  configData = {}
) {
  const {
    cloudinaryBaseUrl = null,
    cloudinaryEnvVersion = null,
    imagePaths = {},
    driverConstructorImgParts = {},
    teamBackgroundColor = null,
    accessibleTeamBackgroundColor = null,
  } = configData;
  const {
    card_background,
    constructor_logo_image,
    car_right_constructor_image,
  } = driverConstructorImgParts;
  const { listViewConstructorImg = "" } = imagePaths;
  const cardBackgroundImg = getCardBackgroundImage(
    card_background,
    currentSeasonYear
  );
  const carRightConstructorImg = getCarRightConstructorImagePath(
    car_right_constructor_image,
    player,
    currentSeasonYear
  );
  const constructorLogoImage = getConstructorLogoImage(
    constructor_logo_image,
    player,
    currentSeasonYear
  );

  return listViewConstructorImg
    ?.replaceAll("<cloudinary_base_path>", cloudinaryBaseUrl)
    ?.replaceAll("<env_version>", cloudinaryEnvVersion)
    ?.replaceAll("<background_color>", teamBackgroundColor?.[player?.TeamId])
    ?.replaceAll(
      "<accessible_background_color>",
      accessibleTeamBackgroundColor?.[player?.PlayerId]
    )
    ?.replaceAll("<constructor_logo_image>", constructorLogoImage)
    ?.replaceAll("<car_right_constructor_image>", carRightConstructorImg)
    ?.replaceAll("<current_season_year>", currentSeasonYear)
    ?.replaceAll("<card_background>", cardBackgroundImg);
}

export function getDriverPitchViewImage(
  player,
  currentSeasonYear,
  configData = {}
) {
  const {
    cloudinaryBaseUrl = null,
    cloudinaryEnvVersion = null,
    imagePaths = {},
    driverConstructorImgParts = {},
    teamBackgroundColor = null,
    accessibleTeamBackgroundColor = null,
  } = configData;
  const { card_background, front_player_image } = driverConstructorImgParts;
  const { pitchViewDriverImg = "" } = imagePaths;
  const cardBackgroundImg = getCardBackgroundImage(
    card_background,
    currentSeasonYear
  );
  const frontPlayerImagePath = getFrontDriverImagePath(
    front_player_image,
    player,
    currentSeasonYear
  );

  return pitchViewDriverImg
    ?.replaceAll("<cloudinary_base_path>", cloudinaryBaseUrl)
    ?.replaceAll("<env_version>", cloudinaryEnvVersion)
    ?.replaceAll("<background_color>", teamBackgroundColor?.[player?.TeamId])
    ?.replaceAll(
      "<accessible_background_color>",
      accessibleTeamBackgroundColor?.[player?.TeamId]
    )
    ?.replaceAll("<front_player_image>", frontPlayerImagePath)
    ?.replaceAll("<current_season_year>", currentSeasonYear)
    ?.replaceAll("<card_background>", cardBackgroundImg);
}

export function getConstructorPitchViewImage(
  player,
  currentSeasonYear,
  configData = {}
) {
  const {
    cloudinaryBaseUrl = null,
    cloudinaryEnvVersion = null,
    imagePaths = {},
    driverConstructorImgParts = {},
    teamBackgroundColor = null,
    accessibleTeamBackgroundColor = null,
  } = configData;
  const { card_background, car_right_constructor_image } =
    driverConstructorImgParts;
  const { pitchViewConstructorImg = "" } = imagePaths;
  const cardBackgroundImg = getCardBackgroundImage(
    card_background,
    currentSeasonYear
  );
  const carRightConstructorImg = getCarRightConstructorImagePath(
    car_right_constructor_image,
    player,
    currentSeasonYear
  );

  return pitchViewConstructorImg
    ?.replaceAll("<cloudinary_base_path>", cloudinaryBaseUrl)
    ?.replaceAll("<env_version>", cloudinaryEnvVersion)
    ?.replaceAll("<background_color>", teamBackgroundColor?.[player?.TeamId])
    ?.replaceAll(
      "<accessible_background_color>",
      accessibleTeamBackgroundColor?.[player?.PlayerId]
    )
    ?.replaceAll("<car_right_constructor_image>", carRightConstructorImg)
    ?.replaceAll("<current_season_year>", currentSeasonYear)
    ?.replaceAll("<card_background>", cardBackgroundImg);
}

export function getDriverHexViewImage(
  player,
  currentSeasonYear,
  configData = {}
) {
  const {
    cloudinaryBaseUrl = null,
    cloudinaryEnvVersion = null,
    imagePaths = {},
    driverConstructorImgParts = {},
    teamBackgroundColor = null,
    accessibleTeamBackgroundColor = null,
  } = configData;
  const {
    card_background,
    right_player_image,
    hexagon_stroke_image,
    hexagon_shape_image,
    card_background_short,
  } = driverConstructorImgParts;
  const { hexViewDriverImg = "" } = imagePaths;
  const cardBackgroundImg = getCardBackgroundImage(
    card_background,
    currentSeasonYear
  );
  const rightPlayerImagePath = getRightDriverImagePath(
    right_player_image,
    player,
    currentSeasonYear
  );
  const hexagonStrokeImage = getHexagonStrokeImage(
    hexagon_stroke_image,
    currentSeasonYear
  );
  const hexagonShapeImage = getHexagonShapeImage(
    hexagon_shape_image,
    currentSeasonYear
  );
  const cardBackgroundShortImage = getCardBackgroundShortImage(
    card_background_short,
    currentSeasonYear
  );

  return hexViewDriverImg
    ?.replaceAll("<cloudinary_base_path>", cloudinaryBaseUrl)
    ?.replaceAll("<env_version>", cloudinaryEnvVersion)
    ?.replaceAll(
      "<accessible_background_color>",
      accessibleTeamBackgroundColor?.[player?.TeamId]
    )
    ?.replaceAll("<card_background_short>", cardBackgroundShortImage)
    ?.replaceAll("<hexagon_stroke_image>", hexagonStrokeImage)
    ?.replaceAll("<hexagon_shape_image>", hexagonShapeImage)
    ?.replaceAll("<background_color>", teamBackgroundColor?.[player?.TeamId])
    ?.replaceAll("<right_player_image>", rightPlayerImagePath)
    ?.replaceAll("<current_season_year>", currentSeasonYear)
    ?.replaceAll("<card_background>", cardBackgroundImg);
}

export function getConstructorHexViewImage(
  player,
  currentSeasonYear,
  configData = {}
) {
  const {
    cloudinaryBaseUrl = null,
    cloudinaryEnvVersion = null,
    imagePaths = {},
    driverConstructorImgParts = {},
    teamBackgroundColor = null,
    accessibleTeamBackgroundColor = null,
  } = configData;
  const {
    card_background,
    car_right_constructor_image,
    hexagon_stroke_image,
    hexagon_shape_image,
    card_background_short,
  } = driverConstructorImgParts;
  const { hexViewConstructorImg = "" } = imagePaths;
  const cardBackgroundImg = getCardBackgroundImage(
    card_background,
    currentSeasonYear
  );
  const carRightConstructorImg = getCarRightConstructorImagePath(
    car_right_constructor_image,
    player,
    currentSeasonYear
  );
  const hexagonStrokeImage = getHexagonStrokeImage(
    hexagon_stroke_image,
    currentSeasonYear
  );
  const hexagonShapeImage = getHexagonShapeImage(
    hexagon_shape_image,
    currentSeasonYear
  );
  const cardBackgroundShortImage = getCardBackgroundShortImage(
    card_background_short,
    currentSeasonYear
  );

  return hexViewConstructorImg
    ?.replaceAll("<cloudinary_base_path>", cloudinaryBaseUrl)
    ?.replaceAll("<env_version>", cloudinaryEnvVersion)
    ?.replaceAll("<card_background_short>", cardBackgroundShortImage)
    ?.replaceAll("<hexagon_stroke_image>", hexagonStrokeImage)
    ?.replaceAll("<hexagon_shape_image>", hexagonShapeImage)
    ?.replaceAll("<background_color>", teamBackgroundColor?.[player?.TeamId])
    ?.replaceAll(
      "<accessible_background_color>",
      accessibleTeamBackgroundColor?.[player?.PlayerId]
    )
    ?.replaceAll("<car_right_constructor_image>", carRightConstructorImg)
    ?.replaceAll("<current_season_year>", currentSeasonYear)
    ?.replaceAll("<card_background>", cardBackgroundImg);
}

export function getBestPerformingDriver(
  player,
  currentSeasonYear,
  configData = {}
) {
  const {
    cloudinaryBaseUrl = null,
    cloudinaryEnvVersion = null,
    imagePaths = {},
    driverConstructorImgParts = {},
    accessibleTeamBackgroundColor,
    teamBackgroundColor,
  } = configData;

  const { right_player_image = "", card_background } =
    driverConstructorImgParts;
  const { bestPerformingDriver } = imagePaths;

  const rightDriverImagePath = getRightDriverImagePath(
    right_player_image,
    player,
    currentSeasonYear
  );
  const cardBackgroundImg = getCardBackgroundImage(
    card_background,
    currentSeasonYear
  );

  return bestPerformingDriver
    ?.replaceAll("<cloudinary_base_path>", cloudinaryBaseUrl)
    ?.replaceAll(
      "<accessible_background_color>",
      accessibleTeamBackgroundColor?.[player?.TeamId]
    )
    ?.replaceAll("<background_color>", teamBackgroundColor?.[player?.TeamId])
    ?.replaceAll("<env_version>", cloudinaryEnvVersion)
    ?.replaceAll("<right_player_image>", rightDriverImagePath)
    ?.replaceAll("<current_season_year>", currentSeasonYear)
    ?.replaceAll("<card_background>", cardBackgroundImg);
}

export function getBestPerformingConstructor(
  player,
  currentSeasonYear,
  configData = {}
) {
  const {
    cloudinaryBaseUrl = null,
    cloudinaryEnvVersion = null,
    imagePaths = {},
    driverConstructorImgParts = {},
    accessibleTeamBackgroundColor,
    teamBackgroundColor,
  } = configData;

  const { car_right_constructor_image = "", card_background } =
    driverConstructorImgParts;
  const { bestPerformingConstructor } = imagePaths;

  const cardBackgroundImg = getCardBackgroundImage(
    card_background,
    currentSeasonYear
  );
  const carRightConstructorImage = getCarRightConstructorImagePath(
    car_right_constructor_image,
    player,
    currentSeasonYear
  );

  return bestPerformingConstructor
    ?.replaceAll("<cloudinary_base_path>", cloudinaryBaseUrl)
    ?.replaceAll("<env_version>", cloudinaryEnvVersion)
    ?.replaceAll("<current_season_year>", currentSeasonYear)
    ?.replaceAll(
      "<accessible_background_color>",
      accessibleTeamBackgroundColor?.[player?.PlayerId]
    )
    ?.replaceAll("<background_color>", teamBackgroundColor?.[player?.PlayerId])
    ?.replaceAll("<car_right_constructor_image>", carRightConstructorImage)
    ?.replaceAll("<card_background>", cardBackgroundImg);
}

export function getRaceWeekFlagPath(
  configData = {},
  cloudinaryBase = "https://alpha-media.formula1.com/image/upload",
  envVersion = "v1739888413",
  countryName
) {
  const raceweekFlagPath =
    configData?.imagePaths?.flagImg ||
    "<cloudinary_base_path>/<env_version>/common/f1/flag/flag<country_short_name>.png";
  const countryShortNames = configData?.countryShortNames || {
    Australia: "aus",
    Austria: "aus",
    Azerbaijan: "azer",
    Bahrain: "brn",
    Belgium: "bel",
    Brazil: "bra",
    Canada: "can",
    China: "chn",
    Hungary: "hun",
    Italy: "ita",
    Japan: "jap",
    Mexico: "mex",
    Monaco: "mon",
    Netherlands: "ned",
    Qatar: "qat",
    "Saudi Arabia": "ksa",
    Singapore: "sin",
    Spain: "esp",
    "United Arab Emirates": "uae",
    "United Kingdom": "gbr",
    "United States": "usa",
  };
  return raceweekFlagPath
    ?.replaceAll("<cloudinary_base_path>", cloudinaryBase)
    ?.replaceAll("<env_version>", envVersion)
    ?.replaceAll("<country_short_name>", countryShortNames?.[countryName]);
}
