import React from "react";
import { getTranslations } from "../../Common/utils";
import { CtaButton } from "../Buttons";

const BoosterChips = (boosterConfig) => {
  const {
    boosterCls,
    boosterIcon,
    boosterText,
    boosterUsed = false,
    hideBoosterClose = true,
    hideBoosterText = false,
    handleClose = () => {},
  } = boosterConfig;
  return (
    <>
      <div
        className={`si-booster__box ${boosterCls} ${
          boosterUsed ? "si-used" : ""
        }`}
        aria-label={boosterText}
        data-watermark={
          boosterUsed
            ? getTranslations("booster_chips_used_text", "Used")
            : undefined
        }
      >
        {boosterIcon && (
          <div className="si-booster__box-icon">
            <i className={`${boosterIcon}`}></i>
          </div>
        )}
        {boosterText && !hideBoosterText && (
          <div className="si-booster__box-text">
            <span>{boosterText}</span>
          </div>
        )}
        {!hideBoosterClose && (
          <CtaButton
            variant={"ghost"}
            onlyIcon={true}
            icon="f1i-close"
            ariaLabel=""
            onClick={() => handleClose()}
          />
        )}
      </div>
    </>
  );
};

export { BoosterChips };
