import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import config from "../../Common/config";
import { IMAGE_VERSION } from "../../Common/constants";
import { getPath } from "../../Common/utils";
import { currentFixture } from "../../Common/utils/fixture";
import { PlayerMiniCard } from "../PlayerMiniCard";
import Media from "../media";

const TableHeader = ({
  tableConfig,
  tableData,
  isTableHead = true,
  selectedOption,
  raceWeeks = undefined,
  handleClick = () => {},
  selectedOppositeTeamName = null,
  showNewLeaderIcon = false,
  isHandleClickDisabled = false,
  hideMiniCardTeam = false,
}) => {
  const userState = useSelector((state) => state.user);
  const translationState = useSelector((state) => state.translation);
  const fixtureState = useSelector((state) => state.fixture);
  const navigate = useNavigate();
  const location = useLocation();

  const getText = (text) => {
    return text ? text : "--";
  };

  const getRank = (rank) => {
    return isNaN(rank) ? "--" : rank;
  };

  return (
    <>
      <table className="si-tbl">
        {isTableHead && (
          <thead>
            <tr>
              {tableConfig.map((tableCell, index) => (
                <th key={index} className={`si-tbl si-tbl--${tableCell.cls}`}>
                  {tableCell.head}
                </th>
              ))}
            </tr>
          </thead>
        )}

        <tbody>
          {tableData &&
            tableData.map((data, index) => {
              const showOppositeTeamOverlay =
                !!selectedOppositeTeamName &&
                !!data?.teamName &&
                data?.teamName === selectedOppositeTeamName;

              return (
                <>
                  <tr
                    key={index}
                    className={`${
                      data.trend === -1 || !data.rank ? "si-disabled" : ""
                    } ${showOppositeTeamOverlay ? "si-active" : ""}`}
                  >
                    {tableConfig.map((tableBody, indexOne) => {
                      const playerTeamWithName = (
                        <PlayerMiniCard
                          PlayerMiniCardName={getText(data[[tableBody?.key]])}
                          PlayerMiniCardTeam={
                            hideMiniCardTeam
                              ? null
                              : data?.userName
                              ? getText(data?.userName)
                              : getText(data?.usrname)
                          }
                          showImage={false}
                          isNameSplit={false}
                        />
                      );
                      return (
                        <>
                          <td
                            key={indexOne}
                            className={`si-tbl si-tbl--${tableBody.cls}`}
                          >
                            {tableBody?.key === "rank" ? (
                              <div className="si-user__rank ">
                                {/*
                          -1 -- Not allow user to click on other team for view opp team
                            0 -- show '-'
                            1 -- show 'equal'
                            2 -- show 'up'
                            3 -- show 'down'
                        */}
                                <i
                                  className={
                                    data.trend === 0
                                      ? ""
                                      : data.trend === 2
                                      ? "f1i-price-up "
                                      : data.trend === 3
                                      ? "f1i-price-down"
                                      : ""
                                  }
                                ></i>
                                <span>{getRank(data[[tableBody?.key]])} </span>

                                {/* {showNewLeaderIcon && //  Remove New Leader icon present on all leaderboards
                                Number(data.rank) === 1 &&
                                data.trend === 2 &&
                                index === 0 && (
                                  <div className="si-newLeader__icon">
                                    <i className="f1i-new-leader"></i>
                                  </div>
                                )} */}
                              </div>
                            ) : tableBody?.key === "rightIcon" ? (
                              <>
                                {!!selectedOppositeTeamName &&
                                showOppositeTeamOverlay ? (
                                  <i className="f1i-chevron-right"></i>
                                ) : null}
                              </>
                            ) : tableBody?.key === "teamName" ? (
                              data.trend !== -1 ? (
                                !isHandleClickDisabled ? (
                                  <button
                                    onClick={() => {
                                      handleClick(data);
                                    }}
                                  >
                                    {playerTeamWithName}
                                  </button>
                                ) : (
                                  <>{playerTeamWithName}</>
                                )
                              ) : (
                                <>{playerTeamWithName}</>
                              )
                            ) : tableBody?.key === "points" ? (
                              getRank(data[[tableBody?.key]])
                            ) : (
                              getText(data[[tableBody?.key]])
                            )}
                            {data?.imgTagtableCell && indexOne == 1 && (
                              <Media
                                className="si-newTag"
                                imgSrc={`${config.IMG_BASE}new-tag.svg?v=${IMAGE_VERSION}`}
                                imgAlt={data[[tableBody?.key]]}
                              />
                            )}
                          </td>
                        </>
                      );
                    })}
                  </tr>
                </>
              );
            })}
          {/* <tr>
            {/-* {tableBody.map((tableCell, index) => (
              <td key={index} className={`si-tbl ${tableHead[index].cls}`}>
                {tableCell.text}
              </td>
            ))} *-/}
            <td className="si-tbl si-tbl--rank">
              <div className="si-user__rank ">
                <i className="f1i-equal"></i>
                <span>1</span>
              </div>
            </td>
            <td className="si-tbl si-tbl--name">Dream team</td>
            <td className="si-tbl si-tbl--pts">1500</td>
          </tr>
          <tr>
            <td className="si-tbl si-tbl--rank">
              <div className="si-user__rank ">
                <i className="f1i-price-down"></i>
                <span>1</span>
              </div>
            </td>
            <td className="si-tbl si-tbl--name">1</td>
            <td className="si-tbl si-tbl--pts">1</td>
          </tr>
          <tr>
            <td className="si-tbl si-tbl--rank">
              <div className="si-user__rank ">
                <i className="f1i-price-up"></i>
                <span>1</span>
              </div>
            </td>
            <td className="si-tbl si-tbl--name">1</td>
            <td className="si-tbl si-tbl--pts">1</td>
          </tr> */}
        </tbody>
      </table>
    </>
  );
};

export { TableHeader };
