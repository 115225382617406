import React, { useState } from "react";
import { disableEmojiInput, getTranslations } from "../../../Common/utils";
import { InputField } from "../../InputField";
import { RadioButton } from "../../RadioButton";

const ReportLeague = ({ handleOnChange }) => {
  const radioValue = [
    {
      value: getTranslations(
        "manage_league_report_league_option_1",
        "Inappropriate language or content"
      ),
    },
    {
      value: getTranslations(
        "manage_league_report_league_option_2",
        "Harassment"
      ),
    },
    {
      value: getTranslations(
        "manage_league_report_league_option_3",
        "Racism/Sexism content"
      ),
    },
  ];

  const [selectedRadio, setSelectedRadio] = useState(null);
  const [otherText, setOtherText] = useState("");

  const handleOnChangeLocal = (e) => {
    setSelectedRadio(e.target.value);
    handleOnChange({
      id: radioValue.findIndex((x) => x.value === e.target.value) + 1,
      text: e.target.value,
    });
  };

  const handleTextInput = (e) => {
    let val = disableEmojiInput(e.target.value);
    setOtherText(val);
    handleOnChange({
      id: 4,
      text: val,
    });
  };

  const isRadioSelected = () => {
    return radioValue.findIndex((x) => x.value === selectedRadio) !== -1;
  };

  return (
    <>
      <form className="" action="" method="" role="" aria-label="">
        <div className="si-league__report-radio">
          <ul>
            {radioValue.map((item, index) => (
              <li key={index}>
                <RadioButton
                  radioName="leagueReport"
                  radioText={item.value}
                  radioId={index}
                  checked={item?.value === selectedRadio}
                  handleOnChange={(e) => handleOnChangeLocal(e)}
                />
              </li>
            ))}
          </ul>
          <div
            className={`si-league__report-field ${
              isRadioSelected() ? "si-disabled" : ""
            }`}
          >
            <InputField
              inputID="customReport"
              label={getTranslations(
                "manage_league_report_league_other_option_label",
                "Other"
              )}
              inputType="text"
              ariaLable="Please enter your report"
              placeholderLabel={getTranslations(
                "manage_league_report_league_other_option_placeholder",
                "Please specify"
              )}
              isDisabled={isRadioSelected()}
              inputText={otherText}
              handleInput={(e) => handleTextInput(e)}
            />
          </div>
        </div>
        {/* // radioValue.findIndex((x) => x.value === selectedRadio) !== -1 */}
      </form>
    </>
  );
};

export default ReportLeague;
