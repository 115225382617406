import axios from "../../axios";
import { getURLString } from "../../index";
// import { DUMMY_DATA } from "../../../../ReusableComponents/Overlays/DriverConstructorInfo/driverConstructorDataConfig";
const URLString = (url) => getURLString(BASE_ROUTE, url);
const UserFeedsURLString = (url) => getURLString(USER_FEEDS_BASE_ROUTE, url);

const BASE_ROUTE = `feeds`;
const USER_FEEDS_BASE_ROUTE = `userfeeds`;
const RACE_DAY_SCHEDULE = (languageCode = "en") =>
  `schedule/raceday_${languageCode}.json`;
const DRIVERS_LIST = (gameDayId, languageCode = "en") =>
  `drivers/${gameDayId}_${languageCode}.json`;
const BOOSTERS_LIST = `booster/boosters.json`;
const CONSTRAINTS = `limits/constraints.json`;
const COUNTRIES = `countrylist/countries.json`;
const CIRCUIT_CONFIGURATION = (languageCode = "en") =>
  `circuit/configurations_${languageCode}.json`;

const SWAP_SUGGESTION_URL = (gamedayId) => `swapsuggestion/${gamedayId}.json`;

const GLOBAL_LEADER_BOARD = (optType, tourGameDayId, phaseId) =>
  `leaderboard/public/global/list_${optType}_${tourGameDayId}_${phaseId}.json`;

const UNJOINED_SPONSORED_PRIVATE_LEADER_BOARD = (
  optType,
  leagueId,
  tourGameDayId,
  phaseId
) =>
  `leaderboard/sponsorleague/list_${optType}_${leagueId}_${tourGameDayId}_${phaseId}.json`;

const COUNTRY_LEADER_BOARD = (optType, leagueId, tourGameDayId, phaseId) =>
  `leaderboard/public/country/list_${optType}_${leagueId}_${tourGameDayId}_${phaseId}.json`;

const DRIVER_LEADER_BOARD = (optType, leagueId, tourGameDayId, phaseId) =>
  `leaderboard/public/driver/list_${optType}_${leagueId}_${tourGameDayId}_${phaseId}.json`;

const TEAM_LEADER_BOARD = (optType, leagueId, tourGameDayId, phaseId) =>
  `leaderboard/public/team/list_${optType}_${leagueId}_${tourGameDayId}_${phaseId}.json`;

const USER_PUBLIC_LEADER_BOARD = (optType, leagueId, tourGameDayId, phaseId) =>
  `leaderboard/publicleague/list_${optType}_${leagueId}_${tourGameDayId}_${phaseId}.json`;

const MINI_LEAGUE_LEADER_BOARD = (optType, leagueId, tourGameDayId, phaseId) =>
  `leaderboard/minileague/list_${optType}_${leagueId}_${tourGameDayId}_${phaseId}.json`;

const MIX_API = `live/mixapi.json`;

const F1_MENU_ITEMS = `footer/f1_menu_items.json`;

const GET_TOP_PUBLIC_LEAGUES = "leagues/public/list.json";

const INFO_POPUP_CONFIG = "gamecontrol/infopopupconfig_1.json";

const ANNOUNCEMENT_POPUP_CONFIG = "gamecontrol/announcementconfig_1.json";

const RATING_STATS = `leagues/filter/rating_stats_2.json`;

const SORTING_STATS = `leagues/filter/sorting_stats_2.json`;

const CLOUDINARY_CONFIG_URL = `cloudinary/config.json`;

export function getRatingStatistics() {
  return axios.get(URLString(RATING_STATS), {
    feedBuster: true,
  });
}

export function getSortingStatistics() {
  return axios.get(URLString(SORTING_STATS), {
    feedBuster: true,
  });
}

export function getRaceDaySchedule(params) {
  const { languageCode } = params;
  return axios.get(URLString(RACE_DAY_SCHEDULE(languageCode)), {
    feedBuster: true,
  });
}

export function getDriversList(params) {
  const { gameDayId, languageCode, livePointsBuster = false } = params;
  return axios.get(URLString(DRIVERS_LIST(gameDayId, languageCode)), {
    feedBuster: true,
    livePointsBuster,
  });
}

export function getBoostersList() {
  return axios.get(URLString(BOOSTERS_LIST), {
    feedBuster: true,
  });
}

export function getConstraints() {
  return axios.get(URLString(CONSTRAINTS), {
    feedBuster: true,
  });
}

export function getCountries() {
  return axios.get(URLString(COUNTRIES), {
    feedBuster: true,
  });
}

export function getCircuitConfiguration(params) {
  const { languageCode } = params;
  return axios.get(URLString(CIRCUIT_CONFIGURATION(languageCode)), {
    feedBuster: true,
  });
}

export function getGlobalLeaderBoard(params) {
  const { optType, tourGameDayId, phaseId } = params;
  return axios.get(
    URLString(GLOBAL_LEADER_BOARD(optType, tourGameDayId, phaseId)),
    {
      feedBuster: true,
    }
  );
}

export function getUnjoinedSponsoredPrivateLeaderBoard(params) {
  const { optType, tourGameDayId, phaseId, leagueId } = params;
  return axios.get(
    URLString(
      UNJOINED_SPONSORED_PRIVATE_LEADER_BOARD(
        optType,
        leagueId,
        tourGameDayId,
        phaseId
      )
    ),
    {
      feedBuster: true,
    }
  );
}

export function getCountryLeaderBoard(params) {
  const { optType, tourGameDayId = 0, phaseId, leagueId } = params;
  return axios.get(
    URLString(COUNTRY_LEADER_BOARD(optType, leagueId, tourGameDayId, phaseId)),
    {
      feedBuster: true,
    }
  );
}

export function getDriverLeaderBoard(params) {
  const { optType, tourGameDayId = 0, phaseId, leagueId } = params;
  return axios.get(
    URLString(DRIVER_LEADER_BOARD(optType, leagueId, tourGameDayId, phaseId)),
    {
      feedBuster: true,
    }
  );
}

export function getFavoriteTeamLeaderBoard(params) {
  const { optType, tourGameDayId = 0, phaseId, leagueId } = params;
  return axios.get(
    URLString(TEAM_LEADER_BOARD(optType, leagueId, tourGameDayId, phaseId)),
    {
      feedBuster: true,
    }
  );
}

export function getPublicLeaderBoard(params) {
  const { optType, leagueId, tourGameDayId, phaseId } = params;
  return axios.get(
    URLString(
      USER_PUBLIC_LEADER_BOARD(optType, leagueId, tourGameDayId, phaseId)
    ),
    {
      feedBuster: true,
    }
  );
}

export function getMiniLeagueLeaderBoard(params) {
  const { optType, leagueId, tourGameDayId, phaseId } = params;
  return axios.get(
    URLString(
      MINI_LEAGUE_LEADER_BOARD(optType, leagueId, tourGameDayId, phaseId)
    ),
    {
      feedBuster: true,
    }
  );
}

export function getPlayerStatistics(playerId, livePointsBuster) {
  return axios.get(URLString(`popup/playerstats_${playerId}.json`), {
    feedBuster: true,
    livePointsBuster,
  });
}

export function getMixAPI() {
  return axios.get(URLString(MIX_API), {
    params: {
      buster: Date.now(),
    },
  });
}

export function getFooterPartners() {
  return axios.get(URLString("footer/partners.json"), {
    feedBuster: true,
  });
}

export function getGeolocation() {
  return axios.get("whereami");
}

export function getF1MenuItems() {
  return axios.get(URLString(F1_MENU_ITEMS), {
    feedBuster: true,
  });
}

export function getTopPublicLeagues() {
  return axios.get(URLString(GET_TOP_PUBLIC_LEAGUES), {
    feedBuster: true,
  });
}

export const getMiniLeaguesPrizes = () => {
  return axios.get(URLString("lists/mini-leagues.json"), {
    feedBuster: true,
  });
};

export function getDriverStatistics(params) {
  const { url = "", tourId } = params;
  const urlPath = url?.replace("{tourId}", tourId);
  return axios.get(URLString(urlPath), {
    feedBuster: true,
  });
}

export function getConstructorStatistics(params) {
  const { url = "", tourId } = params;
  const urlPath = url?.replace("{tourId}", tourId);
  return axios.get(URLString(urlPath), {
    feedBuster: true,
  });
}

export function getFeedbackQuestions(params) {
  const { url = "marketingoptin/question_en" } = params;
  return axios.get(URLString(url), { feedBuster: true });
}

export function getInfoPopupConfig(params) {
  const { url = "", tourId } = params;
  const urlPath = url?.replace("{tourId}", tourId);
  return axios.get(URLString(urlPath), {
    feedBuster: true,
  });
}

export function getAnnouncementConfig(params) {
  const { url = "", tourId } = params;
  const urlPath = url?.replace("{tourId}", tourId);
  return axios.get(URLString(urlPath), {
    feedBuster: true,
  });
}

export function getUserEOSStats(path, socialID) {
  const url = path?.replace("{socialId}", socialID);
  return axios.get(url, {
    feedBuster: true,
  });
}

export function getOverallEOSStats(params) {
  const { url, tourId } = params;
  const urlPath = url?.replace("{tourId}", tourId);
  return axios.get(URLString(urlPath), {
    feedBuster: true,
  });
}

export function getEOSConfig() {
  return axios.get(URLString(`eos/config.json`), {
    feedBuster: true,
  });
}

export function getWEBConfig() {
  return axios.get(URLString(`apps/web_config.json`), {
    feedBuster: true,
  });
}

export function getHomepageStats(params) {
  const { url, tourId } = params;
  const urlPath = url?.replace("{tourId}", tourId);
  return axios.get(URLString(urlPath), {
    feedBuster: true,
  });
}

export function getSeasonPerformance(params) {
  const { url, yearId } = params;
  const urlPath = url?.replace("{year}", yearId);
  return axios.get(UserFeedsURLString(urlPath), {
    feedBuster: true,
  });
}

export function getLeaguesFilterAndSort(params) {
  const { url, tourId } = params;
  const urlPath = url?.replace("{tourId}", tourId);

  return axios.get(URLString(urlPath), {
    feedBuster: true,
  });
}

export function getCloudinaryConfig() {
  return axios.get(URLString(CLOUDINARY_CONFIG_URL), {
    feedBuster: true,
  });
}

export function getSwapSuggestions({ gamedayId }) {
  return axios.get(URLString(SWAP_SUGGESTION_URL(gamedayId)), {
    feedBuster: true,
  });
}
