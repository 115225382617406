import React, { useState, useEffect } from "react";
import config from "../../Common/config";
import { useDispatch, useSelector } from "react-redux";
import { gameplay } from "../../redux/actions";
import { FixRound } from "../Fixtures/FixRound";
import { FixDates } from "../Fixtures/FixDates";
import MatchStatus from "../MatchStatus";
import { ErrorMessage } from "../Error/message";
import {
  currentFixture,
  getFixtureDateForLeagues,
  getGDStatus,
  getMatchStatus,
  sessionType,
} from "../../Common/utils/fixture";
import { CtaButton } from "../Buttons";
import { isUserLoggedIn } from "../../Common/utils/user";
import { useNavigate } from "react-router-dom";
import {
  getPath,
  getTranslations,
  getYearForImagePath,
  normalizeString,
} from "../../Common/utils";
import {
  CALCULATING_POINTS_CLASS,
  CLOUDINARY_IMAGE_BASE_URL,
  IMAGE_VERSION,
  LIVE_POINTS_CLASS,
} from "../../Common/constants";
import { getPreviousSeasonPerformance } from "../../redux/actions/seasonPerformance";
import useLivePoints from "../../Common/hooks/useLivePoints";
import RaceweekFlag from "../Cloudinary/RaceweekFlag";
import TrackMap from "../Cloudinary/Trackmap";
import RaceDetails from "../RaceDetails";
const MatchData = ({
  fixture,
  fixtureStart,
  fixtureEnd,
  fixtureState,
  MatchStatusWrap,
  showFixtureData = true,
  FixtureDateNewLine = false,
  ShowFixtureDate = true,
}) => {
  const { teamLivePointsCondition } = useLivePoints();
  const getMatchStatusIcon = (status) => {
    switch (status) {
      case "LIVE":
        return "f1i-live";

      case "PC IN PROGRESS":
        return "f1i-time";

      case "PROVISIONAL POINTS":
        return "f1i-provipts";
      default:
        return "";
    }
  };

  const getMatchStatusClass = (status) => {
    switch (status) {
      case "LIVE":
        return "si-live";

      case "PROVISIONAL POINTS":
        return "si-provipts";
      default:
        return "";
    }
  };

  const getMatchStatusText = (status) => {
    switch (status) {
      case "LIVE":
        return getTranslations(
          sessionType.get(currentFixture(fixtureState)?.SessionName),
          currentFixture(fixtureState)?.SessionName
        );

      case "PC IN PROGRESS":
        return getTranslations("match_status_calculating", "Calculating...");

      case "PROVISIONAL POINTS":
        return getTranslations(
          "match_status_provisional_points",
          "Provisional Points"
        );
      default:
        return "";
    }
  };

  const currentFixtureMatchStatus = getMatchStatus(
    currentFixture(fixtureState)?.MatchStatus
  );

  return (
    <>
      {(showFixtureData || MatchStatusWrap) && (
        <div className="si-matchHeader__extra">
          {showFixtureData && (
            <div className="si-matchHeader__extra-lhs">
              {/* <FixRound
                fixRoundConfig={{
                  fixRoundLbl: false,
                  roundNo: fixture?.MeetingNumber,
                }}
              /> */}
              {ShowFixtureDate && (
                <FixDates
                  fixtureStart={fixtureStart}
                  fixtureEnd={fixtureEnd}
                  showNewLine={FixtureDateNewLine}
                />
              )}
            </div>
          )}
          {MatchStatusWrap ? (
            <div className="si-matchHeader__extra-rhs">
              <MatchStatus
                matchStatusText={getMatchStatusText(currentFixtureMatchStatus)}
                matchStatusIcon={getMatchStatusIcon(currentFixtureMatchStatus)}
                matchStatusCls={`${getMatchStatusClass(
                  currentFixtureMatchStatus
                )} 
                ${
                  currentFixtureMatchStatus === "PC IN PROGRESS"
                    ? CALCULATING_POINTS_CLASS
                    : ""
                }
                ${
                  teamLivePointsCondition &&
                  currentFixtureMatchStatus === "LIVE"
                    ? LIVE_POINTS_CLASS
                    : ""
                }`}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};

function MatchHeader(props) {
  // const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const fixtureState = useSelector((state) => state.fixture);
  const translationState = useSelector((state) => state.translation);
  const userState = useSelector((state) => state.user);
  const constraintsState = useSelector((state) => state.constraints);
  const previousSeasonFixture = useSelector(
    (state) => state.seasonPerformance.seasonPerformanceData
  );

  // const currentFixture = fixtureState?.list && fixtureState?.list[index];
  // const [maxFixtures, setMaxFixtures] = useState(null);
  const { fixture, isUpcomingRace } = props;
  const CustomTag = props.tagName;
  let { isCurrentYear = true } = props;
  const {
    customCls,
    MatchHeaderLHS = true,
    MatchHeaderRHS = true,
    MatchHeaderExtra = false,
    MatchStatusWrap = false,
    showCTA = false,
    MatchFixtureInfo = true,
    showFixtureData = true,
    FixtureDateNewLine = false,
    ShowFixtureDate = true,
    disableCiruitClick = false,
    rw_flag_size = "md",
    rw_round = {
      fixRoundConfig: {
        fixRoundLbl: false,
        roundNo: fixture?.MeetingNumber,
      },
    },
    ShowRaceweekFixtureLHS = true,
  } = props.MatchHeaderConfig || {};

  const dispatch = useDispatch();
  const toggleCircuitPopup = () => {
    dispatch(gameplay.toggleCircuitPopup());
  };

  const circuitState = useSelector((state) => state.circuit);
  const currentCircuit = circuitState?.data?.find(
    (circuit) => circuit?.MeetingId === fixture?.MeetingId
  );

  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;

  // useEffect(() => {
  //   setMaxFixtures(fixtureState?.list?.length);
  // }, [fixtureState]);

  // function refreshFixture() {
  //   console.log(index, maxFixtures);
  //   if (index <= maxFixtures) {
  //     setIndex((prev) => prev + 1);
  //   }
  // }
  // useEffect(() => {
  //   const timerId = setInterval(refreshFixture, 1000);
  //   return function cleanup() {
  //     clearInterval(timerId);
  //   };
  // }, []);

  // let meetingFixtures = fixtureState?.list?.filter(
  //   (x) => x.MeetingId === fixture?.MeetingId
  // );

  let meetingFixtures;
  if (isCurrentYear) {
    meetingFixtures = fixtureState?.list?.filter(
      (x) => x.MeetingId === fixture?.MeetingId
    );
  } else {
    meetingFixtures = previousSeasonFixture?.Value?.fixtures?.filter(
      (x) => x.MeetingId === fixture?.MeetingId
    );
  }

  const fixtureStart =
    meetingFixtures?.length && meetingFixtures[0]?.SessionStartDate;
  const fixtureEnd =
    meetingFixtures?.length &&
    meetingFixtures[meetingFixtures?.length - 1]?.SessionEndDate;

  const handlePickYourTeam = () => {
    navigate(getPath(translationState, "createTeam"));
  };

  const handleViewPoints = () => {
    navigate(getPath(translationState, "myTeam"));
  };

  return (
    <>
      {(MatchHeaderLHS || MatchHeaderRHS) && (
        <div className={`si-matchHeader__box ${customCls ? customCls : ""}`}>
          {MatchHeaderLHS && (
            <section className="si-matchHeader__box-lft">
              <RaceDetails
                rw_flag={fixture?.CountryName}
                rw_round={rw_round}
                rw_location={fixture?.CircuitLocation}
                rw_caption={MatchFixtureInfo && fixture?.MeetingOfficialName}
                tagName={"h1"}
                rw_flag_size={rw_flag_size}
                rw_fixture={
                  ShowRaceweekFixtureLHS
                    ? {
                        fixtureStart: fixtureStart,
                        fixtureEnd: fixtureEnd,
                      }
                    : null
                }
              />
              {/* <CustomTag className="si-matchHeader__title">
                <RaceweekFlag countryName={fixture?.CountryName} />
                <span>{fixture?.CircuitLocation}</span>
              </CustomTag>
              {MatchFixtureInfo && (
                <div className="si-matchHeader__discrip">
                  <p>{fixture?.MeetingOfficialName}</p>
                </div>
              )} */}
            </section>
          )}

          {MatchHeaderRHS && (
            <section
              className="si-matchHeader__box-rgt"
              aria-label={`${fixture?.MeetingLocation} circuit`}
            >
              <button
                type="button"
                className={`si-matchHeader__circuit ${
                  disableCiruitClick ? "si-noClick" : ""
                }`}
                onClick={() => toggleCircuitPopup()}
              >
                {/* //! Add the track map cloudinary image here */}
                <TrackMap
                  countryName={normalizeString(
                    currentCircuit?.CircuitShortName
                  )}
                />
              </button>
            </section>
          )}
        </div>
      )}
      {MatchHeaderExtra && (
        <>
          {showCTA ? (
            <div className="si-matchHeader__extraMaster">
              <div className="si-matchHeader__extraMaster-lhs">
                <MatchData
                  {...{
                    fixture,
                    fixtureStart,
                    fixtureEnd,
                    fixtureState,
                    MatchStatusWrap,
                    showFixtureData,
                    FixtureDateNewLine,
                    ShowFixtureDate,
                  }}
                />
              </div>

              {getGDStatus(currentFixture(fixtureState)?.GDStatus) ===
              "LIVE" ? (
                <div className="si-matchHeader__extraMaster-rhs">
                  {!fixture.IsActiveForNewUser &&
                  ((isUserLoggedIn(userState) && !userState?.data?.TeamCount) ||
                    !isUserLoggedIn(userState)) ? (
                    <ErrorMessage
                      showIcon={false}
                      text={getTranslations(
                        "team_creation_disabled_message",
                        "*Team creation temporarily unavailable during final race."
                      )}
                    />
                  ) : isUserLoggedIn(userState) &&
                    userState?.data?.TeamCount ? (
                    <CtaButton
                      variant={"accent"}
                      icon="f1i-chevron-right"
                      onClick={() => handleViewPoints()}
                    >
                      {getTranslations(
                        "landing_page_raceday_lock_cta",
                        "View Teams"
                      )}
                    </CtaButton>
                  ) : (
                    <CtaButton
                      variant={"accent"}
                      icon="f1i-chevron-right"
                      onClick={() => handlePickYourTeam()}
                    >
                      {getTranslations(
                        "landing_page_raceday_cta",
                        "Pick Your Team"
                      )}
                    </CtaButton>
                  )}
                </div>
              ) : null}
            </div>
          ) : (
            <MatchData
              {...{
                fixture,
                fixtureStart,
                fixtureEnd,
                fixtureState,
                MatchStatusWrap,
                showFixtureData,
                FixtureDateNewLine,
                ShowFixtureDate,
              }}
            />
          )}
        </>
      )}
    </>
  );
}

export { MatchHeader, MatchData };
