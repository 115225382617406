import React from "react";
import config from "../../Common/config";
import { IMAGE_VERSION } from "../../Common/constants";
import { getTranslations } from "../../Common/utils";
import { CtaButton } from "../Buttons";
import Media from "../media";

const PageNotFound = ({
  pageTitle,
  pageCaption,
  pageThumb,
  pageCta,
  pageCtaText,
  handleButtonClick = () => {},
}) => {
  return (
    <>
      <section className="si-pageNotFound__wrap">
        <div className="si-main__container">
          <div className="si-main__row">
            {pageTitle && (
              <h2 className="si-pageNotFound__title">{pageTitle}</h2>
            )}
            {pageThumb && (
              <div className="si-pageNotFound__thumb">
                <Media imgSrc={pageThumb} imgAlt={pageThumb} />
              </div>
            )}{" "}
            {pageCaption && (
              <p
                className="si-pageNotFound__caption"
                dangerouslySetInnerHTML={{
                  __html: pageCaption,
                }}
              ></p>
            )}
            {pageCta && (
              <div className="si-pageNotFound__cta">
                <CtaButton
                  variant={"primaryDark"}
                  onClick={() => handleButtonClick()}
                >
                  {getTranslations(pageCtaText, "Reload")}
                </CtaButton>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export { PageNotFound };
