import {
  FETCH_CLOUDINARY_CONFIG_FULFILLED,
  FETCH_CLOUDINARY_CONFIG_PENDING,
  FETCH_CLOUDINARY_CONFIG_REJECTED,
} from "../../constants/cloudinary";

const initialState = {
  configLoading: false,
  configRejected: false,
  configFulfilled: false,
  configData: null,
};

const cloudinary = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CLOUDINARY_CONFIG_PENDING:
      return {
        ...state,
        configLoading: true,
        configRejected: false,
        configFulfilled: false,
        configData: null,
      };
    case FETCH_CLOUDINARY_CONFIG_REJECTED:
      return {
        ...state,
        configLoading: false,
        configRejected: true,
        configFulfilled: false,
        configData: null,
      };
    case FETCH_CLOUDINARY_CONFIG_FULFILLED:
      return {
        ...state,
        configLoading: false,
        configRejected: false,
        configFulfilled: true,
        configData: action.payload.data,
      };
    default:
      return state;
  }
};

export default cloudinary;
