import React from "react";
import { getTranslations } from "../../Common/utils";
import { BoosterChips } from "../BoosterChips";
import Notes from "../Notes";

const SelectedChipsWrap = (SelectedChipsConfig) => {
  const {
    boosterCls,
    boosterIcon,
    boosterText,
    boosterThumbnail,
    boosterCaption,
    hidePickedDOM = false,
    showWarningText = false,
    lockText = false,
    showWarningNote = false,
    hideBoosterTextTitle = false,
  } = SelectedChipsConfig;
  return (
    <>
      {boosterThumbnail && (
        <div className="si-booster__picked-thumbnail">
          <BoosterChips
            boosterCls={boosterCls}
            boosterIcon={boosterIcon}
            boosterText={boosterText}
          />
        </div>
      )}
     {!hidePickedDOM && <div className="si-booster__picked">
        {boosterText && !hideBoosterTextTitle && (
          <h3 className="si-booster__picked-title">
            <span>{boosterText}</span>
          </h3>
        )}
        {boosterCaption && (
          <div className="si-booster__picked-caption">
            <p>{boosterCaption}</p>
          </div>
        )}
        {lockText && (
          <div className="si-booster__picked-caption si-negative">
            <p>{lockText}</p>
          </div>
        )}
      </div>}
      {showWarningText && (
        <div className="si-errMsg">
          <span>
            {getTranslations(
              "team_changes_overlay_apply_chip_confirm",
              "Once confirmed, this chip cannot be deactivated."
            )}
          </span>
        </div>
      )}
      {showWarningNote && (
        <Notes 
          customCls={"si-error "}
          iconName={null}
          text={getTranslations(
            "team_changes_overlay_apply_chip_confirm",
            `Once confirmed, this chip cannot be deactivated.`
          )}
        />
      )}
    </>
  );
};

export { SelectedChipsWrap };
