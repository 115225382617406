import React from "react";

const ErrorMessage = ({
  customCls,
  text = "Something went wrong",
  iconClassName = "f1i-error",
  showIcon = true,
}) => {
  return (
    <div className={`si-errMsg ${customCls ? customCls : ""}`}>
      {showIcon && iconClassName && <i className={iconClassName}></i>}
      <span>{text}</span>
    </div>
  );
};

export { ErrorMessage };
