import React from "react";
import { CtaButton } from "../Buttons";

const LoadMoreCta = ({ handleLoadMore = () => {} }) => {
  return (
    <>
      <CtaButton
        variant={"secondary"}
        classes="si-btn__loadmore"
        icon="f1i-chevron-down"
        onClick={() => handleLoadMore()}
      >
        Load more
      </CtaButton>
    </>
  );
};

export { LoadMoreCta };
