import { useSelector } from "react-redux";

export default function useCloudinaryData() {
  const cloudinaryState = useSelector((state) => state.cloudinary);

  const currentSeasonYear =
    process.env.REACT_APP_CLOUDINARY_CURRENT_SEASON_YEAR;

  const configData = cloudinaryState?.configData;
  const cloudinaryBase = process.env.REACT_APP_CLOUDINARY_BASE_URL;
  const envVersion = configData?.cloudinaryEnvVersion
    ? configData?.cloudinaryEnvVersion
    : process.env.REACT_APP_CLOUDINARY_ENV_VERSION;

  return { cloudinaryBase, envVersion, currentSeasonYear, configData };
}
