import { cva } from "class-variance-authority";
import clsx from "clsx";
import React from "react";
import { serialize } from "../../Common/utils";
import { TRACKING_ELEMENT_ID } from "../../Common/constants";

const button = cva("", {
  variants: {
    disabled: {
      true: "si-disabled",
    },
    onlyIcon: { true: "si-btn__icon" },
    leadingIcon: {
      true: "si-icon--leading",
    },
    selected: {
      true: "si-selected",
    },
    noOutline: {
      true: "si-noOutline",
    },
    variant: {
      primaryLight: "si-btn si-btn__primary-light",
      primaryDark: "si-btn si-btn__primary-dark",
      secondary: "si-btn si-btn__secondary",
      accent: "si-btn si-btn__accent",
      live: "si-btn si-btn__live",
      link: "si-btn si-btn__link",
      ghost: "si-btn si-btn__ghost",
      tonal: "si-btn si-btn__tonal",
    },
    fullWidth: {
      true: "si-btn--fullWidth",
    },
    size: {
      sm: "si-btn--sm",
      md: "si-btn--md",
      lg: "si-btn--lg",
    },
  },
});

export const CtaButton = ({
  onClick = (e) => e.preventDefault(),
  variant,
  size,
  selected,noOutline,
  fullWidth,
  disabled,
  children,
  leadingIcon,
  icon,
  clickRef,
  classes,
  onlyIcon,
  trackingKey,
  restProps,
}) => {
  const refProp = clickRef ? { ref: clickRef } : {};
  return (
    <button
      {...refProp}
      aria-label={children}
      id={TRACKING_ELEMENT_ID}
      track-event={trackingKey ? serialize(trackingKey) : ""}
      disabled={disabled}
      className={clsx(
        button({
          variant,
          size,
          selected,
          disabled,
          leadingIcon,
          fullWidth,
          onlyIcon,noOutline
        }),
        classes
      )}
      {...restProps}
      onClick={onClick}
    >
      <div className="si-btn__innerChild">
        {onlyIcon ? null : <span>{children}</span>}
        {icon && <i className={icon}></i>}
      </div>
    </button>
  );
};
