import React from "react";
import { TeamStatsSkeleton } from "../../TeamStatsSkeleton";
import { PlayerTrends } from "../../PlayerCard/PlayerTrends";
import { BoosterChips } from "../../BoosterChips";
import {
  getBoosterCategory,
  getPath,
  getTranslations,
  getYearForImagePath,
  isAndroid,
  isIOS,
} from "../../../Common/utils";
import {
  FANTASY_ANDROID,
  FANTASY_IOS,
  FANTASY_WEBSITE,
  WEB,
  WEBVIEW,
  boosterIdKey,
} from "../../../Common/constants";
import { NoChipsActivated } from "../../ApplyChips";
import { PvtPubCardInfo } from "../../PvtPubCardInfo";
import { CtaButton } from "../../Buttons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { NoDataFound } from "../../NoDataFound";
import useDataLayer from "../../../Common/hooks/useDataLayer";

const PreviousRaceBody = ({
  teamNo,
  racePoints,
  racePointsDifference,
  chipUsed,
  bestDriver,
  bestConstructor,
  previousFixture,
  isNewTeam,
}) => {
  const navigate = useNavigate();
  const translationState = useSelector((state) => state.translation);
  const driverState = useSelector((state) => state.driver);
  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;
  const userState = useSelector((state) => state.user);
  const webview = userState?.webview;
  const pushToDataLayer = useDataLayer();
  const viewResultsClickDataLayer = (value, gameDayId) => {
    const dataLayerObject = {
      actionType: "View Result",
      clickText: getTranslations(
        "previous_race_results_view_result",
        "View Result"
      ),
      path: getPath(translationState, "raceWeekTeam", {
        gameDayId: gameDayId,
        phaseId: 1,
        teamNo: teamNo,
      }),
      locationInPage: `Previous Race Results | T${value}`,
      componentType: webview ? WEBVIEW : WEB,
      pageName: "Home",
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
    };
    pushToDataLayer("navigation_click", dataLayerObject);
  };
  const handleViewResults = (gameDayId, teamNo) => {
    viewResultsClickDataLayer(teamNo, gameDayId);
    navigate(
      getPath(translationState, "raceWeekTeam", {
        gameDayId: gameDayId,
        phaseId: 1,
        teamNo: teamNo,
      })
    );
  };

  const bestDriverItem = driverState?.list?.find(
    (item) => item?.PlayerId === bestDriver?.playerId
  );
  const bestConstructorItem = driverState?.list?.find(
    (item) => item?.PlayerId === bestConstructor?.playerId
  );
  const bestDriverTeamId = bestDriverItem?.TeamId;

  return (
    <>
      {!isNewTeam ? (
        <>
          <TeamStatsSkeleton
            title={getTranslations(
              "previous_race_results_race_points",
              "Race Points"
            )}
            customCls={"si-alignLeft-override"}
            component={
              <>
                <PlayerTrends
                  trendStatus={racePointsDifference < 0 ? "si-down" : "si-up"}
                  costText={`${racePoints}`}
                  trendText={Math.abs(racePointsDifference)}
                  slug={getTranslations("my_team_points_short", "pts")}
                  spanClass={"si-player__trends-pts"}
                  ariaLabel=""
                  showTrend={!!racePointsDifference}
                />
              </>
            }
          />
          <TeamStatsSkeleton
            title={getTranslations(
              "previous_race_results_chip_used",
              "Chip Used"
            )}
            customCls={"si-tac"}
            component={
              chipUsed?.chipId ? (
                <BoosterChips
                  boosterCls={
                    getBoosterCategory(boosterIdKey[chipUsed?.chipId])
                      ?.boosterCls
                  }
                  boosterIcon={
                    getBoosterCategory(boosterIdKey[chipUsed?.chipId])
                      ?.boosterIcon
                  }
                  boosterText={null}
                  hideBoosterText={true}
                />
              ) : (
                <NoChipsActivated />
              )
            }
          />
          <TeamStatsSkeleton
            title={getTranslations(
              "previous_race_results_best_performing",
              "Best Performing"
            )}
            titleContd={getTranslations(
              "previous_race_results_driver_and_constructor",
              "DR & CR"
            )}
            component={
              <>
                <PvtPubCardInfo
                  PvtPubCardConfig={{
                    conditionClsName: "si-driver",
                    showThumb: true,
                    imageSrc: "",
                    imageName: `${webConfigData?.imagePaths?.driversFront?.replace(
                      "{seasonYear}",
                      getYearForImagePath(webConfigData)
                    )}${bestDriver?.playerId}.png`,
                    defaultImageName: `${webConfigData?.imagePaths?.driversFront?.replace(
                      "{seasonYear}",
                      getYearForImagePath(webConfigData)
                    )}0.png`,
                    showRHS: false,
                    showBottomInfo: false,
                    teamID: bestDriverTeamId,
                    player: bestDriverItem,
                    displayPlayerImage: true,
                  }}
                />
                <PvtPubCardInfo
                  PvtPubCardConfig={{
                    conditionClsName: null,
                    showThumb: true,
                    imageSrc: "",
                    imageName: `${webConfigData?.imagePaths?.teamLogos?.replace(
                      "{seasonYear}",
                      getYearForImagePath(webConfigData)
                    )}${bestConstructor?.playerId}.png`,
                    defaultImageName: `${webConfigData?.imagePaths?.teamLogos?.replace(
                      "{seasonYear}",
                      getYearForImagePath(webConfigData)
                    )}0.png`,
                    showRHS: false,
                    showBottomInfo: false,
                    teamID: bestConstructor?.playerId,
                    player: bestConstructorItem,
                    displayPlayerImage: true,
                  }}
                />
              </>
            }
          />
          <div className="si-prevRace__card-cta">
            <CtaButton
              variant={"ghost"}
              icon="f1i-chevron-right"
              onClick={() =>
                handleViewResults(previousFixture?.GamedayId, teamNo)
              }
            >
              {getTranslations(
                "previous_race_results_view_result",
                "View Result"
              )}
            </CtaButton>
          </div>
        </>
      ) : (
        <div className="si-prevRace__noData">
          <NoDataFound
            noDataCaption={getTranslations(
              "previous_race_results_displayed_next_raceweek",
              "Team Performance will be displayed after next Race week"
            )}
          />
        </div>
      )}
    </>
  );
};

export { PreviousRaceBody };
