import React from "react";
import config from "../../Common/config";
import { IMAGE_VERSION } from "../../Common/constants";
import { useSelector } from "react-redux";
import { getYearForImagePath } from "../../Common/utils";
import HexViewImage from "../Cloudinary/HexViewImage";

const FantasyStatsCard = ({
  title,
  imgConstrutor,
  imgDriver,
  pointsConstrutor,
  pointsDriver,
  prefix = null,
  suffix = null,
  driverName = "",
  constructorName = "",
  currentSeasonYear = null,
  driver = null,
  constructor = null,
}) => {
  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;

  return (
    <>
      <div className="si-fanStats__card">
        <div className="si-fanStats__card-title">{title}</div>
        <div className="si-fanStats__card-grid">
          <div className="si-fanStats__card-gridLft">
            <div className="si-fanStats__card-thumb">
              <HexViewImage player={constructor} />
            </div>
          </div>
          <div className="si-fanStats__card-gridMid">
            <div className="si-fanStats__card-gridMid-item">
              <div className="si-fanStats__card-score">
                <span>
                  {prefix ? prefix : null}
                  {pointsConstrutor}
                  {suffix ? ` ${suffix}` : null}
                </span>
              </div>
              <div className="si-fanStats__card-name">
                <span>{constructorName}</span>
              </div>
            </div>
            <div className="si-fanStats__card-gridMid-item">
              <div className="si-fanStats__card-score">
                <span>
                  {prefix ? prefix : null}
                  {pointsDriver}
                  {suffix ? ` ${suffix}` : null}
                </span>
              </div>
              <div className="si-fanStats__card-name">
                <span>{driverName}</span>
              </div>
            </div>
          </div>
          <div className="si-fanStats__card-gridRgt">
            <div className="si-fanStats__card-thumb">
              <HexViewImage player={driver} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { FantasyStatsCard };
