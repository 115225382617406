import React from "react";
import { MatchHeader } from "../../MatchHeader";
import { currentFixture } from "../../../Common/utils/fixture";
import { useSelector } from "react-redux";
import MatchStatus from "../../MatchStatus";

const UpcomingRaceCard = ({ fixture, currentSeasonYear = null }) => {
  return (
    <>
      <div className="si-upcomingRace__card">
        <div className="si-upcomingRace__card-head">
          <div className="si-upcomingRace__card-info">
            <MatchHeader
              fixture={fixture}
              MatchHeaderConfig={{
                MatchHeaderLHS: false,
                MatchHeaderRHS: false,
                MatchHeaderExtra: true,
                FixtureDateNewLine: true,
              }}
              currentSeasonYear={currentSeasonYear}
            />
          </div>
          <div className="si-upcomingRace__card-curcuit">
            <MatchHeader
              fixture={fixture}
              MatchHeaderConfig={{
                MatchHeaderLHS: false,
                MatchHeaderExtra: false,
                disableCiruitClick: true,
              }}
              currentSeasonYear={currentSeasonYear}
            />
          </div>
        </div>
        <div className="si-upcomingRace__card-body">
          <MatchHeader
            fixture={fixture}
            tagName="h2"
            MatchHeaderConfig={{
              MatchHeaderRHS: false,
              MatchHeaderExtra: false,
              ShowRaceweekFixtureLHS: false,
            }}
            currentSeasonYear={currentSeasonYear}
          />
        </div>
      </div>
    </>
  );
};

export default UpcomingRaceCard;
