import React, { useState } from "react";
import {
  CLOUDINARY_IMAGE_BASE_URL,
  IMAGE_VERSION,
} from "../../Common/constants";
// import fallback from "../../../assets/images/fallback.svg";

const Media = ({ imgSrc, imgAlt, restProp }) => {
  const [isImageFailed, setIsImageFailed] = useState(false);
  const onImgFailed = ({ currentTarget }) => {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src = `${CLOUDINARY_IMAGE_BASE_URL}common/fallback.svg?v=${IMAGE_VERSION}`;
    setIsImageFailed(true);
  };

  return (
    <>
      <img
        src={`${imgSrc}`}
        alt={imgAlt}
        loading="lazy"
        {...restProp}
        onError={onImgFailed}
        className={isImageFailed ? "si-fallback__img" : ""}
      />
    </>
  );
};

export default Media;
