import { useState } from "react";
import useCloudinaryData from "../../../Common/hooks/useCloudinaryData";
import {
  getLeagueTypeName,
  getLeagueTypeNameForCloudinary,
} from "../../../Common/utils/league";

const LeagueBanner = ({
  VipSponsorBannerImage,
  leagueId,
  leagueType,
  showMobileBannerOnDesktop,
}) => {
  const { cloudinaryBase, envVersion, currentSeasonYear, configData } =
    useCloudinaryData();
  const imagePaths = configData?.imagePaths;

  const leagueBannerImages = imagePaths?.leagueBannerImages;
  const leagueBannerImgParts = configData?.leagueBannerImgParts;

  const leagueTypeKey = getLeagueTypeNameForCloudinary(leagueType);

  const leagueTypePath = leagueBannerImgParts?.league_type?.[leagueTypeKey];

  const bannerImagePath = leagueBannerImages
    ?.replaceAll("<cloudinary_base_path>", cloudinaryBase)
    ?.replaceAll("<env_version>", envVersion)
    ?.replaceAll("<current_season_year>", currentSeasonYear)
    ?.replaceAll("<league_type>", leagueTypePath)
    ?.replaceAll("<banner_type>", "banner")
    ?.replaceAll("<league_id>", leagueId);

  const bannerImageFallbackPath = leagueBannerImages
    ?.replaceAll("<cloudinary_base_path>", cloudinaryBase)
    ?.replaceAll("<env_version>", envVersion)
    ?.replaceAll("<current_season_year>", currentSeasonYear)
    ?.replaceAll("<league_type>", "fallback")
    ?.replaceAll("<banner_type>", "banner")
    ?.replaceAll("<league_id>", "0");

  const mobileBannerImagePath = leagueBannerImages
    ?.replaceAll("<cloudinary_base_path>", cloudinaryBase)
    ?.replaceAll("<env_version>", envVersion)
    ?.replaceAll("<current_season_year>", currentSeasonYear)
    ?.replaceAll("<league_type>", leagueTypePath)
    ?.replaceAll("<banner_type>", "mobile-banner")
    ?.replaceAll("<league_id>", leagueId);

  const mobileBannerFallbackImagePath = leagueBannerImages
    ?.replaceAll("<cloudinary_base_path>", cloudinaryBase)
    ?.replaceAll("<env_version>", envVersion)
    ?.replaceAll("<current_season_year>", currentSeasonYear)
    ?.replaceAll("<league_type>", "fallback")
    ?.replaceAll("<banner_type>", "mobile-banner")
    ?.replaceAll("<league_id>", "0");

  const [srcSet, setSourceSrcSet] = useState(
    showMobileBannerOnDesktop ? mobileBannerImagePath : bannerImagePath
  );

  return (
    <picture>
      <source
        media="(min-width:768px)"
        srcSet={srcSet}
        alt={VipSponsorBannerImage}
        loading="lazy"
        onError={(e) => {
          console.log(e);
          e.target.srcSet = showMobileBannerOnDesktop
            ? mobileBannerFallbackImagePath
            : bannerImageFallbackPath;
        }}
        // web img come here
      />
      <img
        src={mobileBannerImagePath}
        alt={VipSponsorBannerImage}
        loading="lazy"
        onError={(e) => {
          e.target.src = mobileBannerImagePath;
          setSourceSrcSet(bannerImageFallbackPath);
        }}
        // mobile come here
      />
    </picture>
  );
};

export default LeagueBanner;
