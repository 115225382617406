import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPath, getTranslations } from "../../../Common/utils";
import { CtaButton } from "../../../ReusableComponents/Buttons";
import { CreateLeague } from "../Create";
import { getLeagueTypeForDL } from "../../../Common/utils";
import { isAndroid } from "../../../Common/utils";
import {
  FANTASY_ANDROID,
  FANTASY_IOS,
  FANTASY_WEBSITE,
  WEB,
  WEBVIEW,
} from "../../../Common/constants";
import { isIOS } from "../../../Common/utils";
import useDataLayer from "../../../Common/hooks/useDataLayer";
import useTournamentScanario from "../../../Common/hooks/useTournamentScanario";

const GroupBtns = () => {
  const navigate = useNavigate();
  const translationState = useSelector((state) => state.translation);
  const userState = useSelector((state) => state.user);
  const pushToDataLayer = useDataLayer();
  const { isEos, lastGamedayRaceLocked } = useTournamentScanario();
  const disableCTAs = isEos || lastGamedayRaceLocked;

  const webview = userState?.webview;

  const pushJoinALeagueDataLayer = () => {
    const dataLayerObject = {
      actionType: "Join League",
      clickText: getTranslations(
        "league_landing_join_league_cta",
        "Join League"
      ),
      path: "/leagues/join",
      pageName: "Leagues",
      leagueType: getLeagueTypeForDL(),
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: webview ? WEBVIEW : WEB,
    };
    pushToDataLayer("navigation_click", dataLayerObject);
  };

  const handleJoin = () => {
    pushJoinALeagueDataLayer();
    navigate(getPath(translationState, "joinLeague"));
  };

  if (disableCTAs) {
    return null;
  }

  return (
    <>
      <div className="si-league__ctas">
        <CreateLeague />
        <CtaButton
          variant={"accent"}
          disabled={disableCTAs}
          onClick={() => handleJoin()}
        >
          {getTranslations("league_landing_join_league_cta", "Join League")}
        </CtaButton>
      </div>
    </>
  );
};

export default GroupBtns;
