import React, { useEffect, useRef, useState } from "react";
import { SectionHeadingWithFiller } from "../HeadingWithFiller";
import { getTranslations, isAndroid, isIOS } from "../../Common/utils";
import UpcomingRaceCard from "./UpcomingRaceCard";
import { Anchor } from "../Anchor";
import { useSelector } from "react-redux";
import {
  currentFixture,
  getUpcomingThreeFixtures,
} from "../../Common/utils/fixture";
import { useViewFullScheduleDL } from "./useViewFullScheduleDL";
import useDataLayer from "../../Common/hooks/useDataLayer";
import useIntersectionObserver from "../../Common/hooks/useIntersectionObserver";
import {
  FANTASY_ANDROID,
  FANTASY_IOS,
  FANTASY_WEBSITE,
  WEB,
  WEBVIEW,
} from "../../Common/constants";

const UpcomingRaceSection = ({ currentSeasonYear = null }) => {
  const upcomingRaceSectionRef = useRef(null);
  const pushToDataLayer = useDataLayer();
  const userState = useSelector((state) => state.user);
  const webview = userState?.webview;
  const fixtureState = useSelector((state) => state.fixture);
  const webConfigState = useSelector((state) => state.webconfig);
  const { handleViewFullScheduleDL } = useViewFullScheduleDL();
  const [isDataPushed, setIsDataPushed] = useState(false);
  const { webConfigData = null } = webConfigState;

  const primaryMenuItems =
    webConfigData?.clientHeaderFooter?.header?.menuItems?.primaryMenuItem;

  const scheduleMenuItem = primaryMenuItems?.find(
    (menuItem) => menuItem?.menuTitle === "Schedule"
  );

  const upcomingFixtures = getUpcomingThreeFixtures(
    fixtureState,
    currentFixture(fixtureState)
  );
  const viewFullScheduleLinkText = getTranslations(
    "view_full_schedule_link_text",
    "View Full Schedule"
  );
  const viewFullScheduleLink = scheduleMenuItem?.menuUrl;

  const handleViewFullScheduleLink = () => {
    handleViewFullScheduleDL({
      path: viewFullScheduleLink,
      actionType: "View Full Schedule",
      clickText: viewFullScheduleLinkText,
      locationInPage: "Next Race",
      pageName: "Home",
    });
  };
  const isUpcomingRaceSectionRefIntersectingMap = useIntersectionObserver(
    upcomingRaceSectionRef,
    {
      // threshold: isMobile() ? 0.5 : 1,
      threshold: 0.75,
    }
  );

  useEffect(() => {
    const dataLayerObject = {
      actionType: "view",
      locationInPage: "Race details",
      pageName: "Home",
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: webview ? WEBVIEW : WEB,
    };

    if (upcomingRaceSectionRef.current) {
      isUpcomingRaceSectionRefIntersectingMap.forEach((value, key) => {
        if (value && !isDataPushed) {
          pushToDataLayer("widget_impressions", dataLayerObject);
          setIsDataPushed(true);
        }
      });
    }
  }, [isUpcomingRaceSectionRefIntersectingMap]);

  return (
    <>
      <section className="si-upcomingRace__wrap" ref={upcomingRaceSectionRef}>
        <div className="si-main__container">
          <div className="si-upcomingRace__head">
            <h2 className="si-upcomingRace__title">
              {getTranslations("next_race_heading", "Next Race", "caps")}
            </h2>

            <Anchor
              linkCls={"si-btn si-btn__link"}
              icon={"f1i-chevron-right"}
              linkText={viewFullScheduleLinkText}
              hyperLink={viewFullScheduleLink}
              extraAttri={{
                onClick: handleViewFullScheduleLink,
              }}
            />
          </div>
        </div>
        <div className="si-main__container">
          <div className="si-upcomingRace__list">
            <ul>
              {upcomingFixtures?.map((fixture) => {
                return (
                  <li key={fixture?.RaceId}>
                    <UpcomingRaceCard
                      fixture={fixture}
                      currentSeasonYear={currentSeasonYear}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export { UpcomingRaceSection };
