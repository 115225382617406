import clsx from "clsx";
import useCloudinaryData from "../../../Common/hooks/useCloudinaryData";
import { getRaceWeekFlagPath } from "../../../Common/utils/cloudinary";
import { cva } from "class-variance-authority";
import {
  CLOUDINARY_IMAGE_BASE_URL,
  IMAGE_VERSION,
} from "../../../Common/constants";
import Media from "../../media";

const RaceweekFlag = ({ size, classes, countryName }) => {
  const { configData, cloudinaryBase, envVersion } = useCloudinaryData();
  const countryFlag = cva("si-countryFlag", {
    variants: {
      size: {
        sm: "si-img--sm",
        md: "si-img--md",
        lg: "si-img--lg",
        xlg: "si-img--xlg",
      },
    },
  });
  const flagImagePath = getRaceWeekFlagPath(
    configData,
    cloudinaryBase,
    envVersion,
    countryName
  );

  return (
    <>
      <div className={clsx(countryFlag({ size }), classes)}>
        <Media imgSrc={flagImagePath} imgAlt={countryName} />
        {/* <img
          src={flagImagePath}
          alt={countryName}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = `${CLOUDINARY_IMAGE_BASE_URL}common/fallback.svg?v=${IMAGE_VERSION}`;
          }}
          loading="lazy"
        /> */}
      </div>
    </>
  );
};

export default RaceweekFlag;
