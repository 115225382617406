import { getTranslations } from "../../../Common/utils";
import { BoosterChips } from "../../BoosterChips";
import ListViewImage from "../../Cloudinary/ListViewImage";
import PitchViewImage from "../../Cloudinary/PitchViewImage";
import PlayerPopupImage from "../../Cloudinary/PlayerPopupImage";
import { PlayerTeamJersyNo } from "../PlayerTeamJersyNo";
import { PlayerTeamLogo } from "../PlayerTeamLogo";
import { PlayerThumbnail } from "../PlayerThumbnail";

const DefaultPlayerImage = ({
  teamLogo,
  player,
  currentSeasonYear,
  jersyNo,
  isPlayerPlaying,
  playerType,
  notPlayingOnlyIcon,
  playerImageType,
}) => {
  return (
    <>
      <div className="si-player__card-teamLogo">
        {teamLogo && (
          <PlayerTeamLogo
            playerTeamLogo={player?.TeamId}
            playerTeamName=""
            player={player?.TeamName}
            currentSeasonYear={currentSeasonYear}
          />
        )}
        {player?.PositionName === "DRIVER" && jersyNo && (
          <PlayerTeamJersyNo
            player={player}
            PlayerTeamJersyNo={player?.PlayerId}
            currentSeasonYear={currentSeasonYear}
          />
        )}
      </div>
      <div
        className={`si-player__card-thumbnail ${
          isPlayerPlaying ? "" : "si-notPlaying"
        } `}
      >
        <PlayerThumbnail
          playerType={playerType}
          player={player}
          isNotPlaying={!isPlayerPlaying}
          notPlayingOnlyIcon={notPlayingOnlyIcon}
          currentSeasonYear={currentSeasonYear}
        />
      </div>
    </>
  );
};

const PlayerImageSection = (props) => {
  const {
    player,
    isPlayerPlaying,
    playerImageType,
    notPlayingOnlyIcon,
    isTurboDriver,
    isExtraTurboDriver,
    isFinalFix = false,
  } = props;

  const notPlayingMarkup = (
    <div className="si-player__status-wrap">
      {isExtraTurboDriver ? (
        <div className="si-3x__icon">
          <i className="f1i-extra-drs"></i>
        </div>
      ) : isTurboDriver ? (
        <div className="si-2x__icon">2x</div>
      ) : null}

      {isFinalFix && (
        <BoosterChips
          boosterConfig={{
            boosterCls: "si-final-fix ",
            boosterIcon: "f1i-final-fix",
          }}
        />
      )}
      {!isPlayerPlaying &&
        (notPlayingOnlyIcon ? (
          <div className="si-player__status">
            <div className="si-player__status-icon">
              <i className="f1i-not-playing"></i>
            </div>
          </div>
        ) : (
          <>
            <div className="si-player__status si-player__status--fullwidth">
              <i className="f1i-not-playing"></i>{" "}
              <span>
                {getTranslations("player_playing_text", "Not playing")}
              </span>
            </div>
          </>
        ))}
    </div>
  );

  switch (playerImageType) {
    case "player-popup":
      return (
        <div
          className={`si-player__card-thumbnail ${
            isPlayerPlaying ? "" : "si-notPlaying"
          } `}
        >
          <PlayerPopupImage player={player} />
          {notPlayingMarkup}
        </div>
      );
    case "list-view":
      return (
        <div
          className={`si-player__card-thumbnail ${
            isPlayerPlaying ? "" : "si-notPlaying"
          } `}
        >
          <ListViewImage player={player} />
          {notPlayingMarkup}
        </div>
      );
    case "pitch-view":
      return (
        <div
          className={`si-player__card-thumbnail ${
            isPlayerPlaying ? "" : "si-notPlaying"
          } `}
        >
          <PitchViewImage player={player} />
          {notPlayingMarkup}
        </div>
      );
    default:
      return null;
  }
};

export default PlayerImageSection;
