import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPath, getTranslations, isMobile } from "../../../Common/utils";
import { CtaButton } from "../../../ReusableComponents";

const LeagueSetting = ({ type, leagueId, pushLeagueSettingsDataLayer }) => {
  const navigate = useNavigate();
  const translationState = useSelector((state) => state.translation);

  return (
    <>
      <div className="si-league__setting">
        <CtaButton
          variant={"ghost"}
          onlyIcon={true}
          icon="f1i-customise"
          restProps={{
            ariaLabel: getTranslations(
              "league_members_share_aria",
              "Click to share your league"
            ),
          }}
          onClick={() => {
            navigate(
              getPath(translationState, "leaguesManage", {
                type: type,
                leagueId: leagueId,
              })
            );
            pushLeagueSettingsDataLayer();
          }}
        ></CtaButton>
      </div>
    </>
  );
};

export default LeagueSetting;
