import React from "react";
import config from "../../Common/config";
import { IMAGE_VERSION } from "../../Common/constants";
import { useSelector } from "react-redux";
import useCloudinaryData from "../../Common/hooks/useCloudinaryData";

const SectionHeadingWithFiller = ({
  customCls,
  removerClass,
  heading,
  dark,
  component,
  revert = true,
}) => {
  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;
  const { cloudinaryBase, currentSeasonYear, envVersion } = useCloudinaryData();
  return (
    <>
      <h2
        className={`si-section__heading ${
          revert ? "si-section__heading--topLine" : ""
        } ${dark ? "si-light" : ""} ${customCls ? customCls : ""}`}
      >
        <span>{heading}</span>
        {component}
        {/* <img
          src={`${cloudinaryBase}/${envVersion}/Fantasy/${currentSeasonYear}/images/${
            webConfigData?.imagePaths?.patternsAsset
          }${"tracker-arrow-filler.svg"}`}
          alt={heading}
          loading="lazy"
        /> */}
      </h2>
    </>
  );
};

export { SectionHeadingWithFiller };
