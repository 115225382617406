import React from "react";
import { Overlays } from "..";
import { getTranslations } from "../../../Common/utils";
import { IMAGE_VERSION } from "../../../Common/constants";
import config from "../../../Common/config";

const Announcement = ({
  show = false,
  title,
  description,
  cancelCTA,
  confirmCTA,
  imageURL,
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  return (
    show && (
      <Overlays
        overlayName="announcement"
        overlayTitle={title}
        overlaySubTitle={description}
        announcementText={getTranslations("announcement_tag", "Announcement")}
        btnPrimary={confirmCTA}
        btnSecondary={cancelCTA}
        overlayConfig={{
          cancelBtn: !!cancelCTA,
          submitBtn: !!confirmCTA,
          overlayHeaderThumbnail: true,
          showOverlaySub_Title: true,
          announcementTag: true,
        }}
        overlayThumbnailName={
          imageURL
            ? `${config.IMG_BASE}${imageURL}`
            : `${config.IMG_BASE}announcements/default.jpg?v=${IMAGE_VERSION}`
        }
        onCancelClick={onCancel}
        onConfirmClick={onConfirm}
        confirmButtonState={""}
      />
    )
  );
};

export { Announcement };
