import { useSelector } from "react-redux";
import useCloudinaryData from "../../../Common/hooks/useCloudinaryData";
import {
  getStatisticsConstructorImage,
  getStatisticsDriverImage,
} from "../../../Common/utils/cloudinary";
import { getPlayerFromPlayerId } from "../../../Common/utils";
import {
  getLeagueTypeName,
  getLeagueTypeNameForCloudinary,
} from "../../../Common/utils/league";

const bannerType = "logo";

const GlobalLogoImage = ({ leagueType, imageName }) => {
  const { cloudinaryBase, envVersion, currentSeasonYear, configData } =
    useCloudinaryData();
  const driverState = useSelector((state) => state.driver);

  const imagePaths = configData?.imagePaths;

  if (leagueType === "Global") {
    const globalLeaguePath = imagePaths?.globalLeague;

    const globalLeagueImagePath = globalLeaguePath
      ?.replaceAll("<cloudinary_base_path>", cloudinaryBase)
      ?.replaceAll("<env_version>", envVersion)
      ?.replaceAll("<current_season_year>", currentSeasonYear)
      ?.replaceAll("<imgext>", "svg");

    return <img src={globalLeagueImagePath} alt="Global League" />;
  }

  if (leagueType === "Country") {
    const flagImgHowToPlay = imagePaths?.flagImgHowToPlay;

    const countryImagePath = flagImgHowToPlay
      ?.replaceAll("<cloudinary_base_path>", cloudinaryBase)
      ?.replaceAll("<env_version>", envVersion)
      ?.replaceAll("<current_season_year>", currentSeasonYear)
      ?.replaceAll("<country_short_name>", imageName);

    return <img src={countryImagePath} alt="Global League" />;
  }

  if (leagueType === "Driver") {
    const player = getPlayerFromPlayerId(driverState, imageName);

    const imagePath = getStatisticsDriverImage(
      player,
      currentSeasonYear,
      configData
    );

    return <img src={imagePath} alt="Global League" />;
  }

  if (leagueType === "Team") {
    const player = getPlayerFromPlayerId(driverState, imageName);

    const imagePath = getStatisticsConstructorImage(
      player,
      currentSeasonYear,
      configData
    );

    return <img src={imagePath} alt="Global League" />;
  }
  return null;
};

const LeagueLogo = ({
  imageSrc,
  imageName,
  leagueId,
  isGlobal,
  leagueType,
}) => {
  const { cloudinaryBase, envVersion, currentSeasonYear, configData } =
    useCloudinaryData();
  const imagePaths = configData?.imagePaths;

  const leagueBannerImages = imagePaths?.leagueBannerImages;
  const leagueBannerImgParts = configData?.leagueBannerImgParts;

  if (isGlobal) {
    return <GlobalLogoImage leagueType={leagueType} imageName={imageName} />;
  }

  const leagueTypeKey = getLeagueTypeNameForCloudinary(leagueType);

  const leagueTypePath = leagueBannerImgParts?.league_type?.[leagueTypeKey];

  const logoImagePath = leagueBannerImages
    ?.replaceAll("<cloudinary_base_path>", cloudinaryBase)
    ?.replaceAll("<env_version>", envVersion)
    ?.replaceAll("<current_season_year>", currentSeasonYear)
    ?.replaceAll("<league_type>", leagueTypePath)
    ?.replaceAll("<banner_type>", bannerType)
    ?.replaceAll("<league_id>", leagueId);

  const logoImageFallbackPath = leagueBannerImages
    ?.replaceAll("<cloudinary_base_path>", cloudinaryBase)
    ?.replaceAll("<env_version>", envVersion)
    ?.replaceAll("<current_season_year>", currentSeasonYear)
    ?.replaceAll("<league_type>", "fallback")
    ?.replaceAll("<banner_type>", bannerType)
    ?.replaceAll("<league_id>", "0");

  return (
    <img
      src={logoImagePath}
      onError={(e) => (e.target.src = logoImageFallbackPath)}
      alt={leagueId}
    />
  );
};

export default LeagueLogo;
